// // import React from 'react'

// // export const Login = () => {
// //   return (
// //     <div>Login</div>
// //   )
// // }

// import React from "react";
// import { useState, useEffect } from "react";
// import Box from "@mui/material/Box";
// import TextField from "@mui/material/TextField";
// import { Typography, Button, Paper } from "@mui/material";
// // import config from '../../config/config';
// // import config from "../../config/config";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// // import { LoginAction } from "../../actions/LoginAction";
// // import "../../css/signin.css";
// // import { ToastContainer, toast } from "react-toastify";
// // import "react-toastify/dist/ReactToastify.css";
// import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
// import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
// import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
// import { sha256 } from 'js-sha256';
// import md5 from './Encoder';
// import { setSnackBarData } from "../../redux/SnackBarSlice";
// import { setAuthData } from "../../redux/AuthSlice";

// import { useDispatch } from "react-redux";
// // import Logo from './logo.png';
// import Logo from './SVG without Background.svg';
// import { post2, postLogin } from "../../services/AxiosGeneric";
// //loader
// // import Backdrop from "@mui/material/Backdrop";
// // import CircularProgress from "@mui/material/CircularProgress";
// // import LoadingComponent from "./LoadingComponent";

// const Login = () => {


//   const [isAuthenticated,setIsAuthenticated] = useState(false)
//   const [values, setValues] = useState({
//     email: "",
//     password: "",
//   });
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [showPassword, setShowPassword] = useState(false);
//   const [emailError, setEmailError] = useState(false);
//   const [passwordError, setPasswordError] = useState(false);
//   const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

//   const [errors, setErrors] = useState({
//     email: "",
//     password: "",
//   });
//   const [data, setData] = useState({});

//   const togglePasswordVisibility = () => {
//     setShowPassword(!showPassword); // Toggle the state
//   };


//   const navigate = useNavigate();

//   // const signInUrl = config.baseUrl + config.apiName.pageData

//   //loader
//   // const [open, setOpen] = React.useState(false);
//   const [buttonDisabled, setButtonDisabled] = useState(false);

//   // const handleClose = () => {
//   //   setOpen(false);
//   // };

// const dispatch = useDispatch()



//   const hashPassword = (password) => {
//     const hashed = sha256.create();
//     hashed.update(password);
//     return hashed.hex().toLowerCase();
//   };

//   const signin = async () => {
//    debugger
//     try {
//       if (errors.email || errors.password) {
//         return;
//       }
//       // const crypto = require('crypto');
//       // const hash = crypto.createHash('sha256');
//       // hash.update(values.password); // Assuming values.password contains the user's actual password
//       // const hashedPassword = hash.digest('hex');
//       if (!values.email.trim()){
//         dispatch(setSnackBarData({ open: true, msg: "Username cannot be empty", severity: 'error' }));
// return
//       }

//       if (!values.password.trim()) {
//         dispatch(setSnackBarData({ open: true, msg: "Password cannot be empty", severity: 'error' }));
// return
//       }



//       const payload = {
//         username: values.email,
//         // companyToken
//         //   :
//         //   "FDGVB@^&2",
//         password: md5(values.password),
//       };

//       // const response = await LoginAction.Login(payload);

//       // const response = await axios.post('https://nkcssoqa.nyggs.com/backend/sso/authenticate',payload)
//       const response = await axios.post('http://192.168.12.38:8881/user/authenticate',payload)
//     //  const response = await postLogin(Login,payload)

//       if (response?.data?.status === 200) {
//         setIsAuthenticated(true)
//         // dispatch(showToaster(200, "Login Successful !"));
//         localStorage.setItem('token', response.data.data.token);
//         localStorage.setItem('auth', true);
//         localStorage.setItem('userId', response.data.data.userId);
//         localStorage.setItem('userCompanyId', response.data.data.companyId);
//         localStorage.setItem('username', response.data.data.firstName);
       
//         dispatch(setSnackBarData({ open: true, msg: "Signed in succesfully", severity: 'success' }));
//         // navigate('/buckets')

//         let authData = {
//           auth: true,
//           token: response?.data?.token,
//           userId: response?.data?.data?.userId,
//           companyId: response?.data?.data?.companyId,
//           userName: response?.data?.data?.firstName,
//           open: false
//         }
//         dispatch(setAuthData(authData));



//         // Log dispatched auth data
    


//         // Dispatch actions to update Redux state
//         dispatch(setAuthData({
//           auth: true,
//           token: response.data.data.token,
//           userId: response.data.data.userId,
//           companyId: response.data.data.companyId,
//           userName: response.data.data.firstName,
//         }));
//       // dispatch(setAuthData(response?.data)); 
//       dispatch(
//         setSnackBarData({
//           open: true,
//           msg: "Signed in successfully",
//           severity: "success",
//         })
//       );
//       navigate("/bucketlist"); 
       
//       }
      
//       else if(!response?.data?.token) {
//         navigate('/')
//         dispatch(
//           setSnackBarData({
//             open: true,
//             msg: response?.data?.message,
//             severity: "error",
//           })
//         );
        
//       }
//     } 
    
    
//     catch (error) {

//       console.log(error,"HARSHITAERROR");
//       dispatch(
//         setSnackBarData({
//           open: true,
//           msg: "Employee code or password is wrong!",
//           severity: "error",
//         })
//       );
//     } finally {
//       setButtonDisabled(false);
      
//     }
//   };

//   return (
//     <>
//       {/*
//       <Box sx={{ backgroundColor: "", height: "" }}>
//         <form sx={{ display: "flex", flexDirection: "column", gap: 5, mt: 10 }}>
//           <Typography>SignIn</Typography>
//           <Box
//             sx={{
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               flexDirection: "column",
//               gap: 5,
//             }}
//           >
//             <TextField
//               id="outlined-basic"
//               label="Email Id"
//               variant="outlined"
//               onChange={(event) => {
//                 setEmail(event.target.value);
//               }}
//               sx={{ width: 400 }}
//               required
//             />
//             <TextField
//               id="outlined-basic"
//               label="Password"
//               variant="outlined"
//               onChange={(event) => {
//                 setPassword(event.target.value);
//               }}
//               sx={{ width: 400 }}
//               required
//             />
//             <Button
//               variant="contained"
//               onClick={(e) => {
//                 signin(e);
//               }}
//             >
//               Signin
//             </Button>
//           </Box>
//         </form>
     
//       </Box>*/}
//       {/* <div style={{backgroundColor:"green",height:"89vh",width:"100vw"}}> */}

   
//       <Box
//         id="grad1"
//         sx={{
//           display: "flex", justifyContent: "center", alignItems: "center", 
//           background: "linear-gradient(120deg, #fff 0%, #2392ab 100%)",
//           height:"100vh"

// }}
//       >
//         <Paper
//           elevation={5}
//           sx={{
//             height: "fit-content",
//             // maxHeight:"55vH",
//             // overflowY:"auto",
//             width: "30vw",
//             display: "flex",
//             flexDirection: "column",
//             // marginTop:5,
//             borderRadius:"20px"
          
         
//           }}
//         >

//           {/* <img src={Logo} style={{
//             height: "6vh",
//             width: "12vw",
//             alignSelf:"center",
//             marginTop:12
// }}/> */}
//           {/* <Typography style={{alignSelf:"center",
//             marginTop: 12, fontSize: "2rem", color:"rgb(40, 0, 113)"}}>RAPID S3</Typography> */}
      
//           {/*<LockOutlinedIcon
//             sx={{
//               height: "10vh",
//               width: "5vw",
//               margin: "",
//               alignSelf: "center",
//             }}
//           />*/}
//           <Typography variant="h4" style={{ alignSelf: "center", fontSize: "1.5rem", fontWeight: "bold", marginTop: 20 }}>Sign In</Typography>
//           <Box sx={{ display: "flex", flexDirection: "column", gap: 6, height: "fit-content", }}>
//             <TextField
//               id="standard-basic"
//               label="Username"
//               required
//               variant="standard"
//               sx={{ width: "25vw", alignSelf: "center" }}
//               value={values.email}
//               onChange={(e) => {
//                 setValues({ ...values, email: e.target.value });
//               }}
//               onBlur={() => {
//                 let trimmedValue = values.email.trim(); 
//                 setValues({ ...values, email: trimmedValue });
//                 if (!values.email.trim()) {
//                   setErrors({ ...errors, email: "Username is required" });
            
//                 } else {
//                   setErrors({ ...errors, email: "" });
//                 }
//               }}
//               error={Boolean(errors.email)}
//               helperText={errors.email}
//             />

//             <TextField
//               id="standard-basic"
//               label="Password"
//               required
//               variant="standard"
//               type={showPassword ? "text" : "password"}
//               value={values.password}
//               onChange={(e) => {
//                 setValues({ ...values, password: e.target.value });
//               }}
//               onBlur={() => {
//                 let trimmedValue = values.password.trim();

//                 setValues({ ...values, password: trimmedValue });
//                 if (!values.password.trim()) {
//                   setErrors({ ...errors, password: "Password is required" });
//                 } else {
//                   setErrors({ ...errors, password: "" });
//                 }
//               }}
//               error={Boolean(errors.password)}
//               helperText={errors.password}
//               sx={{ width: "25vw", alignSelf: "center" }}
//               InputProps={{
//                 endAdornment: (
//                   <React.Fragment>
//                     {showPassword ? (
//                       <VisibilityOutlinedIcon
//                         style={{ cursor: "pointer" }}
//                         onClick={togglePasswordVisibility}
//                       />
//                     ) : (
//                       <VisibilityOffOutlinedIcon
//                         style={{ cursor: "pointer" }}
//                         onClick={togglePasswordVisibility}
//                       />
//                     )}
//                   </React.Fragment>
//                 ),
//               }}
//               onKeyPress={(e) => {
//                 if (e.key === "Enter") {
//                   signin(); 
//                 }
//               }}
//             />
//             <Button
//               sx={{
//                 width: "25vw",
//                 height: "25px",
//                 backgroundColor: "rgb(40, 0, 113)",
//                 color: "white",
//                 alignSelf: "center",
//                 "&:hover": {
//                   backgroundColor: "rgb(40, 0, 113)",
//                 },
//               }}
//               disabled={buttonDisabled}
//               onClick={(e) => {
//                 signin(e);
//               }}
//             >
//               Sign In
//             </Button>
//           </Box>
//         </Paper>

//         <div>
//           {/*   
//       <Backdrop
    
//         sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1}}
//         open={open}
//         onClick={handleClose}
//       >
//         <CircularProgress color="inherit" />
//       </Backdrop> */}
//         </div>
//       </Box>
//       {/* </div> */}















      
//     </>
//   );
// };

// export default Login;




//login 2
// import React from 'react'

// export const Login = () => {
//   return (
//     <div>Login</div>
//   )
// }

import React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Typography, Button, Paper, Grid } from "@mui/material";
// import config from '../../config/config';
// import config from "../../config/config";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// import { LoginAction } from "../../actions/LoginAction";
// import "../../css/signin.css";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { sha256 } from 'js-sha256';
import md5 from './Encoder';
import { setSnackBarData } from "../../redux/SnackBarSlice";
import { setAuthData } from "../../redux/AuthSlice";

import { useDispatch } from "react-redux";
// import Logo from './logo.png';
import Logo from './SVG without Background.svg';
// import Logo from "./Svg with Background.svg"
import { post2, postLogin } from "../../services/AxiosGeneric";
import { login } from "../../constant/apiEndpoints/buckets";
//loader
// import Backdrop from "@mui/material/Backdrop";
// import CircularProgress from "@mui/material/CircularProgress";
// import LoadingComponent from "./LoadingComponent";

const Login = () => {


  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [values, setValues] = useState({
    email: "",
    password: "",
  });
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const [height, setHeight] = useState('67vh'); // Default height

  const handleZoomChange = () => {
    const zoomLevel = window.devicePixelRatio;

    // Adjust height based on zoom level
    if (zoomLevel >= 1.5) {
      setHeight('77vh'); // Example height for higher zoom levels
    } else {
      setHeight('55vh'); // Example height for default zoom levels
    }
  };

  useEffect(() => {
    // Initial call to set height
    handleZoomChange();

    // Add event listener to handle zoom level changes
    window.addEventListener('resize', handleZoomChange);

    // Clean up event listener on component unmount
    return () => window.removeEventListener('resize', handleZoomChange);
  }, []);











  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });
  const [data, setData] = useState({});

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword); // Toggle the state
  };


  const navigate = useNavigate();

  // const signInUrl = config.baseUrl + config.apiName.pageData

  //loader
  // const [open, setOpen] = React.useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  // const handleClose = () => {
  //   setOpen(false);
  // };

  const dispatch = useDispatch()



  const hashPassword = (password) => {
    const hashed = sha256.create();
    hashed.update(password);
    return hashed.hex().toLowerCase();
  };

  const signin = async () => {
    // debugger
    try {
      if (errors.email || errors.password) {
        return;
      }
      // const crypto = require('crypto');
      // const hash = crypto.createHash('sha256');
      // hash.update(values.password); // Assuming values.password contains the user's actual password
      // const hashedPassword = hash.digest('hex');
      if (!values.email.trim()) {
        dispatch(setSnackBarData({ open: true, msg: "Username cannot be empty", severity: 'error' }));
        return
      }

      if (!values.password.trim()) {
        dispatch(setSnackBarData({ open: true, msg: "Password cannot be empty", severity: 'error' }));
        return
      }



      const payload = {
        username: values.email,
        // companyToken
        //   :
        //   "FDGVB@^&2",
        password: md5(values.password),
      };

      // const response = await LoginAction.Login(payload);

      // const response = await axios.post('https://nkcssoqa.nyggs.com/backend/sso/authenticate',payload)
      // const response = await axios.post('http://localhost:8881/user/authenticate', payload)
      const response = await postLogin(login,payload)

      if (response?.data?.status === 200) {
        setIsAuthenticated(true)
        // dispatch(showToaster(200, "Login Successful !"));
        localStorage.setItem('token', response.data.data.token);
        localStorage.setItem('auth', true);
        localStorage.setItem('userId', response.data.data.userId);
        localStorage.setItem('userCompanyId', response.data.data.companyId);
        localStorage.setItem('username', response.data.data.firstName);

        dispatch(setSnackBarData({ open: true, msg: "Signed in succesfully", severity: 'success' }));
        // navigate('/buckets')

        let authData = {
          auth: true,
          token: response?.data?.token,
          userId: response?.data?.data?.userId,
          companyId: response?.data?.data?.companyId,
          userName: response?.data?.data?.firstName,
          open: false
        }
        dispatch(setAuthData(authData));



        // Log dispatched auth data



        // Dispatch actions to update Redux state
        dispatch(setAuthData({
          auth: true,
          token: response.data.data.token,
          userId: response.data.data.userId,
          companyId: response.data.data.companyId,
          userName: response.data.data.firstName,
        }));
        // dispatch(setAuthData(response?.data)); 
        dispatch(
          setSnackBarData({
            open: true,
            msg: "Signed in successfully",
            severity: "success",
          })
        );
        navigate("/bucketlist");

      }

      else if (!response?.data?.token) {
        navigate('/')
        dispatch(
          setSnackBarData({
            open: true,
            msg: response?.data?.message,
            severity: "error",
          })
        );

      }
    }


    catch (error) {


      dispatch(
        setSnackBarData({
          open: true,
          msg: "Employee code or password is wrong!",
          severity: "error",
        })
      );
    } finally {
      setButtonDisabled(false);

    }
  };

  return (
    <>
      
      {/* <Box
        id="grad1"
        sx={{
          display: "flex", justifyContent: "center", alignItems: "center",
          background: "linear-gradient(120deg, #fff 0%, #2392ab 100%)",
          height: "100vh"

        }}
      >
        <Paper
          elevation={5}
          sx={{
            height: "fit-content",
            // maxHeight:"55vH",
            // overflowY:"auto",
            width: "30vw",
            display: "flex",
            flexDirection: "column",
            // marginTop:5,
            borderRadius: "20px"
          }}
        >


          <Typography variant="h4" style={{ alignSelf: "center", fontSize: "1.5rem", fontWeight: "bold", marginTop: 20 }}>Sign In</Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 6, height: "fit-content", }}>
            <TextField
              id="standard-basic"
              label="Username"
              required
              variant="standard"
              sx={{ width: "25vw", alignSelf: "center" }}
              value={values.email}
              onChange={(e) => {
                setValues({ ...values, email: e.target.value });
              }}
              onBlur={() => {
                let trimmedValue = values.email.trim();
                setValues({ ...values, email: trimmedValue });
                if (!values.email.trim()) {
                  setErrors({ ...errors, email: "Username is required" });

                } else {
                  setErrors({ ...errors, email: "" });
                }
              }}
              error={Boolean(errors.email)}
              helperText={errors.email}
            />

            <TextField
              id="standard-basic"
              label="Password"
              required
              variant="standard"
              type={showPassword ? "text" : "password"}
              value={values.password}
              onChange={(e) => {
                setValues({ ...values, password: e.target.value });
              }}
              onBlur={() => {
                let trimmedValue = values.password.trim();

                setValues({ ...values, password: trimmedValue });
                if (!values.password.trim()) {
                  setErrors({ ...errors, password: "Password is required" });
                } else {
                  setErrors({ ...errors, password: "" });
                }
              }}
              error={Boolean(errors.password)}
              helperText={errors.password}
              sx={{ width: "25vw", alignSelf: "center" }}
              InputProps={{
                endAdornment: (
                  <React.Fragment>
                    {showPassword ? (
                      <VisibilityOutlinedIcon
                        style={{ cursor: "pointer" }}
                        onClick={togglePasswordVisibility}
                      />
                    ) : (
                      <VisibilityOffOutlinedIcon
                        style={{ cursor: "pointer" }}
                        onClick={togglePasswordVisibility}
                      />
                    )}
                  </React.Fragment>
                ),
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  signin();
                }
              }}
            />
            <Button
              sx={{
                width: "25vw",
                height: "25px",
                backgroundColor: "rgb(40, 0, 113)",
                color: "white",
                alignSelf: "center",
                "&:hover": {
                  backgroundColor: "rgb(40, 0, 113)",
                },
              }}
              disabled={buttonDisabled}
              onClick={(e) => {
                signin(e);
              }}
            >
              Sign In
            </Button>
          </Box>
        </Paper>

        <div>
   
        </div>
      </Box> */}
      <Grid container sx={{ background: "linear-gradient(120deg, #fff 0%, #2392ab 100%)", }}>

        <Grid item lg={6} md={6} sm={6} sx={{ backgroundColor: "", display: "flex", justifyContent: "center", alignItems: "center" }}>


          <img src={Logo} style={{
            height: "60vh",
            width: "20vw",
            alignSelf: "center",
            marginTop: 12
          }} />


        </Grid>







      <Grid item lg={6} md={6} sm={6} sx={{backgroundColor:"",height:"100vh",display:"flex",justifyContent:"center",alignItems:"center"}}>

          <Box sx={{
            height: 'fit-content', width: "28vw", backgroundColor: "white", display: "flex",
            flexDirection: "column",borderRadius:"15px"
}}>
            <Typography variant="h4" style={{ alignSelf: "center", fontSize: "1.5rem", fontWeight: "bold", marginTop: 15 }}>Sign In</Typography>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 8, height: "fit-content", }}>
              <TextField
                id="standard-basic"
                label="Username"
                required
                variant="standard"
                sx={{ width: "25vw", alignSelf: "center",marginTop:4 }}
                value={values.email}
                onChange={(e) => {
                  setValues({ ...values, email: e.target.value });
                }}
                onBlur={() => {
                  let trimmedValue = values.email.trim();
                  setValues({ ...values, email: trimmedValue });
                  if (!values.email.trim()) {
                    setErrors({ ...errors, email: "Username is required" });

                  } else {
                    setErrors({ ...errors, email: "" });
                  }
                }}
                error={Boolean(errors.email)}
                helperText={errors.email}
              />

              <TextField
                id="standard-basic"
                label="Password"
                required
                variant="standard"
                type={showPassword ? "text" : "password"}
                value={values.password}
                onChange={(e) => {
                  setValues({ ...values, password: e.target.value });
                }}
                onBlur={() => {
                  let trimmedValue = values.password.trim();

                  setValues({ ...values, password: trimmedValue });
                  if (!values.password.trim()) {
                    setErrors({ ...errors, password: "Password is required" });
                  } else {
                    setErrors({ ...errors, password: "" });
                  }
                }}
                error={Boolean(errors.password)}
                helperText={errors.password}
                sx={{ width: "25vw", alignSelf: "center" }}
                InputProps={{
                  endAdornment: (
                    <React.Fragment>
                      {showPassword ? (
                        <VisibilityOutlinedIcon
                          style={{ cursor: "pointer" }}
                          onClick={togglePasswordVisibility}
                        />
                      ) : (
                        <VisibilityOffOutlinedIcon
                          style={{ cursor: "pointer" }}
                          onClick={togglePasswordVisibility}
                        />
                      )}
                    </React.Fragment>
                  ),
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    signin();
                  }
                }}
              />
              <Button
                sx={{
                  width: "25vw",
                  height: "40px",
                  marginTop:0,
                  marginBottom:10,
                  backgroundColor: "rgb(40, 0, 113)",
                  color: "white",
                  alignSelf: "center",
                  "&:hover": {
                    backgroundColor: "rgb(40, 0, 113)",
                  },
                }}
                disabled={buttonDisabled}
                onClick={(e) => {
                  signin(e);
                }}
              >
                Sign In
              </Button>
              </Box>
          </Box>

      </Grid>
       

      </Grid>
  


    </>
  );
};

export default Login;




