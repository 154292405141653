export const Buckets = "bucket/buckets"
export const AddBucket = "bucket/create-bucket"
export const DeleteBucket = "bucket/delete-bucket"
export const EditBucket = "bucket/update-bucket"
export const FilesByBucketId = "files/allFiles"
export const FileDownloadUrl = "files/file"
export const FileUpload = "files/upload"
export const FileDownload = "files/download"
export const FileView = "files/b64_encoded"
export const FileToggleSwitch = "files/change-link-status"
// export const VideoStream = "files/stream"
export const VideoStream = "files/videoplayback"
export const FileDelete = "files/delete"
export const RecyclebinBuckets = "trash/buckets"
export const RecyclebinFiles = "trash/files"
export const RecyclebinFilesDownload = "trash/download"
export const RecyclebinFilesView = "trash/b64_encoded"
export const RecyclebinVideoStream  = "trash/videoplayback"
export const RestoreFile = "trash/restore-file"
export const DeleteRecyclebinFile = "trash/force-delete"
export const ViewBucketData = "bucket/detail"
export const FetchSize = "bucket/size"
export const AlertUpdate = "bucket/update-bucket/alert"
export const login = "user/authenticate"






