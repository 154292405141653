
// import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
// import React, { useState } from "react";
// import GenericTextfield from "../../common_components/input_fields/GenericTextfield";

// const ConfirmationModal = ({ open, handleClose, handleConfirm,text }) => {






//     return (
//         <Dialog open={open} onClose={handleClose}>
//             <DialogTitle>Confirmation</DialogTitle>
//             <DialogContent>

//                 <Typography variant="h3" style={{ fontSize: "17px", }}>{text}</Typography>
//                 <TextField
//                     margin="dense"
//                     label="Type CONFIRM here "
//                     fullWidth
//                     variant="outlined"
//                     value={inputValue}
//                     onChange={handleChange}
//                 />

//             </DialogContent>
         
//             <DialogActions>
//                 <Button onClick={handleClose} color="primary" style={{ backgroundColor: "red", color: "white",height:"30px",width:"80px" }}>
//                     No
//                 </Button>
//                 <Button onClick={handleConfirm} color="primary" autoFocus style={{ backgroundColor: "green", color: "white", height: "30px", width: "80px" }}>
//                     Yes
//                 </Button>
//             </DialogActions>
//         </Dialog>
//     );
// };
// export default ConfirmationModal




import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, TextField } from "@mui/material";

const ConfirmationModal = ({ open, handleClose, handleConfirm, text, inputValue, setInputValue,handleChange,isTextField }) => {


    

    const handleInputChange = (e) => {
        const value = e.target.value;
     
        const filteredValue = value.replace(/[^a-zA-Z]/g, '').slice(0, 7);
        setInputValue(filteredValue);
    };
    const expectedConfirmationText = "CONFIRM";

    const isConfirmTextEntered = inputValue?.trim().toUpperCase() === expectedConfirmationText;



    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Confirmation</DialogTitle>
            <DialogContent sx={{ height:  isTextField ?  "" : "80px"}}>
                <Typography variant="h3" style={{ fontSize: "17px",marginTop:25 }}>
                    {text}
                </Typography>

                {isTextField &&
                <>
                    <Typography> To confirm,type "CONFIRM" here </Typography>

                    <TextField
                        sx={{ marginTop: 2 }}
                        margin="dense"
                        // label="Type CONFIRM here..."
                        fullWidth
                        variant="outlined"
                        value={inputValue}
                        // onChange={(e) => setInputValue(e.target.value)} // Update state on change
                        onChange={handleInputChange}


                    />
                </>
            
                
                }
              
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        handleClose();
                        setInputValue("");
                    }}
                    color="primary"
                    style={{ backgroundColor: "red", color: "white", height: "30px", width: "80px" }}
                >
                    No
                </Button>

                <Button
                    onClick={handleConfirm}
                    disabled={!isConfirmTextEntered && isTextField}
                    color="primary"
                    autoFocus
                    style={{
                        backgroundColor: (!isConfirmTextEntered && isTextField )? "gray" : "green", // Color when disabled
                        color: "white",
                        height: "30px",
                        width: "80px",
                        cursor: (!isConfirmTextEntered && isTextField) ? "not-allowed" : "pointer" // Change cursor to indicate disabled state
                    }}
                >
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationModal;
