import { Folder } from '@mui/icons-material'
import { Box, Button, Grid, TextField, Typography } from '@mui/material'
import React, { useState, useEffect, useRef } from 'react'

import { Link, useNavigate } from 'react-router-dom'
import MainTable from '../../common_components/tables/MainTable'
import { SearchComponent } from '../../common_components/input_fields/SearchComponent'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ConfirmationModal from './ConfirmationModal';
import { useDispatch, useSelector } from 'react-redux'
import { AddBucket, Buckets, DeleteBucket, EditBucket, Recyclebin, RecyclebinBuckets } from '../../constant/apiEndpoints/buckets';
import { createApiThunk } from '../../redux/GenericThunk'
import { get2, post2, simpleGet, simpleGet2 } from '../../services/AxiosGeneric'
import { fetchSomeDataError, fetchSomeDataRequest, fetchSomeDataSuccess } from '../../redux/ApiDataSlice'
import moment from 'moment'
import AddBucketModal from './AddBucketModal'
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { FormControl, IconButton, InputAdornment } from '@mui/material'
import LoadingComponent from '../../common_components/popups/LoadingComponent'
import TitleHeading from '../../common_components/heading/TitleHeading'
import DynamicBreadcrumb from '../../common_components/breadcrumbs/DynamicBreadcrumb'







export const RecycleBin = () => {

    const [searchName, setSearchName] = useState('');
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [confirmationAddOpen, setConfirmationAddOpen] = useState(false);

    const [isBucketAdded, setIsBucketAdded] = useState(false);
    const [selectedBucketId, setSelectedBucketId] = useState();
    const [editMode, setEditMode] = useState(false);

    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [values, setValues] = useState({
        bucketName: "",
        bucketSize: "",
    });
    const [loading, setLoading] = useState(false)
    //sorting
    const [sortBy, setSortBy] = useState();
    const [sortField, setSortField] = useState();



    const dispatch = useDispatch();
    const data = useSelector((state) => state.apiSlice);
    const datas = data?.data?.someData?.data?.data ? data?.data?.someData?.data?.data : [];
    
    const navigate = useNavigate()


    const breadcrumbs = [
        { name: '', path: '/bucketlist' },
        { name: '', path: '' },

    ];




    //sorting
    const handleAscSort = (title) => {

        if (title === 'column3') {
            setSortField("Size")

        }


        if (title === 'column4') {
            setSortField("nooffile")

        }

        setSortBy(0)


    }



    const handleDescSort = (key) => {
        if (key === 'column3') {
            setSortField("Size")

        }


        if (key === 'column4') {
            setSortField("nooffile")

        }
        setSortBy(1)


    }




    const handleNavigation = (id, bucketName) => {
        navigate('/recyclebinlisting', { state: { bucketId: id, bucketName: bucketName } })
    }


    const handleChangePage = (event, newPage) => {
        setPage(newPage + 1);
    };


    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);

    };


    //delete bucket
    const deleteBucket = () => {
        try {
            const payload = {
                "bucketName": null,
                "bucketSecret": "string",
                "createdBy": 0,
                "createdByType": "Admin",
                "id": selectedBucketId,
                "isActive": true,
                "size": 0
            }


            dispatch(
                createApiThunk(
                    () => post2(DeleteBucket, payload),
                    fetchSomeDataRequest.type,
                    fetchSomeDataSuccess.type,
                    fetchSomeDataError.type,
                    "someData"
                )
            ).then(() => setIsBucketAdded(prev => !prev));
        } catch (error) {
            console.log(error);
        }

    }
    const handleDeleteClick = (id) => {
        setSelectedBucketId(id)
        setConfirmationOpen(true);
    };

    const handleConfirmDelete = () => {
        deleteBucket();

        setConfirmationOpen(false);
    };

    const handleCloseConfirmation = () => {
        setConfirmationOpen(false);
    };

    //add bucket
    const add = () => {

        try {

            if (!editMode) {
                const payload = {
                    "bucketName": values.bucketName,
                    "bucketSecret": "abc",
                    "createdBy": 0,
                    "createdByType": "Admin",
                    "id": 0,
                    "isActive": true,
                    "size": values.bucketSize
                }

                dispatch(
                    createApiThunk(
                        () => post2(AddBucket, payload),
                        fetchSomeDataRequest.type,
                        fetchSomeDataSuccess.type,
                        fetchSomeDataError.type,
                        "someData"
                    )
                ).then(() => setIsBucketAdded(prev => !prev));

                ;

            }
            else {
                const payload = {

                    "bucketName": null,
                    "bucketSecret": "string",
                    "createdBy": 0,
                    "createdByType": "Admin",
                    "id": selectedBucketId,
                    "isActive": true,
                    "size": values.bucketSize

                }
                dispatch(
                    createApiThunk(
                        () => post2(EditBucket, payload),
                        fetchSomeDataRequest.type,
                        fetchSomeDataSuccess.type,
                        fetchSomeDataError.type,
                        "someData"
                    )
                ).then(() => setIsBucketAdded(prev => !prev));

                ;
            }

        } catch (error) {
            console.log(error);
        }
    };

    const handleAddClick = () => {

        setConfirmationAddOpen(true);
    };

    const handleEditClick = (id) => {
        setSelectedBucketId(id)
        setEditMode(true)
        setConfirmationAddOpen(true);
    };

    const handleConfirmAdd = () => {
        add()
        setValues({
            bucketName: "",
            bucketSize: ""
        })

        setConfirmationAddOpen(false);
    };

    const handleCloseAddConfirmation = () => {
        setValues({
            bucketName: "",
            bucketSize: ""
        })
        setEditMode(false)
        setConfirmationAddOpen(false);

    };


    const handleChangeAdd = (field, value) => {
        setValues(prevValues => ({
            ...prevValues,
            [field]: value,
        }));
    };


    const tableHeader = [
        { title: 'Name', key: 'column1' },
        { title: 'Size', key: 'column3' },
        // { title: 'Deleted By', key: 'column5' },
        { title: 'No of deleted files', key: 'column4' },
    
        // { title: 'Created On', key: 'column4' },
        // { title: '', key: 'column5' },
    ];


    const rows = (Array.isArray(datas) ? datas : []).map((bucket) => ({
        id: bucket?.id,
        column1: (
            <Box style={{ cursor: "pointer" }}>
                <Typography align='' style={{ fontWeight: "bold", color:"#156f83" }} onClick={() => handleNavigation(bucket?.id, bucket?.bucketName)}>
                    {bucket?.bucketName}
                </Typography>
            </Box>
        ),
        // column1: {
        //     value: bucket?.bucketName || '',
        //     // onDoubleClick: () => handleNavigation(bucket?.id)  // Double-click handler for column1
        // },

        column3: <Typography align="right">{`${bucket?.size}` || ''}</Typography>,
        column4: <Typography align="right">{`${bucket?.count}` || ''}</Typography>,
        column5: <Typography align="right">{`${bucket?.count}` || ''}</Typography>,
     
        // column4: moment(bucket?.createdOn).format('YYYY-MM-DD')
        //     || '',
        // column5: <Box style={{ cursor: "pointer" }}><DeleteIcon style={{ cursor: "pointer" }} onClick={() => handleDeleteClick(bucket?.id)} /><EditIcon onClick={() => handleEditClick(bucket?.id)} /></Box>,

    }));

    // const filteredRows = rows.filter(row =>
    //     row.column1.toLowerCase().includes(searchName.toLowerCase())
    // );

    const hasMounted = useRef(false);

    // useEffect(() => {
    //     if (!hasMounted.current) {
    //         // Your code that needs to run once on initial mount
    //         const payload = {
    //             "pageNo": page,
    //             "pageSize": rowsPerPage,
    //         };

    //         dispatch(
    //             createApiThunk(
    //                 () => post2(Buckets, payload),
    //                 fetchSomeDataRequest.type,
    //                 fetchSomeDataSuccess.type,
    //                 fetchSomeDataError.type,
    //                 "someData"
    //             )
    //         )
    //             .then(() => setIsBucketAdded(prev => !prev));

    //         hasMounted.current = true;
    //     }
    // }, [dispatch,page,rowsPerPage]);

    const handleSearch = (event) => {
        if (event.key === "Enter") {
            fetchBuckets(searchName);
        }
    };

    const fetchBuckets = () => {
        setLoading(true)
        // debugger

        // if (!hasMounted.current) {
        const payload = {
            "pageNo": page - 1,
            "pageSize": rowsPerPage,
            "searchField": searchName,
            "sortBy": sortBy,
            "sortField": sortField
        };

        dispatch(
            createApiThunk(
                () => post2(RecyclebinBuckets, payload),
                fetchSomeDataRequest.type,
                fetchSomeDataSuccess.type,
                fetchSomeDataError.type,
                "someData"
            )
        ).finally(() => {
            setLoading(false);
        });;
        // hasMounted.current = true;
        // }
        // else{
        //     hasMounted.current = false;
        // }

    };

    useEffect(() => {

        fetchBuckets();
    }, [dispatch, page, rowsPerPage, sortBy, isBucketAdded, sortField,searchName]);


    useEffect(() => {
        setPage(1)

    }, [rowsPerPage])

    return (

        <>
            <div >
                <Box style={{ display: "flex", direction: 'row', justifyContent: 'space-between' }}>
                    {/* <h2>Recyclebin</h2> */}

                    <Box sx={{ display: 'flex', flexDirection: 'row', }}>
                        <DynamicBreadcrumb breadcrumbs={breadcrumbs} style={{ marginTop: 2 }} />
                        {/* <Link to="/bucketlist" style={{ textDecoration: 'none', color: 'inherit' }}> */}
                            <TitleHeading title="Recyclebin" />
                        {/* </Link> */}


                    </Box>
                    <Box style={{ display: "flex", direction: 'row' }}>
                        <SearchComponent
                            searchName={searchName}
                            setSearchName={setSearchName}
                            placeholder="Search Buckets..."
                        />

                        {/* <FormControl sx={{ float: "right", m: 2, mr: 1 }} >
                            <TextField

                                id="outlined-basic"
                                label="Search..."
                                placeholder='Bucket Name'
                                variant="outlined"
                                type="search"
                                value={searchName}
                                // onChange={ (e)handleCompanyNameChange}

                                onChange={(e) => {


                                    setSearchName(e.target.value);
                                }}

                                InputProps={{
                                    sx: {
                                        // fontSize: "12px",
                                        height: "30px"
                                    },
                                }}

                                InputLabelProps={{ shrink: true }} 

                                // onChange={(e)=>companyChange(e)}
                                onKeyPress={handleSearch}



                            />
                        </FormControl> */}
                      

                      
                    </Box>

                </Box>

                <MainTable tableHeader={tableHeader} rows={rows} handleNavigation={handleNavigation} handleDeleteClick={handleDeleteClick}
                    // rowsPerPageOptions={[rowsPerPage]}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    totalRecords={data?.data?.someData?.data?.totalLength}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    blankRowSize="0"
                    handleAscSort={handleAscSort}
                    handleDescSort={handleDescSort}
                />
            </div>

            {/* Delete Confirmation dialog */}
            <ConfirmationModal
                open={confirmationOpen}
                handleClose={handleCloseConfirmation}
                handleConfirm={handleConfirmDelete}
            />

            <AddBucketModal
                values={values}
                handleChangeAdd={handleChangeAdd}
                open={confirmationAddOpen}
                handleClose={handleCloseAddConfirmation}
                handleConfirm={handleConfirmAdd}
                editMode={editMode}
            />
            {loading && <LoadingComponent />}

        </>

    )
}