import { useState } from 'react';
import './App.css';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@emotion/react';
import HistoryDiolog from './common_components/popups/HistoryDiolog';
import { store } from './redux/Store';
import MainRouting from './routes/MainRouting';
import SnackBarAlert from './common_components/popups/SnackBarAlert';
import { customTheme } from './themes/CustomTheme';

function App() {

  const [theme,setTheme]=useState()
  return (
  <>
   <Provider store={store}>
    <ThemeProvider theme={customTheme}>
    <MainRouting/>
      <HistoryDiolog/>
    </ThemeProvider>
    <SnackBarAlert/>
   </Provider>
  
  </>
  );
}

export default App;
