import { createSlice } from '@reduxjs/toolkit';

export const historyDataSlice = createSlice({
  name: 'historyDataSlice',
  initialState: {
    value: {open:false,data:[]},
  },
  reducers: {
    setHistoryData: (state,passData) => {
    
      state.value = {...state.value,...passData.payload}
    },
   
  },
});

export const { setHistoryData } = historyDataSlice.actions;


export default historyDataSlice.reducer;
