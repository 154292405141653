import { Folder, Info } from '@mui/icons-material'
import { Box, Button, Grid, TextField, Tooltip, Typography } from '@mui/material'
import React, { useState, useEffect, useRef } from 'react'

import { useNavigate } from 'react-router-dom'
import MainTable from '../../common_components/tables/MainTable'
import { SearchComponent } from '../../common_components/input_fields/SearchComponent'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RecyclingIcon from '@mui/icons-material/Recycling';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ConfirmationModal from './ConfirmationModal';
import { useDispatch, useSelector } from 'react-redux'
import { AddBucket, AlertUpdate, Buckets, DeleteBucket, EditBucket, ViewBucketData } from '../../constant/apiEndpoints/buckets';
import { createApiThunk } from '../../redux/GenericThunk'
import { get2, post, post2, simpleGet, simpleGet2 } from '../../services/AxiosGeneric'
import { fetchSomeDataError, fetchSomeDataRequest, fetchSomeDataSuccess } from '../../redux/ApiDataSlice'
import moment from 'moment'
import AddBucketModal from './AddBucketModal'
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { FormControl, IconButton, InputAdornment } from '@mui/material'
import axios from 'axios'
import config from '../Permission/config'
import LoadingComponent from '../../common_components/popups/LoadingComponent'
import { setSnackBarData } from '../../redux/SnackBarSlice'
import { setHistoryData } from '../../redux/HistoryDataSlice'
import DynamicBreadcrumb from '../../common_components/breadcrumbs/DynamicBreadcrumb'
import TitleHeading from '../../common_components/heading/TitleHeading'
import { userService } from '../Permission/userServices'







export const BucketList = () => {
    
    const [bucketList,setBucketList] = useState()
    const [searchName, setSearchName] = useState('');
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [confirmationAddOpen, setConfirmationAddOpen] = useState(false);

    const [isTextField,setIsTextField] = useState(true);

    const [confirmationAlertOpen, setConfirmationAlertOpen] = useState(false);

    const [alertData, setAlertData] = useState("");

    const [isBucketAdded, setIsBucketAdded] = useState(false);
    const [selectedBucketId, setSelectedBucketId] = useState();
    const [editMode, setEditMode] = useState(false);

    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [selectedBucketName, setSelectedBucketName] = useState()
    const [selectedBucketSize, setSelectedBucketSize] = useState()
    const [selectedBucketAutodelete, setSelectedBucketAutodelete] = useState()
    const [selectedBucketMaxUploadSizeFile, setSelectedBucketMaxUploadSizeFile] = useState()
    const [values, setValues] = useState({
        bucketName:"",
        bucketSize: "",
        autoDelete:"",
        maxUploadFileSize:""
    });
    //sorting
    const [sortBy, setSortBy] = useState();
    const [sortField, setSortField] = useState();
    const [loading, setLoading] = useState(false); // Local loading state
    const [inputValue, setInputValue] = useState("");

    //view
    const [viewData,setViewData] = useState([])
    const [initialDeleteFromRecyclebinOn, setInitialDeleteFromRecyclebinOn] = useState()


    const dispatch = useDispatch();
    const data = useSelector((state) => state.apiSlice);
    const datas = data?.data?.someData?.data?.data ? data?.data?.someData?.data?.data : [];
    const bucketDetails = data?.data?.bucketData?.data ? data?.data?.bucketData?.data: [];

    const navigate = useNavigate()

    let alert = ""; 

    const breadcrumbs = [
        { name: '', path: '/' },
        { name: '', path: '' },

    ];

    


    const handleAscSort = (title) => {

        if (title === 'column3'){
            setSortField("usedSize")

        }


        if (title === 'column4') {
            setSortField("createdOn")

        }

        setSortBy(0)
       

    }


    const handleDescSort = (key) => {
        if (key === 'column3') {
            setSortField("usedSize")

        }


        if (key === 'column4') {
            setSortField("createdOn")

        }
        setSortBy(1)
      

    }




    const handleNavigation = (id,bucketName,bucketSize,bucketUsedSize,maxFileSize) => {
        localStorage.setItem("bucketId", id)
        localStorage.setItem("bucketName", bucketName)
        
        navigate('/filelist', { state: { bucketId: id, bucketName: bucketName, bucketSize: bucketSize, bucketUsedSize: bucketUsedSize,maxFileSize:maxFileSize } })
    }

   
    const handleChangePage = (event, newPage) => {
        setPage(newPage + 1);
    };


    const handleChangeRowsPerPage = (event) => {
        // debugger
        setRowsPerPage(+event.target.value);
  
    };


    //delete bucket
    const deleteBucket = async() => {
        
      

// if(!inputValue){
//     dispatch(setSnackBarData({ open: true, msg: "Please type Confirm first", severity: 'error' }));
//     return
// }

        try {
            setLoading(true); 
            const payload = {
                "bucketName": null,
                "bucketSecret": "string",
                "createdBy": 0,
                "createdByType": "Admin",
                "id": selectedBucketId,
                "isActive": true,
                "size": 0,
                "verificationKey": inputValue
            }

           
            // dispatch(
            //     createApiThunk(
            //         () => post2(DeleteBucket, payload),
            //         fetchSomeDataRequest.type,
            //         fetchSomeDataSuccess.type,
            //         fetchSomeDataError.type,
            //         "someData" 
            //     )
            // ).then(() => setIsBucketAdded(prev => !prev));
            const response = await post2(DeleteBucket, payload);
            if(response?.data?.status === 200){
                dispatch(setSnackBarData({ open: true, msg: response?.data?.message, severity: 'success' }));
                setIsBucketAdded(prev => !prev); 
                setInputValue("")
                setConfirmationOpen(false); 


            }
            else{
                dispatch(setSnackBarData({ open: true, msg: response?.data?.message, severity: 'error' }));

            }

        } catch (error) {
            dispatch(setSnackBarData({ open: true, msg: error?.response?.data, severity: 'error' }));
            console.log(error);
        } finally {
            setLoading(false); 
        }

    }
    const handleDeleteClick = (id) => {
        setSelectedBucketId(id)
        setConfirmationOpen(true);
    };

    const handleConfirmDelete = () => {
        // deleteBucket();
        deleteBucket(); 
        setConfirmationOpen(false);
    };



    const handleCloseConfirmation = () => {
        setConfirmationOpen(false);
    };



//ALERT


//yes
    const handleConfirmAlert = () => {
        add()

        setConfirmationAlertOpen(false);
    };



    const handleCloseConfirmationAlert = () => {
        setConfirmationAlertOpen(false);
    };

    //view
    const handleInfoClick = async(id) => {
        setLoading(true)
        try {
            const payload = {

                "id": id,

            }
            // dispatch(
            //     createApiThunk(
            //         () => post2(ViewBucketData, payload),
            //         fetchSomeDataRequest.type,
            //         fetchSomeDataSuccess.type,
            //         fetchSomeDataError.type,
            //         "bucketData"
            //     )
            // )

            const response = await post2(ViewBucketData, payload);
            if (response?.data?.status === 200) {
                setViewData(response?.data?.data)
                dispatch(setHistoryData({ open: true, data: response?.data?.data }));
            }
            else {
                dispatch(setSnackBarData({ open: true, msg: response?.data?.message, severity: 'error' }));

            }

            
        } catch (error) {
            console.log(error);
            dispatch(setSnackBarData({ open: true, msg: error.data.message, severity: 'error' }));

        } finally {
            setLoading(false);
        }

      
    };


  

    //add bucket
    const add = async () => {
        setLoading(true); 
        try {
            if (!values.bucketName.trim()  ) {


                dispatch(setSnackBarData({ open: true, msg: "Bucket name cannot be empty", severity: 'error' }));
           
                return;
            }

            // if(!values.bucketSize.trim()){
            //     dispatch(setSnackBarData({ open: true, msg: "Bucket size cannot be empty", severity: 'error' }));
            //     return

            // }

            if (!editMode) {
                const payload = {
                    "bucketName": values.bucketName,
                  
                    "size": values?.bucketSize ,
                    "deleteOnDays": values?.autoDelete  ,
                    "maxUploadFileSize": values?.maxUploadFileSize
                }

                // dispatch(
                //     createApiThunk(
                //         () => post2(AddBucket, payload), 
                //         fetchSomeDataRequest.type,
                //         fetchSomeDataSuccess.type,
                //         fetchSomeDataError.type,
                //         "someData" 
                //     )
                // ).then(() => setIsBucketAdded(prev => !prev));

                const res = await post2(AddBucket,payload)
                if (res.data.status === 200) {
                    dispatch(setSnackBarData({ open: true, msg: res.data.message, severity: 'success' }));
                    setValues({
                        bucketName: "",
                        bucketSize: "",
                        autoDelete: ""
                    })

                    setConfirmationAddOpen(false);
                    setIsBucketAdded(prev => !prev);
                   

                }
                else {
                    dispatch(setSnackBarData({ open: true, msg: res.data.message, severity: 'error' }));

                }

            }
            else {
                const payload = {

                    
                    "id": selectedBucketId,
                    "isActive": true,
                    "size": values?.bucketSize ? values?.bucketSize : "",
                    "deleteOnDays": values?.autoDelete ? values?.autoDelete : "",
                    "maxUploadFileSize": values?.maxUploadFileSize

                }
                // dispatch(
                //     createApiThunk(
                //         () => post2(EditBucket, payload), 
                //         fetchSomeDataRequest.type,
                //         fetchSomeDataSuccess.type,
                //         fetchSomeDataError.type,
                //         "someData" 
                //     )
                // ).then(() => setIsBucketAdded(prev => !prev));

                const res = await post2(EditBucket,payload)
                if (res.data.status === 200) {
                    dispatch(setSnackBarData({ open: true, msg: res.data.message, severity: 'success' }));
                    
                    setValues({
                        bucketName: "",
                        bucketSize: "",
                        autoDelete: ""
                    })

                    setConfirmationAddOpen(false);
                    setIsBucketAdded(prev => !prev);
                    setEditMode(false)

                }
                else {
                    dispatch(setSnackBarData({ open: true, msg: res.data.message, severity: 'error' }));

                }
                
            }

         

        } catch (error) {
            dispatch(setSnackBarData({ open: true, msg: error.data.message, severity: 'error' }));

            console.log(error);
        } finally {
            setLoading(false); 
        }
    };

    const handleAddClick = () => {

        setConfirmationAddOpen(true);
    };

   
    const handleEditClick = (id, bucketName, bucketSize, deleteFromRecyclebinOn, maxUploadFileSize) => {
      
        setInitialDeleteFromRecyclebinOn(deleteFromRecyclebinOn)
        setSelectedBucketId(id)
        setValues({
            bucketName: bucketName,
            bucketSize: bucketSize,
            autoDelete: deleteFromRecyclebinOn,
            maxUploadFileSize: maxUploadFileSize
        })
        setEditMode(true, bucketName, bucketSize)
        setConfirmationAddOpen(true);
    };


   




    const handleCloseAddConfirmation = () => {
        setValues({
            bucketName: "",
            bucketSize: "",
            autoDelete:""
        })
       
        setSelectedBucketName("")
        setSelectedBucketSize("")
        setSelectedBucketAutodelete("")
        setEditMode(false)
        setConfirmationAddOpen(false);

    };


    const handleChangeAdd = (field, value) => {
       
        setValues(prevValues => ({
            ...prevValues,
            [field]: value,
        }));
    };


    const fetchAlert = async () => {
        // debugger
        setLoading(true);
        try {
            const payload = {
                "id": selectedBucketId,
                "isActive": true,
                "size": values?.bucketSize ? values?.bucketSize : "",
                "deleteOnDays": values?.autoDelete ? values?.autoDelete : "",
                "maxUploadFileSize": values?.maxUploadFileSize

            }


            // dispatch(
            //     createApiThunk(
            //         () => post2(DeleteBucket, payload),
            //         fetchSomeDataRequest.type,
            //         fetchSomeDataSuccess.type,
            //         fetchSomeDataError.type,
            //         "someData" 
            //     )
            // ).then(() => setIsBucketAdded(prev => !prev));
            const response = await post2(AlertUpdate, payload);

            if (response?.data?.status === 200) {
                alert = response?.data?.data
                setAlertData(response?.data?.data)
               
                // setAlertData(response?.data?.data )

                // add()
                // dispatch(setSnackBarData({ open: true, msg: response?.data?.message, severity: 'success' }));
                // setIsBucketAdded(prev => !prev);

            }
            else {

                dispatch(setSnackBarData({ open: true, msg: response?.data?.message, severity: 'error' }));

            }

        } catch (error) {
            // dispatch(setSnackBarData({ open: true, msg: error?.response?.data, severity: 'error' }));
            console.log(error);
        } finally {
            setLoading(false);
        }

    }


    // const handleConfirmAdd =  () => {
    //     debugger
    //     console.log(initialDeleteFromRecyclebinOn, "initialSize")
    //     if (values.autoDelete < initialDeleteFromRecyclebinOn) {
    //         fetchAlert()
    //         if (alert === 0 ){
    //             add()
    //         }
    //         // else if(alertData !=0 && alertData !="notfound")
    //         //     {
    //         //     setConfirmationAlertOpen(true)
    //         //     }
    //         else if (alert !== 0  ){
    //             setConfirmationAlertOpen(true)
    //             console.log("helloalert111",alertData)

    //         }
    //         else{
    //             // add()
    //             // setConfirmationAlertOpen(true)
    //             console.log("hello error")
    //         }
    //         // console.log("helloalert", alertData)

    //         // setConfirmationAlertOpen(true)
    //     }
    //     else {
    //         add()

    //     }


    // };

    const handleConfirmAdd = async () => {
        // debugger
        try {

            if (values.autoDelete < initialDeleteFromRecyclebinOn) {
                await fetchAlert(); 
                if (alert === 0) {
                    add();
                } else if (alert !== undefined && alert !== 0 && alert !== "") {
                    setConfirmationAlertOpen(true);
                    
                } else {
                    console.log("hello error");
                }
            } else {
                add();
            }
        } catch (error) {
            console.error("Error in handleConfirmAdd:", error);
           
        }
    };





    const handleNavigationToRecycleBin = (id, bucketName,bucketPage) => {
        navigate('/recyclebinlisting', { state: { bucketId: id, bucketName: bucketName, bucketPage: bucketPage } })
    }

    const viewBucketData = () => {
        setLoading(true);
       
    }
    

    const tableHeader = [
        { title: 'Name', key: 'column1' },
        { title: 'Consumed / Size', key: 'column2' },
        // { title: 'Available ', key: 'column3' },
        { title: 'Available Size', key: 'column3' },
        { title: 'Created On (dd/mm/yyyy)', key: 'column4' },
        { title: 'Time (hh:mm:ss)', key: 'time' },
        { title: 'Created By', key: 'column7' },
        { title: 'Recyclebin Lifecycle (in days)', key: 'column6' },
        { title: 'Subbuckets', key: 'column8' },
        { title: 'Actions', key: 'column5' },
    ];

    const rows = (Array.isArray(datas) ? datas : []).map((bucket) => ({
        id: bucket?.id,
        column1: (
            <Box style={{ cursor: "pointer" }}>
                <Typography style={{ fontWeight: "bold", color: "#156f83" }} onClick={() => handleNavigation(bucket?.id, bucket?.bucketName, bucket?.size, bucket?.usedSize, bucket?.maxUploadFileSize)}>
                    {bucket?.bucketName}
                </Typography>
            </Box>
        ),
      
        column2: <Typography align="right">{`${bucket?.usedSize} / ${bucket?.size} GB` || ''}</Typography>,
        column3: <Typography align="right">{`${bucket?.freeSize}` || ''}</Typography>,
        // column9: `${bucket?.usedSize}` || '',
        column4: <Typography align="right">
            {moment(bucket?.createdOn).format('DD-MM-YYYY ')
                || ''}
        </Typography>,
        time: <Typography align="right">
            {moment(bucket?.createdOn).format('h: mm: ss A')
                || ''}
        </Typography>,
        column5: <Box style={{ cursor: "pointer" }}><Tooltip title="Delete"><IconButton><DeleteIcon style={{ cursor: "pointer" }} onClick={() => handleDeleteClick(bucket?.id)} /></IconButton></Tooltip>
        
            <Tooltip title="Edit"><IconButton><EditIcon onClick={() => handleEditClick(bucket?.id, bucket?.bucketName, bucket?.size, bucket?.deleteFromRecyclebinOn, bucket?.maxUploadFileSize)} /></IconButton></Tooltip>

            <Tooltip title="Recyclebin"><IconButton><RecyclingIcon onClick={() => handleNavigationToRecycleBin(bucket?.id, bucket?.bucketName,true)} /></IconButton></Tooltip>

            <Tooltip title="View"><IconButton><Info onClick={() => handleInfoClick(bucket?.id)}  /></IconButton></Tooltip>
            </Box>,
        // column6: `${bucket?.deleteFromRecyclebinOn}` || '',
        column6: (
            <Typography align="right">{`${bucket?.deleteFromRecyclebinOn}` || ''}</Typography>
        ),
        
        column7: <Typography>{`${bucket?.createdByName}` || ''}</Typography>,
        // column8: `${bucket?.subbucketCount}` || '',
        column8: (
            <Typography align="right">{`${bucket?.subbucketCount}` || ''}</Typography>
        ),
      
    }));

   
    const handleSearch = (event) => {
        if (event.key === "Enter") {
            setPage(1)
            fetchBuckets(searchName);
        }
    };

  

    const fetchBuckets = async () => {
        // debugger
     
        try {
            setLoading(true);
                const payload = {
                    "pageNo": page-1,
                    "pageSize": rowsPerPage,
                    "searchField":searchName,
                    "sortBy": sortBy,
                    "sortField": sortField,
                    // "companyId": 1,
                    // "userId": 3
                };
            dispatch(
                createApiThunk(
                    () => post2(Buckets, payload),
                    fetchSomeDataRequest.type,
                    fetchSomeDataSuccess.type,
                    fetchSomeDataError.type,
                    "someData" 
                )
            );
            // const response = await userService.post2(
            //     Buckets,
            //     payload,
              
            // );
            // if (response?.data?.status === 200) {
            //     setBucketList(response?.data?.data)
            // }
    
        } catch (error) {
            dispatch(setSnackBarData({ open: true, msg: error?.response?.data, severity: 'error' }));
            console.log(error);
        } finally {
            setLoading(false);
        }

    }
    
    useEffect(() => {
        setSelectedBucketSize()
        fetchBuckets();
    }, [page,rowsPerPage,isBucketAdded,sortBy,sortField,searchName]);

    useEffect(() =>{
        setPage(1)

    },[rowsPerPage])

 


    return (

        <>
            <div >
                <Box style={{ display: "flex", direction: 'row', justifyContent: 'space-between' }}>

                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>

                        {/*<DynamicBreadcrumb breadcrumbs={breadcrumbs} />*/}
                        <TitleHeading title="Buckets"  />
                    </Box>
                    {/* <h2>Buckets</h2> */}
                 
                   
                    <Box style={{ display: "flex", direction: 'row',backgroundColor:"" }}>
                        <SearchComponent
                            searchName={searchName}
                            setSearchName={setSearchName}
                            placeholder="Search Buckets..."
                        />

                        {/* <FormControl sx={{  m: 2 }} >
                        <TextField
                       
                            id="outlined-basic"
                            label="Search..."
                            placeholder='Bucket Name'
                            variant="outlined"
                            type="search"
                            value={searchName}
                            // onChange={ (e)handleCompanyNameChange}

                            onChange={(e) => {
                                

                                setSearchName(e.target.value);
                            }}

                                InputProps={{
                                    sx: {
                                        // fontSize: "12px",
                                        height:"30px"
                                    },
                                }}

                                InputLabelProps={{ shrink: true }} 
                            // onChange={(e)=>companyChange(e)}
                            onKeyPress={handleSearch}

                        />
                        </FormControl> */}
                        {/* <FormControl sx={{ float: "right", m: 2, mr: 1 }} >

                            <TextField
                                id="serachInputBox"
                                type="search"
                             
                                size='small'
                                value={searchName}
                                onChange={(e) => {
                                    setSearchName(e.target.value)
                                }}

                                // onKeyDown={(e) => {
                                //     const { key } = e;
                                //     if (key === 'Enter') {
                                //         e.preventDefault();
                                //         handleSearch()
                                //     }
                                // }}

                                InputProps={{
                                    sx: {
                                        fontSize: "12px"
                                    },
                                    endAdornment: (
                                        <InputAdornment >
                                           

                                            <IconButton sx={{ paddingLeft: '2px', paddingRight: '0px' }}
                                            onClick={{handleSearch}}
                                            >
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl> */}

                        <Button onClick={handleAddClick} variant='contained' style={{ height: "30px", marginTop: "14px" }}>+Add Bucket</Button>
                    </Box>

                </Box>

                <MainTable tableHeader={tableHeader} rows={rows} handleNavigation={handleNavigation} handleDeleteClick={handleDeleteClick}
                    // rowsPerPageOptions={[rowsPerPage]}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    totalRecords={data?.data?.someData?.data?.totalLength}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    blankRowSize="0"
                    handleAscSort = {handleAscSort}
                    handleDescSort = {handleDescSort}
                />
            </div>

            {/* Delete Confirmation dialog */}
            <ConfirmationModal
                isTextField={isTextField}
                open={confirmationOpen}
                handleClose={handleCloseConfirmation}
                handleConfirm={handleConfirmDelete}
                text = "Are you sure you want to delete this bucket?"
                inputValue={inputValue}
                setInputValue={setInputValue}
            />



            <ConfirmationModal

                open={confirmationAlertOpen}
                handleClose={handleCloseConfirmationAlert}
                handleConfirm={handleConfirmAlert}
                text={`${alertData} files will be deleted permanently....Are you sure to update the recyclebin holder duration?`}
                // handleChange={handleChange}
              
            />

            <AddBucketModal
                selectedBucketSize={selectedBucketSize}
                selectedBucketName={selectedBucketName}
                selectedBucketAutodelete={selectedBucketAutodelete}
                selectedBucketMaxUploadSizeFile={selectedBucketMaxUploadSizeFile}
                values={values}
                handleChangeAdd={handleChangeAdd}
                open={confirmationAddOpen}
                handleClose={handleCloseAddConfirmation}
                handleConfirm={handleConfirmAdd}
                editMode={editMode}
            />

            {loading && <LoadingComponent />}

        </>

    )
}