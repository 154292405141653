import { Clear, Folder, Info } from '@mui/icons-material'

import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, TextField, Tooltip, Typography } from '@mui/material'
import React, { useState, useEffect, useRef } from 'react'
import MainTable from '../../common_components/tables/MainTable'
import { SearchComponent } from '../../common_components/input_fields/SearchComponent'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AddBucket, DeleteBucket, EditBucket, FetchSize, FileDelete, FileDownload, FileDownloadUrl, FileToggleSwitch, FileUpload, FileView, FilesByBucketId, VideoStream, ViewBucketData } from '../../constant/apiEndpoints/buckets'
import { fetchSomeDataError, fetchSomeDataRequest, fetchSomeDataSuccess } from '../../redux/ApiDataSlice'
import { createApiThunk } from '../../redux/GenericThunk'
import { get2, post, post2, postFormData } from '../../services/AxiosGeneric'
import moment from 'moment'
import DownloadIcon from '@mui/icons-material/Download';
import InfoIcon from '@mui/icons-material/Info';
import config from '../Permission/config'
import axios from 'axios'
import DragAndDropUpload from './DragAndDropUpload'
import ConfirmationModal from './ConfirmationModal'
import VideoPlayerComponent from './VideoPlayer'
import VisibilityIcon from '@mui/icons-material/Visibility';
import LoadingComponent from '../../common_components/popups/LoadingComponent'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Switch from '@mui/material/Switch';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import AddBucketModal from './AddBucketModal'
import { setSnackBarData } from '../../redux/SnackBarSlice'
import { isVisible } from '@testing-library/user-event/dist/utils';
import RecyclingIcon from '@mui/icons-material/Recycling';
import { setHistoryData } from '../../redux/HistoryDataSlice'
import { Subbucket, SubbucketList } from './SubbucketList'
import DynamicBreadcrumb from '../../common_components/breadcrumbs/DynamicBreadcrumb'
import TitleHeading from '../../common_components/heading/TitleHeading'


const label = { inputProps: { 'aria-label': 'Switch demo' } };

export const FileList = () => {


    const [fileList, setFileList] = useState();
    const [subBucketList, setSubBucketList] = useState();
    const [allData,setAllData] = useState()

    const [isTextField, setIsTextField] = useState(false);


    const [searchName, setSearchName] = useState('');
    const [showUpload, setShowUpload] = useState(false);

    const [acceptedFiles, setAcceptedFiles] = useState([]);

    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');
    const [videoId, setVideoId] = useState()
    const [inputValue, setInputValue] = useState("");

    const [isBucketAdded, setIsBucketAdded] = useState(false);

    const [switchValues, setSwitchValues] = useState({});

    const [switchOn, setSwitchOn] = useState(false);
    const [alignment, setAlignment] = useState();
    const [isCreateSubbucket, setIsCreateSubbucket] = useState(false)
    const [isFileUpload, setIsFileUpload] = useState(false);

    //sorting
    const [sortBy, setSortBy] = useState();
    const [sortField, setSortField] = useState();

    //addbucket
    // const [confirmationOpen, setConfirmationOpen] = useState(false);

    const [selectedBucketId, setSelectedBucketId] = useState();
    const [editMode, setEditMode] = useState(false);
    const [confirmationAddOpen, setConfirmationAddOpen] = useState(false);
    const [selectedBucketName, setSelectedBucketName] = useState()
    const [selectedBucketSize, setSelectedBucketSize] = useState()
    const [selectedBucketAutodelete, setSelectedBucketAutodelete] = useState()
    const [selectedBucketMaxUploadSizeFile, setSelectedBucketMaxUploadSizeFile] = useState()
    const [isSubbucket, setIsSubbucket] = useState(true);
    const [values, setValues] = useState({
        bucketName: "",
        bucketSize: "",
        autoDelete: "",
        maxUploadFileSize: ""
    });
    const [selectedSubbucket, setSelectedSubbucket] = useState()

    const [loading, setLoading] = useState(false);

  
    const [uploadInProgress, setUploadInProgress] = useState(false);
    // let size=""
    // let usedSize = ""

    const [size,setSize] = useState(0);
    const [usedSize, setUsedSize] = useState(0);


    const dispatch = useDispatch();
    const navigate = useNavigate();
    const data = useSelector((state) => state.apiSlice);
    const datas = data?.data?.someData?.data ? data?.data?.someData?.data : [];
    // const fileDataMapped = data?.data?.someData?.data?.data?.bucketList ? data?.data?.someData?.data?.data?.bucketList : data?.data?.someData?.data?.data?.fileList ;

    const fileDataMapped = data?.data?.someData?.data?.data?.fileList ? data?.data?.someData?.data?.data?.fileList : [];


    const bucketDataMapped = data?.data?.someData?.data?.data?.bucketList ? data?.data?.someData?.data?.data?.bucketList : []



    const location = useLocation();

    // const [bucketId, setBucketId] = useState(location.state?.bucketId ? location.state?.bucketId : location.state?.mainBucketId)

    // const bucketId = location.state?.bucketId;
    const bucketId = localStorage.getItem("bucketId")
    const bucketName = localStorage.getItem("bucketName")
    
    // const bucketIdMain = location.state?.mainBucketId;
    const { mainBucketId } = location.state || {};

  


    // console.log(bucketIdMain,"bucketIdMain")
    // const bucketName = location.state?.bucketName;
    const bucketSize = location.state?.bucketSize;
    const bucketUsedSize = location.state?.bucketUsedSize;
    const bucketMaxFileSize = location.state?.maxFileSize;

    const bucketDetails = data?.data?.bucketData?.data ? data?.data?.bucketData?.data : [];
    const [isData, setIsData] = useState(datas.length === 0 ? "false" : "true");




    const breadcrumbs = [
        { name: '', path: '/bucketlist' },
        { name: '', path: '' },

    ];








    const handleChangePage = (event, newPage) => {
        setPage(newPage + 1);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);

    };

    const handleChange = (event, newValue) => {

        if (newValue !== null) {
            if (newValue === 'files') {
                setAlignment(false);
            } else if (newValue === 'subbucket') {
                setAlignment(true);

            }
        }
    };


    //sorting
    const handleAscSort = (title) => {


        if (title === 'column3') {
            setSortField("Size")
        }

        if (title === 'column4') {
            setSortField("createdOn")
        }

        setSortBy(0)
    }



    const handleDescSort = (key) => {
        if (key === 'column3') {
            setSortField("Size")

        }


        if (key === 'column4') {
            setSortField("createdOn")

        }
        setSortBy(1)


    }





    //add bucket
    const add = async () => {
        setLoading(true);
        try {
            if (!values.bucketName.trim()) {


                dispatch(setSnackBarData({ open: true, msg: "Bucket name cannot be empty", severity: 'error' }));

                // Handle error or display message to the user
                return;
            }

            // if(!values.bucketSize.trim()){
            //     dispatch(setSnackBarData({ open: true, msg: "Bucket size cannot be empty", severity: 'error' }));
            //     return

            // }

            if (!editMode) {
                const payload = {
                    "parentId": bucketId,
                    "bucketName": values.bucketName,

                    "size": values?.bucketSize,
                    "deleteOnDays": values?.autoDelete ? values?.autoDelete : "30",
                    "maxUploadFileSize": values?.maxUploadFileSize
                }

                // dispatch(
                //     createApiThunk(
                //         () => post2(AddBucket, payload),
                //         fetchSomeDataRequest.type,
                //         fetchSomeDataSuccess.type,
                //         fetchSomeDataError.type,
                //         "someData"
                //     )
                // )

                //     .then(() => setIsBucketAdded(prev => !prev));
                const res = await post2(AddBucket, payload)
                if (res.data.status === 200) {
                    dispatch(setSnackBarData({ open: true, msg: res.data.message, severity: 'success' }));
                    setValues({
                        bucketName: "",
                        bucketSize: "",
                        autoDelete: ""
                    })
                    fetchSize()
                    setConfirmationAddOpen(false);
                    setIsBucketAdded(prev => !prev);

                }
                else {
                    dispatch(setSnackBarData({ open: true, msg: res.data.message, severity: 'error' }));

                }
            }
            else {
                const payload = {


                    "id": selectedBucketId,
                    "isActive": true,
                    "size": values?.bucketSize ? values?.bucketSize : "",
                    "deleteOnDays": values?.autoDelete ? values?.autoDelete : "",
                    "maxUploadFileSize": values?.maxUploadFileSize

                }
                // dispatch(
                //     createApiThunk(
                //         () => post2(EditBucket, payload),
                //         fetchSomeDataRequest.type,
                //         fetchSomeDataSuccess.type,
                //         fetchSomeDataError.type,
                //         "someData"
                //     )
                // ).then(() => setIsBucketAdded(prev => !prev));

                const response = await post2(EditBucket, payload);
                if (response?.data?.status === 200) {
                    dispatch(setSnackBarData({ open: true, msg: response?.data?.message, severity: 'success' }));
                    setIsBucketAdded(prev => !prev);
                    setEditMode(false)

                }
                else {
                    dispatch(setSnackBarData({ open: true, msg: response.data.message, severity: 'error' }));

                }


            }

            setValues({
                bucketName: "",
                bucketSize: "",
                autoDelete: ""
            })

            setConfirmationAddOpen(false);

        } catch (error) {
            console.log(error);
            dispatch(setSnackBarData({ open: true, msg: error?.data?.message, severity: 'error' }));
        } finally {
            setLoading(false);
        }
    };

    const handleAddClick = () => {
        setConfirmationAddOpen(true);
    };


    const handleEditClick = (id, bucketName, bucketSize, deleteFromRecyclebinOn, maxUploadFileSize) => {
        setSelectedBucketId(id)
        // setSelectedBucketName(bucketName)
        // setSelectedBucketSize(bucketSize)
        // setSelectedBucketAutodelete(deleteFromRecyclebinOn)
        setValues({
            bucketName: bucketName,
            bucketSize: bucketSize,
            autoDelete: deleteFromRecyclebinOn,
            maxUploadFileSize: maxUploadFileSize
        })
        setEditMode(true, bucketName, bucketSize)
        setConfirmationAddOpen(true);
    };



    const handleConfirmAdd = () => {
        add()

    };

    const handleCloseAddConfirmation = () => {
        setValues({
            bucketName: "",
            bucketSize: "",
            autoDelete: ""
        })

        setSelectedBucketName("")
        setSelectedBucketSize("")
        setSelectedBucketAutodelete("")
        setEditMode(false)
        setConfirmationAddOpen(false);

    };


    const handleChangeAdd = (field, value) => {

        setValues(prevValues => ({
            ...prevValues,
            [field]: value,
        }));
    };


    const deleteFile = async () => {
        // debugger

        // if (!inputValue && fileList === null) {
        //     dispatch(setSnackBarData({ open: true, msg: "Please type Confirm first", severity: 'error' }));

        //     return
        // }

  
        try {
            setLoading(true)
            const payload = {
                "id": selectedFile ? selectedFile :selectedSubbucket ,
                "verificationKey":inputValue
            }


            // dispatch(
            //     createApiThunk(
            //         () => post2(FileDelete, payload),
            //         fetchSomeDataRequest.type,
            //         fetchSomeDataSuccess.type,
            //         fetchSomeDataError.type,
            //         "someData"
            //     )

            // ).then(() => setIsBucketAdded(prev => !prev));

            if(fileList === null ){

                // if (!inputValue) {
                //     dispatch(setSnackBarData({ open: true, msg: "Please type Confirm first", severity: 'error' }));

                //     return
                // }
                // setIsTextField(true)
                const response = await post2(DeleteBucket, payload);
                if (response?.status === 200) {
                    dispatch(setSnackBarData({ open: true, msg: response?.data?.message, severity: 'success' }));
                    setIsBucketAdded(prev => !prev);
                    fetchSize()
                    setInputValue("")

                }
                else {
                    dispatch(setSnackBarData({ open: true, msg: response.data.message, severity: 'error' }));

                }

            }
            else{

                // setIsTextField(false)
                const response = await post2(FileDelete, payload);
                if (response?.status === 200) {
                    dispatch(setSnackBarData({ open: true, msg: response?.data?.message, severity: 'success' }));
                    setIsBucketAdded(prev => !prev);
                    fetchSize()

                }
                else {
                    dispatch(setSnackBarData({ open: true, msg: response.data.message, severity: 'error' }));

                }

            }
           


        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }

    }
    const handleDeleteClick = (id) => {
        
        setSelectedFile(id)
        setConfirmationOpen(true);
    };



    const handleDeleteClickSubbucket = (id) => {
        setSelectedSubbucket(id)
        setConfirmationOpen(true);
    };


    const handleConfirmDelete = () => {
        deleteFile();
        setConfirmationOpen(false);
    };

    const handleCloseConfirmation = () => {
        setConfirmationOpen(false);
    };


    // const handleDownloadClick = (id) => {
    //     const apiUrl = ` ${config.baseUrl2}${FileDownload}/${id}`;

    //     const link = document.createElement('a');
    //     link.href = apiUrl;
    //     // link.target = '_blank';
    //     link.download = '';
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);

    // };

    const [loadingStates, setLoadingStates] = useState({});

    const handleDownloadClick = (id) => {
        if (loadingStates[id]) return; 
        setLoadingStates(prev => ({ ...prev, [id]: true }));

        const apiUrl = `${config.baseUrl}${FileDownload}/${id}`;
        const link = document.createElement('a');
        link.href = apiUrl;
        link.download = ''; 
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

  
        setTimeout(() => {
            setLoadingStates(prev => ({ ...prev, [id]: false })); 
        }, 5000); 
    };



    // const handleCopyUrl = (path) => {
    //     const apiUrl = ` ${config.baseUrl2}${FileDownloadUrl}/${path}`;
    //     navigator?.clipboard?.writeText(apiUrl);
    // };

    const handleCopyUrl = (path) => {
        const apiUrl = `${config.baseUrl}${FileDownloadUrl}/${path}`;

        if (navigator.clipboard) {
            navigator.clipboard.writeText(apiUrl)
                .then(() => {
                  
                    dispatch(setSnackBarData({ open: true, msg: "Copied Succesfully", severity: 'success' }));
                })
                .catch((err) => {
                    console.error('Failed to copy URL:', err);
                    dispatch(setSnackBarData({ open: true, msg: "Failed to copy URL", severity: 'error' }));
                });
        } else {
            
            const textArea = document.createElement('textarea');
            textArea.value = apiUrl;
            document.body.appendChild(textArea);
            textArea.select();

            try {
                document.execCommand('copy');
            } catch (err) {
                console.error('Failed to copy URL (fallback):', err);
            }

            document.body.removeChild(textArea);
        }
    };


    // switch
    // Function to handle switch state change
    const handleSwitchChange = (event, id) => {
        const { checked } = event.target;
        setSwitchValues(prevState => ({
            ...prevState,
            [id]: checked
        }));

        updateSwitchStateInBackend(id, checked);
    };

    const updateSwitchStateInBackend = async (id, checked) => {

        try {
            const payload = {
                id: id,
                isActive: checked
            };

            // await axios.post('http://192.168.12.38:8881/files/change-link-status', payload);

            // setSwitchOn(true);

            const response = await post2(FileToggleSwitch, payload)
            if (response?.status === 200) {
                dispatch(setSnackBarData({ open: true, msg: response?.data?.message, severity: 'success' }));
                setIsBucketAdded(prev => !prev);

            }
            else {
                dispatch(setSnackBarData({ open: true, msg: response.data.message, severity: 'error' }));

            }

         
        } catch (error) {
            console.error('Error updating switch state:', error);
        }
    };




    const handleNavigationToSubbucket = (id, bucketName, bucketSize, bucketUsedSize) => {

        navigate('/subbucket', { state: { bucketId: id, bucketName: bucketName, bucketSize: bucketSize, bucketUsedSize: bucketUsedSize, bucketDataMapped: bucketDataMapped } })
    }

    const handleNavigation = (id, subbucketName, bucketSize, bucketUsedSize,maxFileSize) => {
        navigate('/subbucketfilelist', { state: { bucketId: id, bucketName: subbucketName, bucketSize: bucketSize, bucketUsedSize: bucketUsedSize, mainBucketName: bucketName, mainBucketId: bucketId, maxFileSize: maxFileSize } })
        // fetchFiles()
    }


    const handleNavigationToRecycleBin = (id, bucketName,subbucketPage) => {
        navigate('/recyclebinlisting', { state: { bucketId: id, bucketName: bucketName, subbucketPage: subbucketPage } })
    }

    //view
    const handleInfoClick = async (id) => {

        setLoading(true)
        try {
            const payload = {
                "id": id,
            }


            // dispatch(
            //     createApiThunk(
            //         () => post2(ViewBucketData, payload),
            //         fetchSomeDataRequest.type,
            //         fetchSomeDataSuccess.type,
            //         fetchSomeDataError.type,
            //         "bucketData"
            //     )
            // )
            const response = await post2(ViewBucketData, payload);
            if (response?.data?.status === 200) {
                
                dispatch(setHistoryData({ open: true, data: response?.data?.data }));
            }
            else {
                dispatch(setSnackBarData({ open: true, msg: response?.data?.message, severity: 'error' }));

            }

        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }



    };


    const handleOpenInNewTabClick = async (id) => {

        try {
            const payload = {
                id: id
            };

            // const response = await axios.post(config.baseUrl2 + FileView, payload);
            const response = await post2(FileView, payload);


            const type = response?.data?.data
            const dataAS = response?.data
            const files = response?.data?.data;
            const base64String = files?.encodedBase64;
            const fileName = files?.name;
            const fileType = fileName?.split('.').pop().toLowerCase();


            // if (!base64String) {

            //     window.open(config.baseUrl2 + VideoStream + `?key=${id}` );
            //     return;
            // }
            if (type === "VIDEO") {
                const videoStreamUrl = `${config.baseUrl}${VideoStream}?key=${id}`;
                setVideoUrl(videoStreamUrl);
                setDialogOpen(true);
                return;
            }

            // if (base64String){

            // }

            const byteCharacters = atob(base64String);
            const byteNumbers = new Array(byteCharacters.length);

            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }


            const byteArray = new Uint8Array(byteNumbers);


            let mimeType;
            switch (fileType) {
                case 'pdf':
                    mimeType = 'application/pdf';
                    break;
                // case 'excel':
                case 'xlsx':
                    mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                    break;
                case 'png':
                    mimeType = 'image/png';
                    break;
                case 'mp4':
                    mimeType = 'video/mp4';
                    break;
                case 'jpeg':
                    mimeType = 'image/jpeg';
                    break;
                case 'gif':
                    mimeType = 'image/gif';
                    break;
                case 'bmp':
                    mimeType = 'image/bmp';
                    break;
                case 'webp':
                    mimeType = 'image/webp';
                    break;
                case 'svg':
                    mimeType = 'image/svg+xml';
                    break;
                case 'ico':
                    mimeType = 'image/vnd.microsoft.icon';
                    break;


                case 'json':
                    mimeType = 'application/json';
                    break;
                case 'sql':
                    mimeType = 'application/sql';
                    break;
                case 'html':
                    mimeType = 'application/html';
                    break;
                case 'zip':
                    mimeType = 'application/zip';
                    break;
                case 'mp3':
                    mimeType = 'audio/mpeg';
                    break;
                case 'wav':
                    mimeType = 'audio/wav';
                    break;
                case 'ogg':
                    mimeType = 'audio/ogg';
                    break;
                case 'm4a':
                    mimeType = 'audio/mp4';
                    break;

                //VIDEO
                case 'mp4':
                    mimeType = 'video/mp4';
                    break;
                case 'webm':
                    mimeType = 'video/webm';
                    break;
                case 'ogg':
                    mimeType = 'video/ogg';
                    break;
                case 'm4v':
                    mimeType = 'video/mp4';
                    break;
                case 'mov':
                    mimeType = 'video/quicktime';
                    break;

                case 'zip':
                    mimeType = 'application/zip';
                    break;
                default:
                    mimeType = 'application/octet-stream';
                    break;
            }



            const extensions = [
                // Document Formats
                "pdf",

                // Image Formats
                "jpeg",
                "jpg",
                "png",
                "gif",
                "bmp",
                "webp",
                "svg",
                "ico",

                // Audio Formats
                "mp3",
                "wav",
                "ogg",
                "m4a",


            ];


            const blob = new Blob([byteArray], { type: mimeType });
            if (extensions.includes(fileType)) {

                const blobUrl = URL.createObjectURL(blob);
                window.open(blobUrl, '_blank');

            }

            else {
                const url = URL.createObjectURL(blob);
                const anchorElement = document.createElement('a');
                anchorElement.href = url;
                anchorElement.download = fileName;
                anchorElement.click();

            }


        } catch (error) {
            console.log(error);
        }
    };


    const tableHeader = [
        { title: 'Name', key: 'column1' },
        { title: 'Size', key: 'column3' },

        // { title: 'Used Size', key: 'column3' },
        // { title: 'Subbucket', key: 'column6' },
        { title: 'Created On (dd/mm/yyyy)', key: 'column4' },
        { title: 'Time (hh:mm:ss)', key: 'time' },
        { title: 'Created By', key: 'column7' },
      
        { title: 'Actions', key: 'column5' },
        { title: 'Link (Active / Inactive)', key: 'toggle' },
       
        
    ];
   
    if (allData?.data?.isCreateSubbucket === true) {
       
        tableHeader.splice(2, 0, { title: 'Subbucket', key: 'column6' });
    }




    const tableHeaderBucket = [
        { title: 'Name', key: 'column1' },
        // { title: 'Size', key: 'column2' },
        { title: 'Consumed / Size', key: 'column2' },
        { title: 'Available Size', key: 'column9' },
        { title: 'Created On (dd/mm/yyyy)', key: 'column4' },
        { title: 'Time (hh:mm:ss)' , key: 'time' },
        { title: 'Created By', key: 'column7' },

        { title: 'Recyclebin Lifecycle (in days)', key: 'column6' },
   
        { title: 'Actions', key: 'column5' },
    ];

    const rowsBucket = (Array.isArray(subBucketList) ? subBucketList : []).map((bucket) => ({
        id: bucket?.id,
        column1: (
            <Box style={{ cursor: "pointer" }}>
                <Typography style={{ fontWeight: "bold", color: "#156f83" }}
                    onClick={() => handleNavigation(bucket?.id, bucket?.bucketName, bucket?.size, bucket?.usedSize, bucket?.maxUploadFileSize)}
                >
                    {bucket?.bucketName}
                </Typography>
            </Box>
        ),
        column2: <Typography align="right">{`${bucket?.usedSize} / ${bucket?.size} GB` || ''}</Typography>,

        // column2: <Typography align="right" > {`${bucket?.size} GB` || ''}</Typography>,
        column3: <Typography align="right" > {`${bucket?.usedSize}` || ''}</Typography>,
        column4: <Typography align="right">{moment(bucket?.createdOn).format('DD/MM/YYYY ')
            || ''}</Typography>,
        time: <Typography align="right">
            {moment(bucket?.createdOn).format(' h: mm: ss A')
                || ''}
        </Typography>,
    

        column5: <Box style={{ cursor: "pointer" }}><Tooltip title="Delete"><IconButton><DeleteIcon style={{ cursor: "pointer" }} onClick={() => handleDeleteClickSubbucket(bucket?.id)} /></IconButton></Tooltip>

            <Tooltip title="Edit"><IconButton><EditIcon onClick={() => handleEditClick(bucket?.id, bucket?.bucketName, bucket?.size, bucket?.deleteFromRecyclebinOn, bucket?.maxUploadFileSize)} /></IconButton></Tooltip>

            <Tooltip title="Recyclebin"><IconButton><RecyclingIcon

                onClick={() => handleNavigationToRecycleBin(bucket?.id, bucket?.bucketName,true)}

            /></IconButton></Tooltip>

            <Tooltip title="View"><IconButton><Info

                onClick={() => handleInfoClick(bucket?.id)}

            /></IconButton></Tooltip>


        </Box>,
        column6: <Typography align="right">{`${bucket?.deleteFromRecyclebinOn}` || ''}</Typography>,
        column7: <Typography>{`${bucket?.createdByName}` || ''}</Typography>,

        column9: <Typography align="right">{`${bucket?.freeSize}` || ''}</Typography>,

    }));

    const rows = (Array.isArray(fileList) ? fileList : []).map((file) => {
        const fileName = file?.name || '';
        const fileType = fileName.split('.').pop().toLowerCase();
        const supportedFileExtensions = [
            // Document Formats
            "pdf",

            // Image Formats
            "jpeg",
            "jpg",
            "png",
            "gif",
            "bmp",
            "webp",
            "svg",
            "ico",

            // Audio Formats
            "mp3",
            "wav",
            "ogg",
            "m4a",

            // Video Formats
            "mp4", "mkv", "mov", "wmv", "avi", "avchd", "wemb", "flv", "f4v", "swf"
        ];
        const isValidFileType = supportedFileExtensions.includes(fileType);
        return {
            id: file?.id,
            column1: < Box >
                <Typography style={{ fontWeight: "bold", color: "#156f83" }}

                >
                    {fileName}
                </Typography>
            </Box >,

            column3: <Typography align="right">{`${file?.fileSize}` || ''}</Typography>,

            column4: <Typography align="right">{moment(file?.createdOn).format('DD-MM-YYYY ') || ''}</Typography>,
            time: <Typography align="right">{moment(file?.createdOn).format(' h: mm: ss A')
                || ''}</Typography>,
            column7: <Typography>{`${file?.createdByName}` || ''}</Typography>,

            toggle: <Switch
                // checked={file?.isLinkActive}

                // checked={switchValues[file.id] || false}
                onChange={(event) => handleSwitchChange(event, file.id)}
                {...label}
                defaultChecked={file?.isLinkActive}
            />,
            column6: <Typography>{`${file?.bucket}` || ''}</Typography>,

            column5: (
                <Box style={{ cursor: "pointer" }}>
                    <Tooltip title="Download">
                        {/* <IconButton>
                            <DownloadIcon style={{ cursor: "pointer" }} onClick={() => handleDownloadClick(file?.id)} />
                        </IconButton> */}
                        <IconButton onClick={() => handleDownloadClick(file?.id)} disabled={loadingStates[file?.id] || false}>
                          <DownloadIcon/>
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Delete">
                        <IconButton>
                            <DeleteIcon style={{ cursor: "pointer" }} onClick={() => handleDeleteClick(file?.id)} />
                        </IconButton>
                    </Tooltip>



                    <Tooltip title="View">
                        <IconButton disabled={!isValidFileType}>
                            <VisibilityIcon style={{ cursor: "pointer" }}
                                onClick={() => handleOpenInNewTabClick(file?.id)} />
                        </IconButton>
                    </Tooltip>


                    <Tooltip title="Copy url">
                        <IconButton disabled={file?.isLinkActive === false} >

                            <ContentCopyIcon style={{ cursor: "pointer" }}
                                onClick={() => handleCopyUrl(file?.path)} />
                        </IconButton>
                    </Tooltip>

                    {/* <Switch
                        // checked={file?.isLinkActive}

                        // checked={switchValues[file.id] || false}
                        onChange={(event) => handleSwitchChange(event, file.id)}
                        {...label}
                        defaultChecked={file?.isLinkActive}
                    /> */}


                </Box>
            ),
            fileType: fileType

        };
    });


    const handleSearch = (event) => {
        if (event.key === "Enter") {
            setPage(1)
            fetchFiles(searchName);
        }
    };



    const fetchFiles = async () => {

        setLoading(true)
        try {
            const payload = {

                "bucketId": bucketId,
                "pageNo": page - 1,
                "pageSize": rowsPerPage,
                "searchField": searchName,
                "sortBy": sortBy,
                "sortField": sortField,
                "isSubbucketVisible": alignment
            };



            const res = await post2(FilesByBucketId, payload)

            // if(res?.data?.status === 200){
              
            //     if (res?.data?.data?.data?.fileList !== null && res?.data?.data?.data?.bucketList === null ){
            //         setFileList(res?.data?.data?.data?.fileList?.length > 0 ? res?.data?.data?.data?.fileList : [] )
            //     }
            //     else if (res?.data?.data?.data?.bucketList !== null && res?.data?.data?.data?.fileList === null) {
            //          setSubBucketList(res?.data?.data?.data?.bucketList?.length > 0 ? res?.data?.data?.data?.bucketList : [])
            //     }
            // }

             if(res?.data?.status === 200){

                 setAllData(res?.data?.data )

              
                 if (res?.data?.data?.data?.bucketList === null  ){
                    setFileList(res?.data?.data?.data?.fileList )
                    setSubBucketList(null)
                }
                else if (res?.data?.data?.data?.fileList === null) {
                     setSubBucketList(res?.data?.data?.data?.bucketList)
                     setFileList(null)
                }
            }

            
            // dispatch(
            //     createApiThunk(
            //         () => post2(FilesByBucketId, payload),
            //         fetchSomeDataRequest.type,
            //         fetchSomeDataSuccess.type,
            //         fetchSomeDataError.type,
            //         "someData"
            //     )
            // )

        } catch (error) {
            dispatch(setSnackBarData({ open: true, msg: error.response?.data, severity: 'error' }));
            console.log(error);
        } finally {
            setLoading(false);
        }


        // dispatch(
        //     createApiThunk(
        //         () => post2(FilesByBucketId, payload),
        //         fetchSomeDataRequest.type,
        //         fetchSomeDataSuccess.type,
        //         fetchSomeDataError.type,
        //         "someData"
        //     )


        // ).finally(() => {
        //     setLoading(false);
        // });

        // hasMounted.current = true;
        // }
        // else{
        //     hasMounted.current = false;
        // }


    };




    const fetchSize = async () => {

        setLoading(true)
        try {
            const payload = {
           
                // "bucketName": "string",
              
                "id": bucketId,
            
            }



            const res = await post2(FetchSize, payload)

            if(res?.data?.status === 200){


                // setSize(res?.data?.data?.size);
                // setUsedSize(res?.data?.data?.usedSize) 
                converter(res?.data?.data?.size, res?.data?.data?.usedSize)
            //    converter(10,5)
         
            }
            // dispatch(
            //     createApiThunk(
            //         () => post2(FetchSize, payload),
            //         fetchSomeDataRequest.type,
            //         fetchSomeDataSuccess.type,
            //         fetchSomeDataError.type,
            //         "someData"
            //     )
            // )

        } catch (error) {
            dispatch(setSnackBarData({ open: true, msg: error.response?.data, severity: 'error' }));
            console.log(error);
        } finally {
            setLoading(false);
        }


        // dispatch(
        //     createApiThunk(
        //         () => post2(FilesByBucketId, payload),
        //         fetchSomeDataRequest.type,
        //         fetchSomeDataSuccess.type,
        //         fetchSomeDataError.type,
        //         "someData"
        //     )


        // ).finally(() => {
        //     setLoading(false);
        // });

        // hasMounted.current = true;
        // }
        // else{
        //     hasMounted.current = false;
        // }


    };
    const [buttonDisabled, setButtonDisabled] = useState(false); 
    const handleFileDrop = async (acceptedFiles) => {
// debugger
   
        setAcceptedFiles(acceptedFiles);
        setLoading(true)
        const formData = new FormData();
        // acceptedFiles.forEach(file => {
        //     // if (file.size <= bucketMaxFileSize) {
        //     //     formData?.append('files', file);
        //     // } else {
        //     //     dispatch(`File size exceeds the maximum limit : ${bucketMaxFileSize}`);
        //     // }
        //     if(file){
        //         formData?.append('files', file);

        //     }
        // });

        acceptedFiles.forEach(file => {
            if (file instanceof File <= bucketMaxFileSize) {
                formData.append('files', file);
                formData.append('companyId', localStorage.getItem('userCompanyId'));
                formData.append('createdBy', localStorage.getItem('userId'));
            }

        });


        // const maxFileSizeGB = 50; // Maximum file size allowed in GB
        // const maxFileSizeBytes = maxFileSizeGB * 1024 * 1024 * 1024; // Convert GB to bytes

        // acceptedFiles.forEach((file) => {
        //     if (file.size <= maxFileSizeBytes) {
        //         formData.append('files', file);
        //     } else {
        //         alert('File size exceeds the maximum limit (50GB):', file.name);
        //     }
        // });

        try {
            const params = {
                bucketId: bucketId,
                'userId': localStorage.getItem('userId'),
                'userCompanyId': localStorage.getItem("userCompanyId")
            };

            // const response = await axios.post(`${config.baseUrl2}files/upload?bucketId=${bucketId}`, formData, {
            //     headers: {
            //         'Content-Type': 'multipart/form-data',
            //         // 'userId': localStorage.getItem('userId'),
            //         // 'userCompanyId': localStorage.getItem("userCompanyId"),

            //     }
            // });

            const response = await postFormData(`${FileUpload}?bucketId=${bucketId}`, formData)

            if (response?.data?.status === 200) {

                dispatch(setSnackBarData({ open: true, msg: response?.data?.message, severity: 'success' }));
                setShowUpload(false)

                fetchFiles();
                fetchSize();
            } else {

                dispatch(setSnackBarData({ open: true, msg: response?.data?.message, severity: 'error' }));

            }
        } catch (error) {
            console.error('Error uploading files:', error);
            dispatch(setSnackBarData({ open: true, msg: error?.data?.message, severity: 'error' }));
        }finally {
            setLoading(false);
        }
    };


    const handleClearClick = () => {
        setAcceptedFiles([]); // Clear the files
        setShowUpload(false); // Close the dialog
    };


    const converter = (size, usedSize) => {

        const sizeUsed = usedSize !== "0.0" ? parseFloat(usedSize) : 0

  
        if (sizeUsed < 1024) {
            const totalSize = `${(size * 1024).toFixed(2)} MB`
            const used = `${sizeUsed.toFixed(2)} MB`; 

          
            setSize(totalSize)
            setUsedSize(used)

        } else if (sizeUsed >= 1024) {
            const sizeUsed = `${(usedSize / 1024).toFixed(2)} GB`
            const totalSize = `${size} GB`
            setUsedSize(sizeUsed)
            setSize(totalSize)

        }

    }


    useEffect(()=>{
        fetchSize()

    },[])

    useEffect(() => {
        fetchFiles();


    }, [dispatch, page, rowsPerPage, isBucketAdded, sortBy, sortField, alignment,searchName]);


    useEffect(() => {
        setPage(1)

    }, [rowsPerPage])



    return (

        <>
            <div >
                <Box style={{ display: "flex", direction: 'row', justifyContent: 'space-between' }}>

                    <Box sx={{ display: 'flex', flexDirection: 'row', }}>
                        <DynamicBreadcrumb breadcrumbs={breadcrumbs} style={{ marginTop: 2 }} />
                        {/* <Link to="/bucketlist" style={{ textDecoration: 'none', color: 'purple' }}> */}
                            <TitleHeading title={`${bucketName} `} />
                        {/* </Link> */}

                        {/* <TitleHeading title={` (${bucketSize} GB / ${bucketUsedSize})`} /> */}
                        <TitleHeading title={` (${usedSize}  / ${size})`} />
                        

                    </Box>

                    {/* <TitleHeading title = {`${bucketName}  ( ${bucketSize} GB / ${bucketUsedSize})`}/> */}
                    <div>

                    </div>
                    <Box style={{ display: "flex", direction: 'row' }}>
                        {/* <SearchComponent
                            searchName={searchName}
                            setSearchName={setSearchName}
                            placeholder="Search Buckets..."
                        /> */}

                        {allData?.data?.isCreateSubbucket === true &&
                            <ToggleButtonGroup
                                style={{ height: "30px", marginTop: "15px" }}
                                color="primary"
                                value={alignment ? 'subbucket' : 'files'}

                                exclusive
                                onChange={handleChange}
                                aria-label="Platform"
                            >

                                <ToggleButton value="files" onClick={handleChange}>Files</ToggleButton>
                                <ToggleButton value="subbucket" onClick={handleChange}>Sub-bucket</ToggleButton>
                            </ToggleButtonGroup>
                        }

                         <SearchComponent
                            searchName={searchName}
                            setSearchName={setSearchName}
                            placeholder= { (alignment === true && fileList=== null) ? "Search Buckets..." :"Search Files..."}
                        /> 



                        {allData?.data?.isFileUpload === true && 
                            
                            <Button variant='contained' style={{ height: "30px", marginTop: "15px", marginBottom: "" }} onClick={() => setShowUpload(!showUpload)}>+ Upload Files</Button>
                        
                        }

                        {allData?.data?.isCreateSubbucket === true && <Button variant='contained' style={{ height: "30px", marginTop: "15px", marginBottom: "", marginLeft: "10px" }} onClick={handleAddClick} >Create Sub Bucket</Button>}

                    </Box>

                </Box>

               
                <Dialog open={showUpload} onClose={() => setShowUpload(false)} fullWidth>
                    <DialogTitle>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography>Upload Files</Typography>
                            <IconButton onClick={handleClearClick}>
                                <Clear  sx={{color:"white"}}/>
                            </IconButton>
                        </Box>
                    
                    
                    </DialogTitle>
                    <DialogContent style={{ height: "", backgroundColor: "" }} >
                        <DragAndDropUpload onDrop={handleFileDrop} setShowUpload={setShowUpload} bucketMaxFileSize={bucketMaxFileSize}  />
                    </DialogContent>
                    {/* <DialogActions>
                        
                        <Button onClick={() => setShowUpload(false)} color="primary">
                            Close
                        </Button>
                    </DialogActions> */}
                </Dialog>

{/*                 
                {(bucketDataMapped?.length > 0 && fileDataMapped?.length === 0) && <MainTable tableHeader={tableHeaderBucket} rows={rowsBucket} page={page}
                    rowsPerPage={rowsPerPage}
                    totalRecords={data?.data?.someData?.data?.totalLength}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    blankRowSize="0"
                    handleAscSort={handleAscSort}
                    handleDescSort={handleDescSort}
                />}

                {(fileList.length > 0 && !subBucketList ) &&
                {(fileDataMapped?.length >= 0 && bucketDataMapped?.length === 0) &&

                    <MainTable tableHeader={tableHeader} rows={rows} page={page}
                        rowsPerPage={rowsPerPage}
                        totalRecords={data?.data?.someData?.data?.totalLength}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        blankRowSize="0"
                        handleAscSort={handleAscSort}
                        handleDescSort={handleDescSort}
                    />
                } */}

                <MainTable
                    tableHeader={subBucketList === null ?  tableHeader : tableHeaderBucket}
                    rows={subBucketList === null ? rows : rowsBucket}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    totalRecords={allData?.totalLength}
                    // totalRecords={data?.data?.someData?.data?.totalLength}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    blankRowSize="0"
                    handleAscSort={handleAscSort}
                    handleDescSort={handleDescSort}
                />


                {/* {(bucketDataMapped?.length > 0 && fileDataMapped.length === 0) && <Subbucket/> } */}


                {/* Delete Confirmation dialog */}
                <ConfirmationModal
                    open={confirmationOpen}
                    handleClose={handleCloseConfirmation}
                    handleConfirm={handleConfirmDelete}
                    text="Are you sure you want to delete this file?"
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                    isTextField={ fileList ? false : true }
                    setIsTextField={setIsTextField}
                />
                <Dialog maxWidth="lg"
                    open={dialogOpen} onClose={() => setDialogOpen(false)}>
                    {/* <DialogTitle>Video Player</DialogTitle> */}
                    <DialogContent >

                        <VideoPlayerComponent sx={{ width: '100%', height: 'auto' }} videoUrl={videoUrl} videoId={videoId} />
                    </DialogContent>
                    {/* <DialogActions>
                        <Button onClick={() => setDialogOpen(false)}>Close</Button>
                    </DialogActions> */}
                </Dialog>

                <AddBucketModal
                    selectedBucketSize={selectedBucketSize}
                    selectedBucketName={selectedBucketName}
                    selectedBucketAutodelete={selectedBucketAutodelete}
                    selectedBucketMaxUploadSizeFile={selectedBucketMaxUploadSizeFile}
                    values={values}
                    handleChangeAdd={handleChangeAdd}
                    open={confirmationAddOpen}
                    handleClose={handleCloseAddConfirmation}
                    handleConfirm={handleConfirmAdd}
                    editMode={editMode}
                    isSubbucket={isSubbucket}
                />

            </div>
            {loading && <LoadingComponent />}

        </>

    )
}