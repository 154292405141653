// src/app/store.js

import { configureStore } from '@reduxjs/toolkit';
import authReducer from './AuthSlice'
import ApiDataSlice from './ApiDataSlice';
import  snackBarSlice  from './SnackBarSlice';
import  historyDataSlice  from './HistoryDataSlice';

export const store = configureStore({
  reducer: {
    authSlice: authReducer,
    apiSlice:ApiDataSlice,
    snackBarSlice:snackBarSlice,
    historySlice :historyDataSlice
  },
});
