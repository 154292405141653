import { Clear, Folder, Info } from '@mui/icons-material'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, TextField, Tooltip, Typography } from '@mui/material'
import React, { useState, useEffect, useRef } from 'react'
import MainTable from '../../common_components/tables/MainTable'
import { SearchComponent } from '../../common_components/input_fields/SearchComponent'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AddBucket, EditBucket, FetchSize, FileDelete, FileDownload, FileDownloadUrl, FileToggleSwitch, FileUpload, FileView, FilesByBucketId, VideoStream } from '../../constant/apiEndpoints/buckets'
import { fetchSomeDataError, fetchSomeDataRequest, fetchSomeDataSuccess } from '../../redux/ApiDataSlice'
import { createApiThunk } from '../../redux/GenericThunk'
import {post2, postFormData } from '../../services/AxiosGeneric'
import moment from 'moment'
import DownloadIcon from '@mui/icons-material/Download';
import config from '../Permission/config'
import DragAndDropUpload from './DragAndDropUpload'
import ConfirmationModal from './ConfirmationModal'
import VideoPlayerComponent from './VideoPlayer'
import VisibilityIcon from '@mui/icons-material/Visibility';
import LoadingComponent from '../../common_components/popups/LoadingComponent'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Switch from '@mui/material/Switch';
import AddBucketModal from './AddBucketModal'
import { setSnackBarData } from '../../redux/SnackBarSlice'
import RecyclingIcon from '@mui/icons-material/Recycling';
import TitleHeading from '../../common_components/heading/TitleHeading'
import DynamicBreadcrumb from '../../common_components/breadcrumbs/DynamicBreadcrumb'


const label = { inputProps: { 'aria-label': 'Switch demo' } };

export const SubbucketFileList = () => {
    const [searchName, setSearchName] = useState('');
    const [showUpload, setShowUpload] = useState(false);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');
    const [videoId, setVideoId] = useState()
    const [isBucketAdded, setIsBucketAdded] = useState(false);
    const [switchValues, setSwitchValues] = useState({});
    const [subbucketFileList, setSubbucketFileList] = useState([])
    const [allData, setAllData] = useState()
    const [alignment, setAlignment] = useState();
    //sorting
    const [sortBy, setSortBy] = useState();
    const [sortField, setSortField] = useState();

    const [selectedBucketId, setSelectedBucketId] = useState();
    const [editMode, setEditMode] = useState(false);
    const [confirmationAddOpen, setConfirmationAddOpen] = useState(false);
    const [selectedBucketName, setSelectedBucketName] = useState()
    const [selectedBucketSize, setSelectedBucketSize] = useState()
    const [selectedBucketAutodelete, setSelectedBucketAutodelete] = useState()
    const [selectedBucketMaxUploadSizeFile, setSelectedBucketMaxUploadSizeFile] = useState()
    const [values, setValues] = useState({
        bucketName: "",
        bucketSize: "",
        autoDelete: "",
        maxUploadFileSize: ""
    });

    const [size, setSize] = useState(0);
    const [usedSize, setUsedSize] = useState(0);
    const [inputValue, setInputValue] = useState("");

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const data = useSelector((state) => state.apiSlice);
    const datas = data?.data?.someData?.data ? data?.data?.someData?.data : [];
    // const fileDataMapped = data?.data?.someData?.data?.data?.bucketList ? data?.data?.someData?.data?.data?.bucketList : data?.data?.someData?.data?.data?.fileList ;

    const fileDataMapped = data?.data?.someData?.data?.data?.fileList ? data?.data?.someData?.data?.data?.fileList : [];
    const bucketDataMapped = data?.data?.someData?.data?.data?.bucketList ? data?.data?.someData?.data?.data?.bucketList : []





    
    const location = useLocation();
    const bucketId = location.state?.bucketId;
    const bucketName = location.state?.bucketName;
    const bucketSize = location.state?.bucketSize;
    const bucketUsedSize = location.state?.bucketUsedSize;

    const bucketMaxFileSize = location.state?.maxFileSize;



    const mainBucketName = location?.state?.mainBucketName;

    const mainBucketId = location?.state?.mainBucketId;

    const [isData, setIsData] = useState(datas.length === 0 ? "false" : "true");



    const breadcrumbs = [
        { name: '', path: '/filelist' },
        { name: '', path: '' },

    ];



    const handleChangePage = (event, newPage) => {
        setPage(newPage + 1);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);

    };

    const handleChange = (event, newValue) => {

        if (newValue !== null) {
            if (newValue === 'files') {
                setAlignment(false);
            } else if (newValue === 'subbucket') {
                setAlignment(true);
            }
        }
    };


    const [loading, setLoading] = useState(false)





    //sorting
    const handleAscSort = (title) => {


        if (title === 'column3') {
            setSortField("Size")

        }


        if (title === 'column4') {
            setSortField("createdOn")

        }

        setSortBy(0)


    }



    const handleDescSort = (key) => {
        if (key === 'column3') {
            setSortField("Size")

        }


        if (key === 'column4') {
            setSortField("createdOn")

        }
        setSortBy(1)


    }





    //add bucket
    const add = () => {
        setLoading(true);
        try {
            if (!values.bucketName.trim()) {


                dispatch(setSnackBarData({ open: true, msg: "Bucket name cannot be empty", severity: 'error' }));

                // Handle error or display message to the user
                return;
            }

            // if(!values.bucketSize.trim()){
            //     dispatch(setSnackBarData({ open: true, msg: "Bucket size cannot be empty", severity: 'error' }));
            //     return

            // }

            if (!editMode) {
                const payload = {
                    "parentId": bucketId,
                    "bucketName": values.bucketName,

                    "size": values?.bucketSize ? values?.bucketSize : "10",
                    "deleteOnDays": values?.autoDelete ? values?.autoDelete : "30",
                    "maxUploadFileSize": values?.maxUploadFileSize
                }

                dispatch(
                    createApiThunk(
                        () => post2(AddBucket, payload),
                        fetchSomeDataRequest.type,
                        fetchSomeDataSuccess.type,
                        fetchSomeDataError.type,
                        "someData"
                    )
                )

                    .then(() => setIsBucketAdded(prev => !prev));



            }
            else {
                const payload = {


                    "id": selectedBucketId,
                    "isActive": true,
                    "size": values?.bucketSize ? values?.bucketSize : "",
                    "deleteOnDays": values?.autoDelete ? values?.autoDelete : "",
                    "maxUploadFileSize": values?.maxUploadFileSize

                }
                dispatch(
                    createApiThunk(
                        () => post2(EditBucket, payload),
                        fetchSomeDataRequest.type,
                        fetchSomeDataSuccess.type,
                        fetchSomeDataError.type,
                        "someData"
                    )
                ).then(() => setIsBucketAdded(prev => !prev));

                ;
            }

            setValues({
                bucketName: "",
                bucketSize: "",
                autoDelete: ""
            })

            setConfirmationAddOpen(false);
            fetchSize()





        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false); 
        }
    };

    const handleAddClick = () => {

        setConfirmationAddOpen(true);
    };


    const handleEditClick = (id, bucketName, bucketSize, deleteFromRecyclebinOn, maxUploadFileSize) => {
       
        setSelectedBucketId(id)
        // setSelectedBucketName(bucketName)
        // setSelectedBucketSize(bucketSize)
        // setSelectedBucketAutodelete(deleteFromRecyclebinOn)
        setValues({
            bucketName: bucketName,
            bucketSize: bucketSize,
            autoDelete: deleteFromRecyclebinOn,
            maxUploadFileSize: maxUploadFileSize
        })
        setEditMode(true, bucketName, bucketSize)
        setConfirmationAddOpen(true);
    };


    const handleConfirmAdd = () => {
        add()

    };

    const handleCloseAddConfirmation = () => {
        setValues({
            bucketName: "",
            bucketSize: "",
            autoDelete: ""
        })

        setSelectedBucketName("")
        setSelectedBucketSize("")
        setSelectedBucketAutodelete("")
        setEditMode(false)
        setConfirmationAddOpen(false);

    };


    const handleChangeAdd = (field, value) => {

        setValues(prevValues => ({
            ...prevValues,
            [field]: value,
        }));
    };







    const deleteFile = async() => {
        // if (!inputValue) {
        //     dispatch(setSnackBarData({ open: true, msg: "Please type Confirm first", severity: 'error' }));

        //     return
        // }

      
        try {

            setLoading(true)
            const payload = {
                "id": selectedFile,
                

            }


            // dispatch(
            //     createApiThunk(
            //         () => post2(FileDelete, payload),
            //         fetchSomeDataRequest.type,
            //         fetchSomeDataSuccess.type,
            //         fetchSomeDataError.type,
            //         "someData"
            //     )

            // ).then(() => setIsBucketAdded(prev => !prev));
            
            const response = await post2(FileDelete, payload);
            if (response?.status === 200) {
                dispatch(setSnackBarData({ open: true, msg: response?.message, severity: 'success' }));
                setIsBucketAdded(prev => !prev);
                fetchSize()
            }
            else {
                dispatch(setSnackBarData({ open: true, msg: response?.data?.message, severity: 'error' }));

            }


        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }

    }
    const handleDeleteClick = (id) => {
        setSelectedFile(id)
        setConfirmationOpen(true);
    };

    const handleConfirmDelete = () => {

        deleteFile();

        setConfirmationOpen(false);
    };

    const handleCloseConfirmation = () => {
        setConfirmationOpen(false);
    };







    // const handleDownloadClick = (id) => {
    //     const apiUrl = ` ${config.baseUrl2}${FileDownload}/${id}`;
        
    //     const link = document.createElement('a');
    //     link.href = apiUrl;
    //     // link.target = '_blank';
    //     link.download = '';
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);

    // };

    const [loadingStates, setLoadingStates] = useState({});

    const handleDownloadClick = (id) => {
        if (loadingStates[id]) return;
        setLoadingStates(prev => ({ ...prev, [id]: true }));

        const apiUrl = `${config.baseUrl}${FileDownload}/${id}`;
        const link = document.createElement('a');
        link.href = apiUrl;
        link.download = '';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);


        setTimeout(() => {
            setLoadingStates(prev => ({ ...prev, [id]: false }));
        }, 5000);
    };




    // const handleCopyUrl = (path) => {

    //     const apiUrl = ` ${config.baseUrl2}${FileDownloadUrl}/${path}`;
    //     navigator?.clipboard?.writeText(apiUrl);
    //     dispatch(setSnackBarData({ open: true, msg:"COPIED SUCCESSFULLY", severity: 'error' }));
    // };




    const handleCopyUrl = (path) => {
        // debugger
        const apiUrl = `${config.baseUrl}${FileDownloadUrl}/${path}`;

      
        if (navigator.clipboard) {
            navigator.clipboard.writeText(apiUrl)
                .then(() => {
            
                    dispatch(setSnackBarData({ open: true, msg: "Copied Succesfully", severity: 'success' }));
                })
                .catch((err) => {
                    console.error('Failed to copy URL:', err);
                    dispatch(setSnackBarData({ open: true, msg: "Failed to copy URL", severity: 'error' }));
                });
        } else {
           
            const textArea = document.createElement('textarea');
            textArea.value = apiUrl;
            document.body.appendChild(textArea);
            textArea.select();

            try {
                document.execCommand('copy');
      
            } catch (err) {
                console.error('Failed to copy URL (fallback):', err);
            }

            document.body.removeChild(textArea);
        }
    };

    // switch



    // Function to handle switch state change
    const handleSwitchChange = (event, id) => {
        const { checked } = event.target;
        setSwitchValues(prevState => ({
            ...prevState,
            [id]: checked
        }));

        updateSwitchStateInBackend(id, checked);
    };

    const updateSwitchStateInBackend = async (id, checked) => {
       
        try {
            const payload = {
                id: id,
                isActive: checked
            };

            // await axios.post('http://192.168.12.38:8881/files/change-link-status', payload);

            // setSwitchOn(true);

            const response = await post2(FileToggleSwitch,payload)
            if (response?.status === 200) {
                dispatch(setSnackBarData({ open: true, msg: response?.data?.message, severity: 'success' }));
                setIsBucketAdded(prev => !prev);

            }
            else {
                dispatch(setSnackBarData({ open: true, msg: response?.data?.message, severity: 'error' }));

            }

        
        } catch (error) {
            console.error('Error updating switch state:', error);
        }
    };


   

    const handleNavigation = (id, bucketName, bucketSize, bucketUsedSize) => {
        navigate('/subucketlisting', { state: { bucketId: id, bucketName: bucketName, bucketSize: bucketSize, bucketUsedSize: bucketUsedSize } })

    }

    // const handleOpenInNewTabClick = async (id) => {
    //     try {

    //         const payload = {
    //             id: id
    //         }
    //         // Dispatch the createApiThunk action
    //         // dispatch(
    //         //     createApiThunk(
    //         //         () => post2(FileView, payload), // Pass a function reference
    //         //         fetchSomeDataRequest.type,
    //         //         fetchSomeDataSuccess.type,
    //         //         fetchSomeDataError.type,// Action type for error
    //         //         "fileData" // Key for data in the action payload
    //         //     )
    //         // );

    //         const response = await axios.post(config.baseUrl2 + FileView, payload);
    //         console.log(response, "response")
    //         const files = response?.data?.data;
    //         const base64String = files?.encodedBase64;
    //         const fileName = files?.name;
    //         const fileType = fileName?.split('.').pop().toLowerCase(); 
    //         const byteCharacters = atob(base64String);
    //         const byteNumbers = new Array(byteCharacters.length);
    //         for (let i = 0; i < byteCharacters.length; i++) {
    //             byteNumbers[i] = byteCharacters.charCodeAt(i);
    //         }

    //         const byteArray = new Uint8Array(byteNumbers);


    //         let mimeType;
    //         switch (fileType) {
    //             case 'pdf':
    //                 mimeType = 'application/pdf';
    //                 break;
    //             case 'excel':
    //                 mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    //                 break;
    //             case 'xlsx':
    //                 mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    //                 break;
    //             case 'png':
    //                 mimeType = 'image/png';
    //                 break;

    //             case 'mp4':
    //                 mimeType = 'video/mp4';
    //                 break;

    //             case 'jpeg':
    //                 mimeType = 'image/jpeg';
    //                 break;

    //             case 'json':
    //                 mimeType = 'application/json';
    //                 break;
    //             case 'sql':
    //                 mimeType = 'application/sql';
    //                 break;
    //             case 'html':
    //                 mimeType = 'application/html';
    //                 break;
    //             default:
    //                 fileType = 'application/octet-stream';
    //                 break;

    //         }

    //         const blob = new Blob([byteArray], { type: mimeType });
    //         if (fileType === 'mp4') {
    //             const url = URL.createObjectURL(blob);
    //             const anchorElement = document.createElement('a');
    //             anchorElement.href = url;
    //             anchorElement.download = fileName; 
    //             anchorElement.click();
    //         } else {
    //             const blobUrl = URL.createObjectURL(blob);
    //             window.open(blobUrl, '_blank');
    //         }
    //         const blobUrl = URL.createObjectURL(blob);
    //         window.open(blobUrl, '_blank');
    //     } catch (error) {

    //         console.log(error);
    //     }



    // }




    const handleOpenInNewTabClick = async (id) => {

        try {
            const payload = {
                id: id
            };

            // const response = await axios.post(config.baseUrl2 + FileView, payload);
            const response = await post2(FileView, payload);


            const type = response?.data?.data
            const dataAS = response?.data
            const files = response?.data?.data;
            const base64String = files?.encodedBase64;
            const fileName = files?.name;
            const fileType = fileName?.split('.').pop().toLowerCase();


            // if (!base64String) {

            //     window.open(config.baseUrl2 + VideoStream + `?key=${id}` );
            //     return;
            // }
            if (type === "VIDEO") {
                const videoStreamUrl = `${config.baseUrl}${VideoStream}?key=${id}`;
                setVideoUrl(videoStreamUrl);
                setDialogOpen(true);
                return;
            }

            // if (base64String){

            // }

            const byteCharacters = atob(base64String);
            const byteNumbers = new Array(byteCharacters.length);

            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }


            const byteArray = new Uint8Array(byteNumbers);


            let mimeType;
            switch (fileType) {
                case 'pdf':
                    mimeType = 'application/pdf';
                    break;
                // case 'excel':
                case 'xlsx':
                    mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                    break;
                case 'png':
                    mimeType = 'image/png';
                    break;
                case 'mp4':
                    mimeType = 'video/mp4';
                    break;
                case 'jpeg':
                    mimeType = 'image/jpeg';
                    break;
                case 'gif':
                    mimeType = 'image/gif';
                    break;
                case 'bmp':
                    mimeType = 'image/bmp';
                    break;
                case 'webp':
                    mimeType = 'image/webp';
                    break;
                case 'svg':
                    mimeType = 'image/svg+xml';
                    break;
                case 'ico':
                    mimeType = 'image/vnd.microsoft.icon';
                    break;


                case 'json':
                    mimeType = 'application/json';
                    break;
                case 'sql':
                    mimeType = 'application/sql';
                    break;
                case 'html':
                    mimeType = 'application/html';
                    break;
                case 'zip':
                    mimeType = 'application/zip';
                    break;
                case 'mp3':
                    mimeType = 'audio/mpeg';
                    break;
                case 'wav':
                    mimeType = 'audio/wav';
                    break;
                case 'ogg':
                    mimeType = 'audio/ogg';
                    break;
                case 'm4a':
                    mimeType = 'audio/mp4';
                    break;

                //VIDEO
                case 'mp4':
                    mimeType = 'video/mp4';
                    break;
                case 'webm':
                    mimeType = 'video/webm';
                    break;
                case 'ogg':
                    mimeType = 'video/ogg';
                    break;
                case 'm4v':
                    mimeType = 'video/mp4';
                    break;
                case 'mov':
                    mimeType = 'video/quicktime';
                    break;

                case 'zip':
                    mimeType = 'application/zip';
                    break;
                default:
                    mimeType = 'application/octet-stream';
                    break;
            }



            const extensions = [
                // Document Formats
                "pdf",

                // Image Formats
                "jpeg",
                "jpg",
                "png",
                "gif",
                "bmp",
                "webp",
                "svg",
                "ico",

                // Audio Formats
                "mp3",
                "wav",
                "ogg",
                "m4a",


            ];


            const blob = new Blob([byteArray], { type: mimeType });
            if (extensions.includes(fileType)) {

                const blobUrl = URL.createObjectURL(blob);
                window.open(blobUrl, '_blank');

            }

            else {
                const url = URL.createObjectURL(blob);
                const anchorElement = document.createElement('a');
                anchorElement.href = url;
                anchorElement.download = fileName;
                anchorElement.click();

            }


        } catch (error) {
            console.log(error);
        }
    };


    // const handleOpenInNewTabClick = async (id) => {
    //     try {
    //         const payload = {
    //             id: id
    //         };

    //         const response = await axios.post(config.baseUrl2 + FileView, payload);
    //         console.log(response, "response");

    //         const files = response?.data?.data;
    //         const base64String = files?.encodedBase64;
    //         const fileName = files?.name;
    //         const fileType = fileName?.split('.').pop().toLowerCase();

    //         if (!base64String) {
    //             debugger
    //             // If response doesn't contain a base64 string, open in new tab
    //             const newTab = window.open(config.baseUrl2 + VideoStream + '/' + id, '_blank');

    //             let cursorLeftTab = false;

    //             // Add event listener to track when cursor leaves the new tab
    //             newTab.addEventListener('mouseleave', async function handleMouseLeave(event) {
    //                 cursorLeftTab = true;
    //                 // Hit the API to track cursor leaving
    //                 try {
    //                     if (cursorLeftTab) {
    //                         // Your API call here
    //                         // Example:
    //                         await axios.post(config.baseUrl2 + VideoStream, { id });
    //                         console.log('Cursor left the video tab');
    //                     }
    //                 } catch (error) {
    //                     console.error('Error tracking cursor leaving:', error);
    //                 }
    //             });

    //             // Add event listener to track when cursor enters the new tab
    //             newTab.addEventListener('mouseenter', async function handleMouseEnter(event) {
    //                 cursorLeftTab = false;
    //             });

    //             return;
    //         }

    //         // Rest of your code for handling files
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };




    const tableHeader = [
        { title: 'Name', key: 'column1' },
        { title: 'Size', key: 'column3' },
        // { title: 'Used Size', key: 'column3' },
        { title: 'Time (hh:mm:ss)', key: 'time' },
        { title: 'Created On (dd/mm/yyyy)', key: 'column4' },
        { title: 'Created By', key: 'column7' },
        { title: 'Actions', key: 'column5' },

    ];



    const tableHeaderBucket = [


        { title: 'Name', key: 'column1' },
        { title: 'Size', key: 'column2' },
        { title: 'Used Size', key: 'column3' },
        { title: 'Created On (dd/mm/yyyy)', key: 'column4' },
        { title: 'Created By', key: 'column7' },
        { title: 'Recyclebin Lifecycle (in days)', key: 'column6' },
        { title: 'Actions', key: 'column5' },
        { title: 'Time (hh:mm:ss)', key: 'time' },
    ];

    const rowsBucket = (Array.isArray(bucketDataMapped) ? bucketDataMapped : []).map((bucket) => ({
        id: bucket?.id,
        column1: (
            <Box style={{ cursor: "pointer" }}>
                <Typography style={{ fontWeight: "bold", color: "" }}


                    onClick={() => handleNavigation(bucket?.id, bucket?.bucketName, bucket?.size, bucket?.usedSize)}

                >
                    {bucket?.bucketName}
                </Typography>
            </Box>
        ),
        // column1: {
        //     value: bucket?.bucketName || '',
        //     // onDoubleClick: () => handleNavigation(bucket?.id)  // Double-click handler for column1
        // },

        column2: <Typography align="right">{`${bucket?.size} GB` || ''}</Typography>,
        column3: <Typography align="right">{`${bucket?.usedSize}` || ''}</Typography>,
        column4: <Typography align="right">{moment(bucket?.createdOn).format('DD-MM-YYYY ')
            || ''}</Typography>,
        time: <Typography align="right">
            {moment(bucket?.createdOn).format(' h: mm: ss A')
                || ''}
        </Typography>,

        column5: <Box style={{ cursor: "pointer" }}><Tooltip title="Delete"><IconButton><DeleteIcon style={{ cursor: "pointer" }} onClick={() => handleDeleteClick(bucket?.id)} /></IconButton></Tooltip>

            <Tooltip title="Edit"><IconButton><EditIcon onClick={() => handleEditClick(bucket?.id, bucket?.bucketName, bucket?.size, bucket?.deleteFromRecyclebinOn, bucket?.maxUploadFileSize)} /></IconButton></Tooltip>

            <Tooltip title="Recyclebin"><IconButton><RecyclingIcon

            // onClick={() => handleNavigationToRecycleBin(bucket?.id, bucket?.bucketName)}

            /></IconButton></Tooltip>

            <Tooltip title="View"><IconButton><Info

            // onClick={() => handleInfoClick(bucket?.id)} 

            /></IconButton></Tooltip>


        </Box>,
        column6: <Typography align="right">{`${bucket?.deleteFromRecyclebinOn}` || ''}</Typography>,
        column7: <Typography align="right">{`${bucket?.createdByName}` || ''}</Typography>,

    }));

    // const rows = (Array.isArray(datas) ? datas : []).map((file) => ({



    //     id: file?.id,
    //     column1: file?.name || '',
    //     column2: `${file?.fileSize}` || '',
    //     // column3: `${bucket?.usedSize}` || '',
    //     column4: moment(file?.createdOn).format('YYYY-MM-DD')
    //         || '',
    //     column5: <Box style={{ cursor: "pointer" }}>
    //         <Tooltip title="Download">
    //             <IconButton>  <DownloadIcon style={{ cursor: "pointer" }} onClick={() => handleDownloadClick(file?.path)} /></IconButton>

    //         </Tooltip>

    //         <Tooltip title="View"><IconButton >   <InfoIcon style={{ cursor: "pointer" }} onClick={() => handleOpenInNewTabClick(file?.id)} /></IconButton></Tooltip>

    //         <Tooltip title="Delete"><IconButton>   <DeleteIcon style={{ cursor: "pointer" }} onClick={() => handleDeleteClick(file?.id)} /></IconButton></Tooltip>


    //          </Box>,

    // }));

    const rows = (Array.isArray(subbucketFileList) ? subbucketFileList : []).map((file) => {
        const fileName = file?.name || '';
        const fileType = fileName.split('.').pop().toLowerCase();
        const supportedFileExtensions = [
            // Document Formats
            "pdf",

            // Image Formats
            "jpeg",
            "jpg",
            "png",
            "gif",
            "bmp",
            "webp",
            "svg",
            "ico",

            // Audio Formats
            "mp3",
            "wav",
            "ogg",
            "m4a",

            // Video Formats
            "mp4", "mkv", "mov", "wmv", "avi", "avchd", "wemb", "flv", "f4v", "swf"
        ];
        const isValidFileType = supportedFileExtensions.includes(fileType);



        return {
            id: file?.id,
            column1: fileName,
            column3: `${file?.fileSize}` || '',
            column4: moment(file?.createdOn).format('DD-MM-YYYY ') || '',
            time: <Typography align="right">{moment(file?.createdOn).format(' h: mm: ss A')
                || ''}</Typography>,
            column7: <Typography>{`${file?.createdByName}` || ''}</Typography>,
            column5: (
                <Box style={{ cursor: "pointer" }}>
                    <Tooltip title="Download">
                        {/* <IconButton>
                            <DownloadIcon style={{ cursor: "pointer" }} onClick={() => handleDownloadClick(file?.id)} />
                        </IconButton> */}

                        <IconButton onClick={() => handleDownloadClick(file?.id)} disabled={loadingStates[file?.id] || false}>
                            <DownloadIcon />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Delete">
                        <IconButton>
                            <DeleteIcon style={{ cursor: "pointer" }} onClick={() => handleDeleteClick(file?.id)} />
                        </IconButton>
                    </Tooltip>



                    <Tooltip title="View">
                        <IconButton disabled={!isValidFileType}>
                       
                            <VisibilityIcon style={{ cursor: "pointer" }}
                                onClick={() => handleOpenInNewTabClick(file?.id)} />
                        </IconButton>
                    </Tooltip>


                    <Tooltip title="Copy url">
                        <IconButton disabled={file?.isLinkActive === false} >

                            <ContentCopyIcon style={{ cursor: "pointer" }}
                                onClick={() => handleCopyUrl(file?.path)} />
                        </IconButton>
                    </Tooltip>

                    <Switch
                        // checked={file?.isLinkActive}

                        // checked={switchValues[file.id] || false}
                        onChange={(event) => handleSwitchChange(event, file.id)}
                        {...label}
                        defaultChecked={file?.isLinkActive}
                    />


                </Box>
            ),
            fileType: fileType

        };
    });








    const filteredRows = rows.filter(row =>
        row.column1.toLowerCase().includes(searchName.toLowerCase())
    );

    const hasMounted = useRef(false);

    // useEffect(() => {


    //     if (!hasMounted.current) {

    //         const payload = {
    //             "bucketId": bucketId,
    //             "pageNo": 1,
    //             "pageSize": 10,
    //             // "searchField": "6840478"
    //         }


    //         dispatch(
    //             createApiThunk(
    //                 () =>
    //                     post2(FilesByBucketId, payload),
    //                 fetchSomeDataRequest.type,
    //                 fetchSomeDataSuccess.type,
    //                 fetchSomeDataError.type,
    //                 "someData"
    //             )
    //         )




    //         // dispatch(
    //         //     createApiThunk(
    //         //         simpleGet2(Buckets),
    //         //         fetchSomeDataRequest.type,
    //         //         fetchSomeDataSuccess.type,
    //         //         fetchSomeDataError.type,
    //         //         "otherData"
    //         //     )
    //         // );



    //         hasMounted.current = true;
    //     }


    // }, [dispatch]);
    const handleSearch = (event) => {
        if (event.key === "Enter") {
            setPage(1)
            fetchSubbucketFiles(searchName);
        }
    };



    const fetchSize = async () => {

        setLoading(true)
        try {
            const payload = {

                // "bucketName": "string",

                "id": bucketId,

            }



            const res = await post2(FetchSize, payload)

            if (res?.data?.status === 200) {
                converter(res?.data?.data?.size, res?.data?.data?.usedSize)

                // setSize(res?.data?.data?.size);
                // setUsedSize(res?.data?.data?.usedSize)


          
            }

          
            // dispatch(
            //     createApiThunk(
            //         () => post2(FetchSize, payload),
            //         fetchSomeDataRequest.type,
            //         fetchSomeDataSuccess.type,
            //         fetchSomeDataError.type,
            //         "someData"
            //     )
            // )

        } catch (error) {
            dispatch(setSnackBarData({ open: true, msg: error.response?.data, severity: 'error' }));
            console.log(error);
        } finally {
            setLoading(false);
        }


        // dispatch(
        //     createApiThunk(
        //         () => post2(FilesByBucketId, payload),
        //         fetchSomeDataRequest.type,
        //         fetchSomeDataSuccess.type,
        //         fetchSomeDataError.type,
        //         "someData"
        //     )


        // ).finally(() => {
        //     setLoading(false);
        // });

        // hasMounted.current = true;
        // }
        // else{
        //     hasMounted.current = false;
        // }


    };


const[totalLength,setTotalLength] = useState();
    const fetchSubbucketFiles = async() => {

        setLoading(true)
        // if (!hasMounted.current) {

        try{
            const payload = {

                "bucketId": bucketId,
                "pageNo": page - 1,
                "pageSize": rowsPerPage,
                "searchField": searchName,
                "sortBy": sortBy,
                "sortField": sortField,
                "isSubbucketVisible": alignment
            }

            const res = await post2(FilesByBucketId, payload)

            if (res?.data?.status === 200) {

                setAllData(res?.data?.data)
                setSubbucketFileList(res?.data?.data?.data?.fileList || [])
                setTotalLength(res?.data?.data?.totalLength)
                // if (res?.data?.data?.data?.bucketList === null) {
                //     setFileList(res?.data?.data?.data?.fileList)
                //     setSubBucketList(null)
                // }
                // else if (res?.data?.data?.data?.fileList === null) {
                //     setSubBucketList(res?.data?.data?.data?.bucketList)
                //     setFileList(null)
                // }
            

        }
      
    } catch (error) {
        dispatch(setSnackBarData({ open: true, msg: error.response?.data, severity: 'error' }));
        console.log(error);
    } finally {
        setLoading(false);
    }






       



        // dispatch(
        //     createApiThunk(
        //         () => post2(FilesByBucketId, payload),
        //         fetchSomeDataRequest.type,
        //         fetchSomeDataSuccess.type,
        //         fetchSomeDataError.type,
        //         "someData"
        //     )


        // ).finally(() => {
        //     setLoading(false);
        // });

        // hasMounted.current = true;
        // }
        // else{
        //     hasMounted.current = false;
        // }


    };


    //view
    // const handleInfoClick = (id) => {
    //     try {
    //         const payload = {

    //             "id": id,

    //         }


    //         dispatch(
    //             createApiThunk(
    //                 () => post2(ViewBucketData, payload),
    //                 fetchSomeDataRequest.type,
    //                 fetchSomeDataSuccess.type,
    //                 fetchSomeDataError.type,
    //                 "bucketData"
    //             )
    //         )
    //     } catch (error) {
    //         console.log(error);
    //     } finally {
    //         setLoading(false);
    //     }

    //     setViewData()


    //     dispatch(setHistoryData({ open: true, data: bucketDetails }));
    // };

    const handleFileDrop = async (acceptedFiles) => {

    
        const formData = new FormData();
        acceptedFiles.forEach(file => {
            if (file instanceof File) {
                formData.append('files', file);
                formData.append('companyId', localStorage.getItem('userCompanyId'));
                formData.append('createdBy', localStorage.getItem('userId'));


            }

        });

       

        try {
            const params = {
                bucketId: bucketId,
            };
            setLoading(true)

            // const response = await axios.post(`${config.baseUrl2}files/upload?bucketId=${bucketId}`, formData, {
            //     headers: {
            //         'Content-Type': 'multipart/form-data',
            //         'userId': localStorage.getItem('userId'),
            //         'userCompanyId': localStorage.getItem("userCompanyId"),

            //     }
            // });
            const response = await postFormData(`${FileUpload}?bucketId=${bucketId}`, formData)


            if (response?.data?.status === 200) {
           
                dispatch(setSnackBarData({ open: true, msg: response?.data?.message, severity: 'success' }));
                setShowUpload(false)
                fetchSubbucketFiles()
                fetchSize();
            } else {

                dispatch(setSnackBarData({ open: true, msg: response?.data?.message, severity: 'error' }));

            }
        } catch (error) {
            console.error('Error uploading files:', error);
            dispatch(setSnackBarData({ open: true, msg: error?.data?.message, severity: 'error' }));
        } finally {
            setLoading(false)
        }
    };

const converter = (size,usedSize) =>{
    if(usedSize < 1024){
        const totalSize = `${(size * 1024).toFixed(2)} MB`
        const sizeUsed = `${(usedSize * 1).toFixed(2) } MB`
        setSize(totalSize)
        setUsedSize(sizeUsed)

    } else if (usedSize > 1024){
        const sizeUsed = `${(usedSize / 1024).toFixed(2) } GB`
     
        setUsedSize(sizeUsed)

    }

}

    useEffect(()=>{
        fetchSize()
       
    },[])

    useEffect(() => {
        fetchSubbucketFiles();
    }, [ page, rowsPerPage, isBucketAdded, sortBy, sortField, alignment,searchName]);


    return (

        <>
            <div >
                <Box style={{ display: "flex", direction: 'row', justifyContent: 'space-between' }}>
                    {/* <h2>{mainBucketName} / {bucketName}  ({bucketSize} GB / {bucketUsedSize})</h2> */}

                    <Box sx={{ display: 'flex', flexDirection: 'row', }}>
                        <DynamicBreadcrumb breadcrumbs={breadcrumbs} style={{ marginTop: 2 }} />

                     
                        <Link to={{
                            pathname: '/filelist',
                            state: { mainBucketId: mainBucketId } // Pass mainBucketId as state
                        }}  style={{ textDecoration: 'none', color: 'inherit' }}>
                            <TitleHeading title={`${mainBucketName} / `} />
                        </Link>
                        {/* <Link to="/filelist" style={{ textDecoration: 'none', color: 'inherit' }}> */}
                            <TitleHeading title={` ${bucketName} / `} />
                        {/* </Link> */}
                        <TitleHeading title={` (${usedSize} / ${size})`} />
                        {/* <TitleHeading title={` (${(usedSize).toFixed(2)} / ${(size).toFixed(2)})`} /> */}


                    </Box>


                    <Box style={{ display: "flex", direction: 'row' }}>
                        <SearchComponent
                            searchName={searchName}
                            setSearchName={setSearchName}
                            placeholder="Search Files..."
                        />



                        {/* <FormControl sx={{ float: "right", m: 2, mr: 1 }} >
                            <TextField

                                id="outlined-basic"
                                label="Search..."
                                placeholder='File Name'
                                variant="outlined"
                                type="search"
                                value={searchName}
                                // onChange={ (e)handleCompanyNameChange}

                                onChange={(e) => {


                                    setSearchName(e.target.value);
                                }}

                                InputProps={{
                                    sx: {
                                        // fontSize: "12px",
                                        height: "30px"
                                    },
                                }}

                                InputLabelProps={{ shrink: true }}





                                // onChange={(e)=>companyChange(e)}
                                onKeyPress={handleSearch}

                            />
                        </FormControl> */}
                        <Button variant='contained' style={{ height: "30px", marginTop: "15px", marginBottom: "" }} onClick={() => setShowUpload(!showUpload)}>+ Upload Files</Button>


                    </Box>

                </Box>
                <Dialog open={showUpload} onClose={() => setShowUpload(false)} fullWidth>
                    <DialogTitle>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Typography variant="h6">Upload Files</Typography>
                            <IconButton onClick={() => setShowUpload(false)} sx={{color:"white"}}>
                                <Clear />
                            </IconButton>
                        </Box>
                    </DialogTitle>
                
                    <DialogContent >
                        <DragAndDropUpload onDrop={handleFileDrop} 
                        setShowUpload={setShowUpload}bucketMaxFileSize={bucketMaxFileSize} />
                    </DialogContent>
                    {/* <DialogActions>
                        <Button onClick={() => setShowUpload(false)} color="primary">
                            Close
                        </Button>
                    </DialogActions> */}
                </Dialog>

         
                {/* {(bucketDataMapped?.length > 0 && fileDataMapped.length === 0) && <MainTable tableHeader={tableHeaderBucket} rows={rowsBucket} page={page}
                    rowsPerPage={rowsPerPage}
                    totalRecords={data?.data?.someData?.data?.totalLength}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    blankRowSize="0"
                    handleAscSort={handleAscSort}
                    handleDescSort={handleDescSort}
                />} */}

                {/* {(fileDataMapped?.length > 0 && bucketDataMapped?.length === 0) && <MainTable tableHeader={tableHeader} rows={rows} page={page}
                    rowsPerPage={rowsPerPage}
                    totalRecords={data?.data?.someData?.data?.totalLength}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    blankRowSize="0"
                    handleAscSort={handleAscSort}
                    handleDescSort={handleDescSort}
                />} */}






                <MainTable tableHeader={tableHeader} rows={rows} page={page}
                    rowsPerPage={rowsPerPage}
                    // totalRecords={data?.data?.someData?.data?.totalLength}
                    totalRecords={totalLength}

                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    blankRowSize="0"
                    handleAscSort={handleAscSort}
                    handleDescSort={handleDescSort}
                />

                {/* Delete Confirmation dialog */}
                <ConfirmationModal
                
                    open={confirmationOpen}
                    handleClose={handleCloseConfirmation}
                    handleConfirm={handleConfirmDelete}
                    text="Are you sure you want to delete this file?"
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                />
                <Dialog maxWidth="lg"
                    open={dialogOpen} onClose={() => setDialogOpen(false)}>
                    {/* <DialogTitle>Video Player</DialogTitle> */}
                    <DialogContent >

                        <VideoPlayerComponent sx={{ width: '100%', height: 'auto' }} videoUrl={videoUrl} videoId={videoId} />
                    </DialogContent>
                    {/* <DialogActions>
                        <Button onClick={() => setDialogOpen(false)}>Close</Button>
                    </DialogActions> */}
                </Dialog>

                <AddBucketModal
                    selectedBucketSize={selectedBucketSize}
                    selectedBucketName={selectedBucketName}
                    selectedBucketAutodelete={selectedBucketAutodelete}
                    selectedBucketMaxUploadSizeFile={selectedBucketMaxUploadSizeFile}
                    values={values}
                    handleChangeAdd={handleChangeAdd}
                    open={confirmationAddOpen}
                    handleClose={handleCloseAddConfirmation}
                    handleConfirm={handleConfirmAdd}
                    editMode={editMode}
                />

            </div>
            {loading && <LoadingComponent />}

        </>

    )
}