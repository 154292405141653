import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackBarData } from '../../redux/SnackBarSlice';

export default function SnackBarAlert() {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const data = useSelector((state)=>state.snackBarSlice.value)

  console.log(data,"snackbar data");


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(setSnackBarData({open:false}))
  };

  return (
    <div>
     
      <Snackbar open={data?.open} autoHideDuration={2000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity= {data?.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {data?.msg}
        </Alert>
      </Snackbar>
    </div>
  );
}
