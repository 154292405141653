import React  from 'react'
import PersistentDrawerLeft from './Appbar'
import MiniDrawer from './Appbar2'
import { useSelector } from 'react-redux'
import ErrorBoundary from '../utils/ErrorBoundary'

const MainLayout = ({children}) => {
  const authData = useSelector((state)=>state.authSlice.value)
  // debugger
  return (
    <div>
       { authData?.token ? <MiniDrawer>
        <ErrorBoundary>
          {children}
          </ErrorBoundary>
          </MiniDrawer>:<ErrorBoundary>{children}</ErrorBoundary>}
    </div>
  )
}

export default MainLayout