// components/SomeComponent.js

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createApiThunk } from '../redux/GenericThunk';
import { fetchSomeDataRequest, fetchSomeDataSuccess, fetchSomeDataError, setApiData } from '../redux/ApiDataSlice';
import { Button, Grid } from '@mui/material';
import { get, simpleGet } from '../services/AxiosGeneric';
import { Testing } from '../constant/apiEndpoints/login';
import LoadingComponent from '../common_components/popups/LoadingComponent';
import { FixedSizeList as List } from 'react-window';



 const MainComponent = () => {
  
const [item,setItem] = useState(Array.from(new Array(10))?.map((item,index)=>{
  return{name:""}})); 

function debounce(func,delay){
  
  let timer;
  return function(){
    let main = this
    let args = arguments
    clearTimeout(timer)

  timer = setTimeout(()=>{
    func.apply(main,args)

  },delay)

  }
}


function* debounce1(func,delay){
  
  let timer;
 while(true){
  let value = yield ;
    clearTimeout(timer)

  timer = setTimeout(()=>{
    func(value)

  },delay)

  }
}





//  printTitle();

   // Example usage:
   const handleInput =(value)=> {
    // debugger
    // Your time-consuming task goes here
    console.log(value,'Input event handled');
  }
  

  // const debouncedFunction = searchVehicle(handleInput, 1000);

  let m = 10;

  
  
 
  const debouncedHandleInput = debounce(handleInput, 1000); 
  let debounceGenerator = debounce1(handleInput,1000)

  //  debugger
  const dispatch = useDispatch();
  const data = useSelector((state) => state.apiSlice);


 

  const onChangeHandle1 = (index,value)=>{
    //       debugger
          let temp = [...item]
          temp[index] = value
          setItem(temp);
      
        }
   




// console.log(Object.is(5,5));

  useEffect(() => {
    // debugger
    dispatch(
      createApiThunk(
        simpleGet(Testing),
        fetchSomeDataRequest.type,
        fetchSomeDataSuccess.type,
        fetchSomeDataError.type,
        "someData"
      )
    );

    dispatch(
        createApiThunk(
          simpleGet(Testing),
          fetchSomeDataRequest.type,
          fetchSomeDataSuccess.type,
          fetchSomeDataError.type,
          "otherData"
        )
      );
        

  }, [dispatch]);



  // useEffect(()=>{
  //   let items = )
  //     setItem(items)
  // },[])


//  const TableComponent = ()=>{

//   const nameComponent = (row,index)=>{
//    
  
//   return  <GenericTextfield key={index+"name"} value={row?.name} onChangeHandle={(value)=>onChangeHandle1(index,value,"name")} />}

//   const rows = useMemo(() =>
//       item?.map((val, index) => {
//         return { name: nameComponent(val, index) };
//       }),
//     [item]
//   );

//   return <MainTable tableHeader={[{title:'Main',key:'name',isComponent:true}]} rows={rows} blankRowSize={10} />
//  }


 

  return (
    <div >
      <h1>RapidS3</h1>

      
      {/* {data.loading && <LoadingComponent/>}
      {data.error && <p>Error: {data.error}</p>}
      {data.data && <p>Data: {JSON.stringify(data.data.someData)}</p>}
      {data?.data?.otherData && <p>Data: {JSON.stringify(data.data.otherData)}</p>}
      <Grid container><Grid item xs={3}><GenericAutocomplete/></Grid>
      <Grid item xs={3}> 
      <GenericTextfield */}
      {/* // onChangeHandle={(value)=>debounceGenerator.next(value)} */}
      {/* /></Grid> */}
      {/* </Grid> */}
     {/* <GenericPrint> */}
      {/* <GenericTable data ={item} onUpdate={onChangeHandle1}/> */}
      {/* <TableComponent/> */}
      {/* <SearchComponent/> */}
      {/* <Button variant='contained'  onClick={()=>increaseCounter()}> Add</Button> */}
      {/* <Button variant='contained'  onClick={()=>dispatch(setSnackBarData({open:true}))}> Alert Message</Button>
      <Button variant='contained'  onClick={()=>dispatch(setHistoryData({open:true}))}>Open History</Button>
      <Button variant='contained' onClick={()=>dispatch(setApiData({...data.data.otherData,id:2,keyId:"otherData"}))}>Change Id</Button>
      </GenericPrint> */}
    </div>
  );
};

export default MainComponent





const GenericTable = ({ data, onUpdate }) => {
  const cellsRef = useRef([]);

  const Row = ({ index, style }) => {
    const rowData = data[index];

    const handleChange = (e) => {
      const { name, value } = e.target;
      onUpdate(index, { ...rowData, [name]: value });
    };

    const handleFocus = (columnIndex) => {
      if (cellsRef.current[index] && cellsRef.current[index][columnIndex]) {
        cellsRef.current[index][columnIndex].focus();
      }
    };

    // Memoize the cell component to optimize rendering
    const Cell = useMemo(() => {
      return ({ columnIndex }) => {
        const columnName = Object.keys(rowData)[columnIndex];
        return (
          <input
            ref={(el) => {
              if (!cellsRef.current[index]) {
                cellsRef.current[index] = [];
              }
              cellsRef.current[index][columnIndex] = el;
            }}
            type="text"
            name={columnName}
            value={rowData[columnName]}
            onChange={handleChange}
            onFocus={() => handleFocus(columnIndex)}
          />
        );
      };
    }, [rowData, index]);

    return (
      <div style={style}>
        {Object.keys(rowData).map((_, columnIndex) => (
          <Cell key={columnIndex} columnIndex={columnIndex} />
        ))}
      </div>
    );
  };

  return (
    <List
      height={400}
      itemCount={data.length}
      itemSize={40}
      width={600} // Adjust width according to your requirement
    >
      {Row}
    </List>
  );
};


 