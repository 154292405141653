module.exports = {

    // DEV Server HTTP
    // baseUrl: "http://192.168.30.44:81/api/",
    // loginUrl: "http://192.168.30.44:81/",


    baseUrl: "http://192.168.30.35:8881/",
    // baseUrl2: "http://localhost:8881/",

    // PROD Server HTTP
    // baseUrl: "http://services.nyggs.com:81/api/",
    // loginUrl: "http://services.nyggs.com:81/",

    // // PROD Server HTTPS
    // baseUrl: "https://services.nyggs.com:82/api/",
    // loginUrl: "https://services.nyggs.com:82/",
};