import * as React from "react";
import { useState, useEffect } from 'react';
import { makeStyles, styled, withStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
// import { makeStyles } from "@mui/styles";
import { TablePagination, TableRow, Tooltip, Typography } from "@mui/material";

import { Box } from "@mui/system";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
// import { FixedSizeList } from 'react-window';





export const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    tableRow: {
        height: 10,
    },
    tableCell: {
        padding: "0px 16px",
    },
});


export default function MainTableDragDrop({
    tableHeader,
    rows,
    page,
    rowsPerPage,
    totalRecords,
    handleChangePage,
    handleChangeRowsPerPage,
    //5,10,15
    rowsPerPageOptions = [10, 15, 25, 50],
    // blankRowSize = 15,
    blankRowSize = 0,
    handleAscSort,
    handleDescSort


}) {

    console.log(totalRecords, "totalRecords")

    const classes = useStyles();
    // console.log(tableHeader, "table Header");








    return (
        <div style={{ width: "100%", height: "", borderRadius: "15px", pb: 10 }}>
            {" "}
            {/* <Paper elevation={3}> */}
            <TableContainer
                sx={{
                    borderRadius: "15px",
                    height: "25vh",
                    // maxHeight:"100vh",
                    // maxWidth:"100vw"

                    // maxHeight: `${15 * 40}px`,

                    // overflow: "hidden",
                }}
                component={Paper}
            >
                <Table sx={{ minWidth:200 }} aria-label="customized table">
                    <TableHead sx={{ height: "40px", zIndex: 99 }}>
                        <TableRow >
                            <TableCell style={{}}>#</TableCell>
                            {/* {tableHeader?.map((val) => {
                  return <TableCell>{val?.title}</TableCell>;
                })} */}
                            {tableHeader?.map((val) => {
                                return <TableCell><Box style={{ display: "flex", justifyContent: "space-between" }}><Box sx={{ marginTop: "7px" }}><Typography >{val?.title}</Typography></Box>{val?.key === "column3" || val?.key === "column4" ?
                                    <Box>
                                        <ArrowUpwardIcon style={{ cursor: "pointer", height: "20px", marginTop: 9 }} onClick={() => handleAscSort(val?.key)} /><ArrowDownwardIcon style={{ cursor: "pointer", height: "20px" }} onClick={() => handleDescSort(val?.key)} />
                                    </Box> : ""}</Box></TableCell>;
                            })}

                        </TableRow>
                    </TableHead>
                    <TableBody sx={{ minHeight: `${15 * 40}px` }} >


                        {rows?.map((row, index) => (


                            <TableRow key={row?.id}>
                                <TableCell >{rowsPerPage * (page - 1) + index + 1}</TableCell>
                                {tableHeader?.map((val, ind) => {
                                    {console.log(val,"jjjjjjjj")}
                                    return val?.key === "#" ? (
                                        <TableCell key={val?.key}
                                            style={{
                                                width: val?.key === "Name" ? '30px' : 'auto',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap'
                                            }}>
                                            {rowsPerPage * (page - 1) + index + 1}
                                        </TableCell>
                                    ) : (
                                        <TableCell  >
                                            {!val?.isComponent ? <Tooltip
                                                title={
                                                    row?.[val?.key]?.length > 70 ? row?.[val?.key] : ""
                                                }
                                            >
                                                <Box >
                                                    {row?.[val?.key]?.length > 70
                                                        ? (row?.[val?.key]).slice(0, 70) + `...`
                                                        : row?.[val?.key]}
                                                </Box>
                                            </Tooltip> : <Box> {row?.[val?.key]} </Box>}
                                        </TableCell>
                                    )
                                })}
                                {/* {tableHeader?.map((val, ind) => (
                    <TableCell
                      key={ind}
                      onDoubleClick={val?.key === 'column1' ? () => handleNavigation(row?.id, row?.column1) : null}
                      

                      style={{ cursor: val?.key === 'column1' ? 'pointer' : 'default' }}
                    >
                      {val?.key === "#"
                        ? rowsPerPage * (page - 1) + index + 1
                        : !val?.isComponent ? (
                          <Tooltip
                            title={
                              row?.[val?.key]?.length > 70
                                ? row?.[val?.key]
                                : ""
                            }
                          >
                            <Box>
                              {row?.[val?.key]?.length > 70
                                ? row?.[val?.key].slice(0, 70) + "..."
                                : row?.[val?.key]}
                            </Box>
                          </Tooltip>
                        ) : (
                          <Box>{row?.[val?.key]}</Box>
                        )}
                    </TableCell>
                  ))}
                  */}
                            </TableRow>

                        ))}
                        {rows?.length < blankRowSize &&
                            rows?.length !== 0 &&
                            Array.from(new Array(blankRowSize - rows?.length))?.map(() => (
                                <TableRow>
                                    <TableCell colSpan={tableHeader?.length}></TableCell>
                                </TableRow>
                            ))}
                        {rows?.length === 0 && (
                            <TableRow>
                                <TableCell
                                    colSpan={tableHeader?.length + 1

                                    }
                                    sx={{ textAlign: "center" }}
                                >
                                    No Data Found
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {totalRecords > blankRowSize && (
                <TablePagination
                    sx={{
                        borderTop: "1px solid #d1cfcf",
                        borderBottom: "1px solid #d1cfcf",
                    }}
                    rowsPerPageOptions={rowsPerPageOptions}
                    component="div"
                    count={totalRecords}
                    rowsPerPage={rowsPerPage}
                    page={page - 1}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            )}
            {/* </Paper> */}
        </div>
    );
}




