import { Backdrop } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux';
import Logo from '../popups/loader.gif'


const LoadingComponent = () => {

    const data = useSelector((state) => state?.loadingSlice?.value)
    // const isLoading = useSelector((state) => state.loadingSlice.loading); 
    console.log(data, "isLoading")

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }}
            open={true}
        >
            <section className={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
            }}>
                {/* <div><CircularProgress color="secondary" /></div>
                <div>Loading...</div> */}
                <div id="page">
                    <div id="container">
                        {/* <div id="ring"></div>
            <div id="ring"></div>
            <div id="ring"></div>
            <div id="ring"></div>
            <div id="h3">loading</div> */}
                        <img src={Logo} alt="Loading" style={{ width: '100px', height: '100px' }} />
                    </div>
                </div>
            </section>
        </Backdrop>

    )

}

export default LoadingComponent