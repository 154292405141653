import React, { useEffect, useState } from 'react';

function ErrorBoundary({ children }) {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const errorHandler = (error, errorInfo) => {
        // debugger
      setHasError(true);
      // Log the error to an error reporting service
      console.error('Error Boundary caught an error:', error, errorInfo);
      return false
    };

    const unhandledRejectionHandler = (event) => {
        // debugger
      setHasError(true);
      // Log the unhandled promise rejection
      console.error('Unhandled Promise Rejection:', event.reason);
      event.preventDefault();
    };

    window.addEventListener('error', errorHandler);
    window.addEventListener('unhandledrejection', unhandledRejectionHandler);

    return () => {
      window.removeEventListener('error', errorHandler);
      window.removeEventListener('unhandledrejection', unhandledRejectionHandler);
    };
  }, []);

  useEffect(()=>{
setHasError(false);

  },[children])

  return hasError ? <h1 style={{padding:'20px'}}>Something went wrong!</h1> : children;
}

export default ErrorBoundary;