// reducers/someDataReducer.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: null,
  loading: false,
  error: null,
};

const apiDataSlice = createSlice({
  name: 'apiData',
  initialState,
  reducers: {
    fetchSomeDataRequest: (state) => {
      state.loading = true;
    },
    fetchSomeDataSuccess: (state, action) => {
      state.loading = false;
      state.data = {...state.data,...action.payload};
      state.error = null;
    },
    fetchSomeDataError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    setApiData:(state,action)=>{
        state.data = {...state.data,[action.payload.keyId]:action.payload}
    }
  },
});

export const { fetchSomeDataRequest, fetchSomeDataSuccess, fetchSomeDataError,setApiData} = apiDataSlice.actions;

export default apiDataSlice.reducer;
