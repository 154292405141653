

import { createSlice } from '@reduxjs/toolkit';

export const snackBarSlice = createSlice({
  name: 'snackBarSlice',
  initialState: {
    value: {open:false,msg:"Success",severity:"success"},
  },
  reducers: {
    setSnackBarData: (state,data) => {
        
      state.value = {...state.value,...data.payload}
    },
   
  },
});

export const { setSnackBarData } = snackBarSlice.actions;


export default snackBarSlice.reducer;
