// src/features/counterSlice.js

import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'authSlice',
  initialState: {
    value: {companyIddb:1,token:"hello"},
  },
  reducers: {
    setAuthData: (state,passData) => {


        // debugger
      state.value = {...state.value,...passData.payload}
    },
   
  },
});

export const { setAuthData } = authSlice.actions;

export const selectAuth = (state) => state.authSlice.value;



export default authSlice.reducer;



// authSlice.js

// import { createSlice } from '@reduxjs/toolkit';

// export const authSlice = createSlice({
//   name: 'auth',
//   initialState: {
//     isAuthenticated: false,
//     token: null,
//     userId: null,
//     userCompanyId: null,
//     username: null,
//   },
//   reducers: {
//     setAuthData: (state, action) => {
   
//       state.isAuthenticated = true;
//       state.token = action.payload.token;
//       state.userId = action.payload.userId;
//       state.userCompanyId = action.payload.companyId;
//       state.username = action.payload.firstName;
//       console.log(state.token, "action")
//     },
  

//     clearAuthData: (state) => {
//       state.isAuthenticated = false;
//       state.token = null;
//       state.userId = null;
//       state.userCompanyId = null;
//       state.username = null;
//     },
//   },
// });

// export const { setAuthData, clearAuthData } = authSlice.actions;

// export const selectAuth = (state) => state.auth; // Selector to access auth state

// export default authSlice.reducer;

