import { Folder, Info, Visibility } from '@mui/icons-material'

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, TextField, Tooltip, Typography } from '@mui/material'
import React, { useState, useEffect, useRef } from 'react'
import MainTable from '../../common_components/tables/MainTable'
import { SearchComponent } from '../../common_components/input_fields/SearchComponent'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { DeleteRecyclebinFile, FileDownload, FileView, FilesByBucketId, RecyclebinFiles, RecyclebinFilesDownload, RecyclebinFilesView, RecyclebinVideoStream, RestoreFile, VideoStream } from '../../constant/apiEndpoints/buckets'
import { fetchSomeDataError, fetchSomeDataRequest, fetchSomeDataSuccess } from '../../redux/ApiDataSlice'
import { createApiThunk } from '../../redux/GenericThunk'
import { get2, post2 } from '../../services/AxiosGeneric'
import moment from 'moment'
import DownloadIcon from '@mui/icons-material/Download';
import InfoIcon from '@mui/icons-material/Info';
import config from '../Permission/config'
import axios from 'axios'
import DragAndDropUpload from './DragAndDropUpload'
import RestorePageIcon from '@mui/icons-material/RestorePage';
import ConfirmationModal from './ConfirmationModal'
import LoadingComponent from '../../common_components/popups/LoadingComponent'
import { setSnackBarData } from '../../redux/SnackBarSlice'
import TitleHeading from '../../common_components/heading/TitleHeading'
import DynamicBreadcrumb from '../../common_components/breadcrumbs/DynamicBreadcrumb'
import VideoPlayerComponent from './VideoPlayer'


export const RecyclebinListing = () => {
    const [searchName, setSearchName] = useState('');
    const [showUpload, setShowUpload] = useState(false);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectedFile,setSelectedFile] = useState([]);
    const [isFileSelected,setIsFileSelected] = useState(false);
    const [ isBucketAdded,setIsBucketAdded] = useState(false);

    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [confirmationAddOpen, setConfirmationAddOpen] = useState(false);
    const [confirmationDeleteOpen, setConfirmationDeleteOpen] = useState(false);

    const [loading,setLoading] = useState(false);
    //sorting
    const [sortBy, setSortBy] = useState();
    const [sortField, setSortField] = useState();
    // const [isBucket,setIsBucket] = useState(false);

    const [inputValue, setInputValue] = useState("");

    const [inputValueRestore, setInputValueRestore] = useState("");
    const [videoUrl, setVideoUrl] = useState('');
    const [videoId, setVideoId] = useState()
    const [dialogOpen, setDialogOpen] = useState(false);
    const dispatch = useDispatch();
    const data = useSelector((state) => state.apiSlice);
    const datas = data?.data?.someData?.data?.data ? data?.data?.someData?.data?.data : [];
    const location = useLocation();
    const bucketId = location.state?.bucketId;
    const bucketName = location.state?.bucketName;
    const bucketPage = location.state?.bucketPage;
    const subbucketPage = location.state?.subbucketPage
   

    const isEmptyData = datas.length === 0;

    const isBucket = data?.data?.someData?.data.isBucket;
  
    // const breadcrumbs = [
    //     { name: '', path: '/recyclebin' },
    //     { name: '', path: '' },

    // ];



    const breadcrumbs = [
        { name: '', path: bucketPage ? '/bucketlist' : '/recyclebin' },


        { name: '', path: subbucketPage ? '/filelist' : '/recyclebin' },

    ];





    // const breadcrumbs = [
    //     {
    //         name: '',
    //         path: bucketPage ? '/bucketlist' : '/recyclebin',
    //     },
  
    // ];









    const handleChangePage = (event, newPage) => {
        setPage(newPage + 1);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);

    };


    //sorting
    const handleAscSort = (title) => {
      

        if (title === 'column3') {
            setSortField("Size")

        }


        if (title === 'column4') {
            setSortField("createdOn")

        }

        setSortBy(0)


    }



    const handleDescSort = (key) => {
        if (key === 'column3') {
            setSortField("Size")

        }


        if (key === 'column4') {
            setSortField("createdOn")

        }
        setSortBy(1)


    }










    const handleRestoreClick = () => {
        setConfirmationOpen(true);
    };

    const handleConfirmRestore = () => {
        fileRestore();
        setConfirmationOpen(false);
    };

    const handleCloseConfirmation = () => {
        setConfirmationOpen(false);
    };

    const fileRestore = async() => {
        setLoading(true)
        try {
            const payload = {
                "bucketId": bucketId,
                "idlist": selectedFile,
                "confirm": inputValueRestore

            }
            // dispatch(
            //     createApiThunk(
            //         () => post2(RestoreFile, payload),
            //         fetchSomeDataRequest.type,
            //         fetchSomeDataSuccess.type,
            //         fetchSomeDataError.type,
            //         "someData"
            //     )
            // ).then(() => setIsBucketAdded(prev => !prev));

            const response = await post2(RestoreFile, payload);
            if (response?.status === 200) {
                dispatch(setSnackBarData({ open: true, msg: response?.data?.message, severity: 'success' }));
                setIsBucketAdded(prev => !prev);
                setIsFileSelected(false)


            }
            else {
                dispatch(setSnackBarData({ open: true, msg: response?.data?.message, severity: 'error' }));

            }
        } catch (error) {
            console.log(error);
        }finally{
            setLoading(false)
        }

    }


    const handleDeleteClick = () => {
        setConfirmationDeleteOpen(true);
    };

    const handleConfirmDelete = () => {
        filesDelete();
        setConfirmationDeleteOpen(false);
    };

    const handleCloseConfirmationDelete = () => {
        setConfirmationDeleteOpen(false);
    };

    const filesDelete = async() => {

        // if (!inputValue) {
        //     dispatch(setSnackBarData({ open: true, msg: "Please type Confirm first", severity: 'error' }));

        //     return
        // }
    
        try {
            setLoading(true)
            const payload = {
                "bucketId": bucketId,
                "idlist": selectedFile,
                // "confirm":inputValue
             
            }


            // dispatch(
            //     createApiThunk(
            //         () => post2(DeleteRecyclebinFile, payload),
            //         fetchSomeDataRequest.type,
            //         fetchSomeDataSuccess.type,
            //         fetchSomeDataError.type,
            //         "someData"
            //     )
            // )
            const response = await post2(DeleteRecyclebinFile, payload);
            if (response?.status === 200) {
                dispatch(setSnackBarData({ open: true, msg: response?.data?.message, severity: 'success' }));
                setIsBucketAdded(prev => !prev);
                setInputValue("")
                setIsFileSelected(false)

            }
            else {
                dispatch(setSnackBarData({ open: true, msg: response?.data?.message, severity: 'error' }));

            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false); // Set loading state to false after API call completes
        }


    }


 

    const handleOpenInNewTabClick = async (id) => {
        // debugger

        try {
            const payload = {
                id: id
            };

            // const response = await axios.post(config.baseUrl2 + FileView, payload);
            const response = await post2(RecyclebinFilesView, payload);


            const type = response?.data?.data
      
            const dataAS = response?.data
            const files = response?.data?.data;
            const base64String = files?.encodedBase64;
            const fileName = files?.name;
            const fileType = fileName?.split('.').pop().toLowerCase();


            // if (!base64String) {

            //     window.open(config.baseUrl2 + VideoStream + `?key=${id}` );
            //     return;
            // }
            if (type === "VIDEO") {
                const videoStreamUrl = `${config.baseUrl}${RecyclebinVideoStream}?key=${id}`;
            
                setVideoUrl(videoStreamUrl);
                setDialogOpen(true);
                return;
            }

            // if (base64String){

            // }

            const byteCharacters = atob(base64String);
            const byteNumbers = new Array(byteCharacters.length);

            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }


            const byteArray = new Uint8Array(byteNumbers);


            let mimeType;
            switch (fileType) {
                case 'pdf':
                    mimeType = 'application/pdf';
                    break;
                // case 'excel':
                case 'xlsx':
                    mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                    break;
                case 'png':
                    mimeType = 'image/png';
                    break;
                case 'mp4':
                    mimeType = 'video/mp4';
                    break;
                case 'jpeg':
                    mimeType = 'image/jpeg';
                    break;
                case 'gif':
                    mimeType = 'image/gif';
                    break;
                case 'bmp':
                    mimeType = 'image/bmp';
                    break;
                case 'webp':
                    mimeType = 'image/webp';
                    break;
                case 'svg':
                    mimeType = 'image/svg+xml';
                    break;
                case 'ico':
                    mimeType = 'image/vnd.microsoft.icon';
                    break;


                case 'json':
                    mimeType = 'application/json';
                    break;
                case 'sql':
                    mimeType = 'application/sql';
                    break;
                case 'html':
                    mimeType = 'application/html';
                    break;
                case 'zip':
                    mimeType = 'application/zip';
                    break;
                case 'mp3':
                    mimeType = 'audio/mpeg';
                    break;
                case 'wav':
                    mimeType = 'audio/wav';
                    break;
                case 'ogg':
                    mimeType = 'audio/ogg';
                    break;
                case 'm4a':
                    mimeType = 'audio/mp4';
                    break;

                //VIDEO
                case 'mp4':
                    mimeType = 'video/mp4';
                    break;
                case 'webm':
                    mimeType = 'video/webm';
                    break;
                case 'ogg':
                    mimeType = 'video/ogg';
                    break;
                case 'm4v':
                    mimeType = 'video/mp4';
                    break;
                case 'mov':
                    mimeType = 'video/quicktime';
                    break;

                case 'zip':
                    mimeType = 'application/zip';
                    break;
                default:
                    mimeType = 'application/octet-stream';
                    break;
            }



            const extensions = [
                // Document Formats
                "pdf",

                // Image Formats
                "jpeg",
                "jpg",
                "png",
                "gif",
                "bmp",
                "webp",
                "svg",
                "ico",

                // Audio Formats
                "mp3",
                "wav",
                "ogg",
                "m4a",


            ];


            const blob = new Blob([byteArray], { type: mimeType });
            if (extensions.includes(fileType)) {

                const blobUrl = URL.createObjectURL(blob);
                window.open(blobUrl, '_blank');

            }

            else {
                const url = URL.createObjectURL(blob);
                const anchorElement = document.createElement('a');
                anchorElement.href = url;
                anchorElement.download = fileName;
                anchorElement.click();

            }


        } catch (error) {
            console.log(error);
        }
    };



    const handleDownloadClick = (id) => {
        const apiUrl = ` ${config.baseUrl}${RecyclebinFilesDownload}/${id}`;

        const link = document.createElement('a');
        link.href = apiUrl;
        // link.target = '_blank';
        link.download = '';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    };

    // const [loadingStates, setLoadingStates] = useState({});

    // const handleDownloadClick = (id) => {
    //     if (loadingStates[id]) return;
    //     setLoadingStates(prev => ({ ...prev, [id]: true }));

    //     const apiUrl = `${config.baseUrl2}${FileDownload}/${id}`;
    //     const link = document.createElement('a');
    //     link.href = apiUrl;
    //     link.download = '';
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);


    //     setTimeout(() => {
    //         setLoadingStates(prev => ({ ...prev, [id]: false }));
    //     }, 5000);
    // };





    


    //old
    // const handleSelectAllClick = () => {
    //     const pageFileIds = rows.map(row => row.id);
    //     setSelectedFile(prevSelected => {
    //         if (prevSelected.length === pageFileIds.length) {
    //             setIsFileSelected(false);
    //             return [];
    //         } else {
    //             setIsFileSelected(true);
    //             return pageFileIds;
    //         }
    //     });
    // };

    const handleSelectAllClick = () => {
        const pageFileIds = rows.map(row => row.id);

        // If there are selected files, deselect all
        if (selectedFile.length > 0) {
            setSelectedFile([]);
            setIsFileSelected(false);
        } else {
            // Otherwise, select all
            setSelectedFile(pageFileIds);
            setIsFileSelected(true);
        }
    };



    const handleCheckboxChange = (id) => {
        setSelectedFile(prevSelected => {
            const isSelected = prevSelected.some(item => item === id);
     

            if (isSelected) {
                const newSelected = prevSelected.filter(item => item !== id);
                setIsFileSelected(newSelected.length > 0);
                return newSelected;
            } else {
                setIsFileSelected(true);
                return [...prevSelected, id];
            }
        });
    };


    const tableHeader = [
        { title: 'Name', key: 'column1' },
        { title: 'Size', key: 'column3' },

     
        // { title: 'Used Size', key: 'column3' },
      
        { title: 'Deleted On (dd/mm/yyyy)', key: 'column4' },
        { title: 'Time (hh:mm:ss)', key: 'time' },
        { title: 'Deleted By', key: 'column6' },
        { title: 'Days left for permanent delete ', key: 'column7' },
        { title: '', key: 'column5' },
        { title: 'Action', key: 'action' },
   

    ];

    if (isBucket === true) {

        tableHeader.splice(2, 0, { title: 'Subbucket', key: 'column8' });
    }


    const rows = (Array.isArray(datas) ? datas : []).map((file) => {
        // Extract file extension from file name
        const fileName = file?.name || '';
        const fileType = fileName.split('.').pop().toLowerCase();

        // List of supported file extensions
        const supportedFileExtensions = [
            // Document Formats
            "pdf",

            // Image Formats
            "jpeg",
            "jpg",
            "png",
            "gif",
            "bmp",
            "webp",
            "svg",
            "ico",

            // Audio Formats
            "mp3",
            "wav",
            "ogg",
            "m4a",

            // Video Formats
            "mp4", "mkv", "mov", "wmv", "avi", "avchd", "wemb", "flv", "f4v", "swf"
        ];
        // Check if file type is valid
        const isValidFileType = supportedFileExtensions.includes(fileType);


        return {
            id: file?.id,
            column1: (
                <Box style={{ cursor: "pointer" }}>
                    <Typography style={{ fontWeight: "normal", color: "inherit" }}>
                        {file?.name || ''}
                    </Typography>
                </Box>
            ),
            time: (
                <Typography align="right">
                    {moment(file?.createdOn).format('h:mm:ss A') || ''}
                </Typography>
            ),
            column3: (
                <Typography align="right">
                    {`${file?.size || ''}`}
                </Typography>
            ),
            column6: (
                <Typography>
                    {`${file?.createdByName || ''}`}
                </Typography>
            ),
            column4: (
                <Typography align="right">
                    {moment(file?.createdOn).format('DD-MM-YYYY') || ''}
                </Typography>
            ),
            column7: (
                <Typography align="right">
                    {`${file?.daysUntilDeletion}`}
                </Typography>
            ),
            column5: (
                <Box style={{ cursor: "pointer" }}>
                    <input
                        type="checkbox"
                        onChange={() => handleCheckboxChange(file?.id)}
                        checked={selectedFile.includes(file?.id)}
                    />
                </Box>
            ),
            column8: (
                <Typography>
                    {`${file?.bucketName || ''}`}
                </Typography>
            ),
            action: (
                <Box style={{ cursor: "pointer" }}>
                    <Tooltip title="View">
                        <IconButton disabled={!isValidFileType}>
                            <Visibility
                              
                                style={{ cursor: "pointer" }}
                                onClick={() => handleOpenInNewTabClick(file?.id)}
                            />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Download">
                        <IconButton>
                            <DownloadIcon
                            
                                style={{ cursor: "pointer" }}
                                onClick={() => handleDownloadClick(file?.id)}
                            />
                        </IconButton>
                        {/* <IconButton onClick={() => handleDownloadClick(file?.id)} disabled={loadingStates[file?.id] || false}>
                            <DownloadIcon />
                        </IconButton> */}
                    </Tooltip>
                </Box>
            )
        };
    });


    // const filteredRows = rows.filter(row =>
    //     row.column1.toLowerCase().includes(searchName.toLowerCase())
    // );

    const hasMounted = useRef(false);
  

    
    const handleSearch = (event) => {
        if (event.key === "Enter") {
            fetchBuckets(searchName);
        }
    };



    const fetchBuckets = () => {
        setLoading(true)
        // debugger

        // if (!hasMounted.current) {
        const payload = {

            "bucketId": bucketId,
            "pageNo": page - 1,
            "pageSize": rowsPerPage,
            "searchField": searchName,
            "sortBy":sortBy,
            "sortField":sortField
        };

        dispatch(
            createApiThunk(
                () => post2(RecyclebinFiles, payload),
                fetchSomeDataRequest.type,
                fetchSomeDataSuccess.type,
                fetchSomeDataError.type,
                "someData"
            )
        ).finally(() => {
            setLoading(false);
        });
        // hasMounted.current = true;
        // }
        // else{
        //     hasMounted.current = false;
        // }

    };

    const handleFileDrop = async (acceptedFiles) => {
        const formData = new FormData();
        acceptedFiles.forEach(file => {
            formData.append('files', file);
        });

        try {

            const response = await axios.post(`${config.baseUrl}files/upload?bucket=${bucketName}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',

                }
            });

            if (response?.status === 200) {
      
                setShowUpload(false)

                fetchBuckets();
            } else {
                console.error('Failed to upload files:', response?.statusText);
            }
        } catch (error) {
            console.error('Error uploading files:', error);
        }
    };

    useEffect(() => {
        fetchBuckets();
    }, [dispatch, page, rowsPerPage,sortBy,sortField,isBucketAdded,searchName]);


    useEffect(() => {
        setPage(1)

    }, [rowsPerPage])


    return (

        <>
            <div >
                <Box style={{ display: "flex", direction: 'row', justifyContent: 'space-between' }}>
                    {/* <h2>Recyclebin / {bucketName}</h2> */}
                    <Box sx={{ display: 'flex', flexDirection: 'row', }}>
                        <DynamicBreadcrumb breadcrumbs={breadcrumbs} style={{ marginTop: 2 }} />
                        {/* <Link to="/recyclebin" style={{ textDecoration: 'none', color: 'inherit' }}> */}

                        <Link to="/bucketlist" style={{ textDecoration: 'none', color: 'purple' }}>
                            <TitleHeading title={`${bucketName} `} />
                        </Link>
                            {/* <TitleHeading title={bucketName} /> */}
                        {/* </Link> */}

                        {/* <TitleHeading title={` (${bucketSize} GB / ${bucketUsedSize})`} /> */}

                    </Box>

                    <Box style={{ display: "flex", direction: 'row' ,gap:"5px"}}>
                        <SearchComponent
                            searchName={searchName}
                            setSearchName={setSearchName}
                            placeholder="Search Files..."
                        />
                        {/* <FormControl sx={{ float: "right", m: 2, mr: 1 }} >
                            <TextField

                                id="outlined-basic"
                                label="Search..."
                                placeholder='File Name'
                                variant="outlined"
                                type="search"
                                value={searchName}
                                // onChange={ (e)handleCompanyNameChange}

                                onChange={(e) => {


                                    setSearchName(e.target.value);
                                }}

                                InputProps={{
                                    sx: {
                                        // fontSize: "12px",
                                        height: "30px"
                                    },
                                }}

                                InputLabelProps={{ shrink: true }} 

                                // onChange={(e)=>companyChange(e)}
                                onKeyPress={handleSearch}



                            />
                        </FormControl> */}
                        <Button variant="contained" sx={{ height: "30px",width: "159px", marginTop: "15px" }} 
                            // disabled={!isFileSelected }
                            disabled={isEmptyData}
                        onClick={handleSelectAllClick}
                        >
                            {!isFileSelected  ? "Select All" : "Deselect All" }  
                        </Button>

                     {/* <Tooltip title="Restore">
                            <IconButton disabled={!isFileSelected}>  <RestorePageIcon style={{ cursor: "pointer" }} 
                
                                onClick={() => handleRestoreClick()}
                
                /></IconButton>

            </Tooltip>  */}
           
                        <Button variant="contained" style={{ cursor: "pointer", height: "30px", width: "159px", marginTop: "15px" }} disabled={!isFileSelected || isEmptyData} onClick={() => handleRestoreClick()}>Restore </Button>

                        {/* <Tooltip title="Delete" ><IconButton disabled={!isFileSelected}>   <DeleteIcon style={{ cursor: "pointer" }}
            
                onClick={() => handleDeleteClick()}
             /></IconButton></Tooltip>  */}
             
                        <Button variant="contained" style={{ cursor: "pointer", height: "30px", width: "159px", marginTop: "15px", }} disabled={!isFileSelected || isEmptyData} onClick={() => handleDeleteClick()}>Permanent Delete</Button>

                   
                    </Box>

                </Box>
                <Dialog open={showUpload} onClose={() => setShowUpload(false)} fullWidth>
                    <DialogTitle>Upload Files</DialogTitle>
                    <DialogContent>
                        <DragAndDropUpload onDrop={handleFileDrop} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setShowUpload(false)} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                <MainTable tableHeader={tableHeader} rows={rows} page={page}
                    rowsPerPage={rowsPerPage}
                    totalRecords={data?.data?.someData?.data?.totalLength}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    blankRowSize="0"
                    handleAscSort={handleAscSort}
                    handleDescSort={handleDescSort} 
                    
                    />

                <ConfirmationModal
                    open={confirmationOpen}
                    handleClose={handleCloseConfirmation}
                    handleConfirm={handleConfirmRestore}
                    text="Are you sure you want to restore the selected files?"
                    inputValue={inputValueRestore}
                    setInputValue={setInputValueRestore}
                    isTextField={false}
                />
                <ConfirmationModal
                    open={confirmationDeleteOpen}
                    handleClose={handleCloseConfirmationDelete}
                    handleConfirm={handleConfirmDelete}
                    text="Are you sure you want to delete the selected files?"
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                    isTextField={false}
                />


                <Dialog maxWidth="lg"
                    open={dialogOpen} onClose={() => setDialogOpen(false)}>
                    {/* <DialogTitle>Video Player</DialogTitle> */}
                    <DialogContent >

                        <VideoPlayerComponent sx={{ width: '100%', height: 'auto' }} videoUrl={videoUrl} videoId={videoId} />
                    </DialogContent>
                    {/* <DialogActions>
                        <Button onClick={() => setDialogOpen(false)}>Close</Button>
                    </DialogActions> */}
                </Dialog>
            </div>

            {loading && <LoadingComponent />}

      

        </>

    )
}