// import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Tooltip } from '@mui/material'
// import React from 'react'
// import { useDispatch, useSelector } from 'react-redux'
// import MainTable from '../tables/MainTable';
// import { setHistoryData } from '../../redux/HistoryDataSlice';

// import ContentCopyIcon from '@mui/icons-material/ContentCopy';
// import { setSnackBarData } from '../../redux/SnackBarSlice';

// const HistoryDiolog = () => {
//     const dispatch = useDispatch();
//     // const data = useSelector((state)=>state?.historySlice?.value)


//   const { open, data } = useSelector((state) => state.historySlice.value);

//     console.log(data,"hhhhhhhhhhhhhhh")

// const handleClose = ()=>{

//     dispatch(setHistoryData({open:false}));
    
// }


//    const copyToClipboard = (text) => {
   
//     navigator?.clipboard?.writeText(text)
//      dispatch(setSnackBarData({ open: true, msg: "Copied successfully", severity: 'success' }));

//   };
//   // const copyToClipboard = async (text) => {
//   //   debugger
//   //   try {
//   //     await navigator?.clipboard?.writeText(text);
//   //     dispatch(setSnackBarData({ open: true, msg: "Copied successfully", severity: 'success' }));
//   //   } catch (error) {
//   //     console.error('Failed to copy:', error);
//   //     dispatch(setSnackBarData({ open: true, msg: "Copy failed", severity: 'error' }));
//   //   }
//   // };


//   return (
//     <div>
//          <Dialog
//         open={open}
//         onClose={handleClose}
//         sx={{ '& .css-gg4vpm': { display: 'none' } }}
//         aria-labelledby="draggable-dialog-title"
//       >
//         {/* <MainTable/> */}
//         <DialogTitle   id="draggable-dialog-title">
//          Details
//         </DialogTitle>
//         <DialogContent >
//           <Box style={{ display: "flex", flexDirection: "column", gap: 10 ,marginTop:10 }}>

//             <TextField label="Bucket Name" value={data?.bucketName} disabled ></TextField>

//             <Box>
//               <TextField label="Bucket Key" value={data?.bucketKey} disabled InputProps={{
//                 endAdornment: (
//                   <Tooltip title="Copy">
//                     <IconButton onClick={() => copyToClipboard(data?.bucketKey)} size="small">
//                       <ContentCopyIcon />
//                     </IconButton>
//                   </Tooltip>
//                 ),
//               }}> </TextField> 

//             </Box>
           
//            <Box>
//               <TextField label="Bucket Secret" value={data?.bucketSecret} type="" disabled
//                InputProps={{
//                 endAdornment: (
//                   <Tooltip title="Copy">
//                     <IconButton onClick={() => copyToClipboard(data?.bucketSecret)} size="small">
//                       <ContentCopyIcon />
//                     </IconButton>
//                   </Tooltip>
//                 )
                
//                 ,
//               }}></TextField>

//            </Box>
           

//           </Box>
    
         
        
//         </DialogContent>
//         <DialogActions>
//           <Button variant='contained' autoFocus  onClick={handleClose}>
//             Close
//           </Button>
//         </DialogActions>
//       </Dialog>
      
//     </div>
//   )
// }

// export default HistoryDiolog


import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { setSnackBarData } from '../../redux/SnackBarSlice';
import { setHistoryData } from '../../redux/HistoryDataSlice';
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import CopyToClipboard from 'react-copy-to-clipboard';

const HistoryDialog = () => {
  const dispatch = useDispatch();
  const { open, data } = useSelector((state) => state.historySlice.value);

  const [showPassword, setShowPassword] = useState(false);

  const handleClose = () => {
    dispatch(setHistoryData({ open: false }));
  };



  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword); // Toggle the state
  };

  // const copyToClipboard = async (text) => {
  //   try {
     
  //     if (navigator.clipboard) {
  //       await navigator.clipboard.writeText(text);
  //       dispatch(setSnackBarData({ open: true, msg: "Copied successfully", severity: 'success' }));
  //     } 
      
  //   } catch (error) {
  //     console.error('Failed to copy:', error);
  //     dispatch(setSnackBarData({ open: true, msg: "Copy failed", severity: 'error' }));
  //   }
  // };


  // const copyToClipboard = (text) => {
  //   if (navigator.clipboard) {
  //     navigator.clipboard.writeText(text)
  //       .then(() => {
  //         console.log('Copied to clipboard:', text);
  //         dispatch(setSnackBarData({ open: true, msg: "Copied successfully", severity: 'success' }));
  //       })
  //       .catch((err) => {
  //         console.error('Failed to copy URL:', err);
  //         dispatch(setSnackBarData({ open: true, msg: "Failed to copy URL", severity: 'error' }));
  //       });
  //   } else {
  //     // Fallback for older browsers
  //     const textArea = document.createElement('textarea');
  //     textArea.value = text;
  //     document.body.appendChild(textArea);
  //     textArea.select();

  //     try {
  //       const successful = document.execCommand('copy');
  //       if (successful) {
  //         console.log('URL copied to clipboard (fallback):', text);
  //         dispatch(setSnackBarData({ open: true, msg: "Copied successfully (fallback)", severity: 'success' }));
  //       } else {
  //         console.error('Fallback copy command was unsuccessful');
  //         dispatch(setSnackBarData({ open: true, msg: "Failed to copy URL (fallback)", severity: 'error' }));
  //       }
  //     } catch (err) {
  //       console.error('Failed to copy URL (fallback):', err);
  //       dispatch(setSnackBarData({ open: true, msg: "Failed to copy URL (fallback)", severity: 'error' }));
  //     } finally {
  //       document.body.removeChild(textArea);
  //     }
  //   }
  // };


  

  const copyToClipboard = async (text) => {


    // Use modern Clipboard API if available
    if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(text);

        dispatch(setSnackBarData({ open: true, msg: "Copied Successfully", severity: 'success' }));
      } catch (err) {
        console.error('Failed to copy text using Clipboard API:', err);
        dispatch(setSnackBarData({ open: true, msg: "Failed to copy text", severity: 'error' }));
      }
    } else {
      // Fallback method for older browsers
      const textArea = document.createElement('textarea');
      textArea.style.position = 'fixed';  // Prevent scrolling
      textArea.style.opacity = '0';       // Hide it
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      textArea.setSelectionRange(0, 99999);  // For mobile devices

      try {
        const successful = document.execCommand('copy');
        if (successful) {
   
          dispatch(setSnackBarData({ open: true, msg: "Copied Successfully", severity: 'success' }));
        } else {
          throw new Error('Failed to copy text with execCommand');
        }
      } catch (err) {
        console.error('Failed to copy text using execCommand:', err);
        dispatch(setSnackBarData({ open: true, msg: "Failed to copy text", severity: 'error' }));
      } finally {
        document.body.removeChild(textArea);
      }
    }
  };


  const handleCopy = (text, result) => {
    if (result) {
      console.log('Text copied to clipboard:', text);
      
    } else {
      console.error('Failed to copy text.');
    }
  };








  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle id="draggable-dialog-title">
        Details
      </DialogTitle>
      <DialogContent>
        <Box style={{ display: "flex", flexDirection: "column", gap: 10, marginTop: "2rem"}}>
          <TextField
            label="Bucket Name"
            value={data?.bucketName}
            disabled
          />

          <Box>
            <TextField
            // sx={{width:"16.5vw"}}
              label="Bucket Key"
              value={data?.bucketKey}
              disabled
              InputProps={{
                endAdornment: (
                  <>
                    {/* <Tooltip >
                      <IconButton  size="small">
                        <ContentCopyIcon  sx={{color:"white",cursor:"none"}}/>
                      </IconButton>
                    </Tooltip> */}
                    <Box sx={{width:"4.2rem"}}>

                    </Box>
                    {/* <Tooltip title="Copy">
                      <IconButton onClick={() => copyToClipboard(data?.bucketKey)} size="small">
                        <ContentCopyIcon />
                      </IconButton>
                    </Tooltip> */}
                
                     <CopyToClipboard text={data?.bucketKey} onCopy={handleCopy}>
                      <Button><ContentCopyIcon /></Button>
                      
                    </CopyToClipboard> 
                    
                


                  </>
              
                  
                ),
              }}
            />
          </Box>

          <Box>
            <TextField
              label="Bucket Secret"
              type={showPassword ? "text" : "password"}
              value={data?.bucketSecret}
              // type="password"
              sx={{
                backgroundColor: "",
                width: "100%", // Adjust the width of the TextField
                '& .MuiInputBase-input': {
                  width: 'calc(100% - 3rem)' // Adjust input width, accounting for adornments
                }
              }}

              disabled
              InputProps={{
                endAdornment: (
                  <>
                  <Box sx={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
<Box sx={{width:"2rem"}}></Box>
                
                      <Tooltip title={showPassword ? "Hide" : "Show"}>
                        <IconButton
                          style={{ cursor: "pointer" }}
                          onClick={togglePasswordVisibility}
                          size="small"
                        >
                          {showPassword ? (
                            <VisibilityOutlinedIcon />
                          ) : (
                            <VisibilityOffOutlinedIcon />
                          )}
                        </IconButton>
                      </Tooltip>
                      {/* <Tooltip title="Copy">
                      <IconButton onClick={() => copyToClipboard(data?.bucketSecret || '')} size="small">
                        <ContentCopyIcon />
                      </IconButton>
                    </Tooltip> */}
                      <CopyToClipboard text={data?.bucketSecret} onCopy={handleCopy}>

                        <Button><ContentCopyIcon sx={{ backgroundColor: "", width: "2rem" }} /></Button>

                      </CopyToClipboard>

{/* 
                      <Tooltip title="Copy">
                        <IconButton onClick={() => copyToClipboard(data?.bucketSecret || '')} size="small">
                          <ContentCopyIcon />
                        </IconButton>
                      </Tooltip> */}


                  </Box>
                    
                  </>
                ),
              }}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' autoFocus onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HistoryDialog;

