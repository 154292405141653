
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import ConfirmationModal from "./ConfirmationModal";

const AddBucketModal = ({ values, handleChangeAdd, open, handleClose, handleConfirm, editMode, selectedBucketSize, selectedBucketName, selectedBucketAutodelete, selectedBucketMaxUploadSizeFile, isSubbucket }) => {

    // const [confirmationOpen, setConfirmationOpen] = useState(false);

    // const handleOpen = () => {
        
    //     setConfirmationOpen(true);
    // };

    // const handleConfirmAdd = () => {
    //     handleConfirm();

    //     setConfirmationOpen(false);
    // };

    // const handleCloseConfirmation = () => {
    //     setConfirmationOpen(false);
    // };


    return (
        <>

            <Dialog open={open} onClose={handleClose} style={{}}>

                <Box style={{ width: "25vw", }}>



                    {!isSubbucket &&
                        <DialogTitle > {editMode ? "UPDATE BUCKET" : "ADD BUCKET"}</DialogTitle>}

                    {isSubbucket && <DialogTitle > {editMode ? "UPDATE SUB BUCKET" : "ADD SUB BUCKET"}</DialogTitle>}
                    <DialogContent >

                        {/* <Typography variant="h3" style={{ fontSize: "17px", }}>Are you sure you want to delete this item?</Typography> */}

                        <Box style={{ display: 'flex', flexDirection: 'column', gap: '25px', marginTop: 25, height: '', }}>


                            <TextField fullWidth label={isSubbucket ? "Subbucket Name" : "Bucket Name"} variant="outlined" value={selectedBucketName ? selectedBucketName : values.bucketName}
                                onChange={(e) => handleChangeAdd('bucketName', e.target.value)}
                                disabled={editMode} />


                            <Box>
                                <TextField fullWidth label="Bucket size " placeholder="10" variant="outlined" value={values.bucketSize}
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        if (value === "" || (/^\d*$/.test(value) && parseInt(value) <= 500)) {
                                            handleChangeAdd('bucketSize', value);
                                        }
                                    }} />

                                {!isSubbucket && <Typography style={{ color: "grey" }}>(Default size : 10GB)</Typography>}
                               

                            </Box>



                            {!isSubbucket &&
                                <>



                                    <Box>
                                        <TextField fullWidth label="Recyclebin File Cleanup After" placeholder="30" variant="outlined" value={selectedBucketName ? selectedBucketAutodelete : values?.autoDelete}
                                            onChange={(e) => {
                                                const { value } = e.target;
                                                const newValue = value.replace(/\D/g, '');
                                                if (newValue === "" || (parseInt(newValue) <= 120)) {
                                                    handleChangeAdd('autoDelete', newValue);
                                                }
                                            }}
                                        />
                                        <Typography style={{ color: "grey" }}>(Default duration : 30 days)</Typography>

                                    </Box>
                                    <Box>
                                        <TextField fullWidth label="Max Upload File Size (in MB)" variant="outlined" value={selectedBucketMaxUploadSizeFile ? selectedBucketMaxUploadSizeFile : values?.maxUploadFileSize}
                                            // onChange={(e) => {
                                            //     const { value } = e.target;
                                            //     const newValue = value.replace(/\D/g, '');
                                            //     if (newValue === "" || (parseInt(newValue) <= 100)) {
                                            //         handleChangeAdd('maxUploadFileSize', newValue);
                                            //     }
                                            // }}
                                            onChange={(e) => {
                                                const { value } = e.target;
                                                if (value === "" || (/^\d*$/.test(value) && parseInt(value) <= 500)) {
                                                    handleChangeAdd('maxUploadFileSize', value);
                                                }
                                            }}
                                        />
                                        <Typography style={{ color: "grey" }}>(Default size : 5MB)</Typography>

                                    </Box>

                                </>

                            }
                        </Box>

                    </DialogContent>
                    <DialogActions style={{ display: "flex" }}>
                        <Button onClick={handleClose} color="primary" style={{ backgroundColor: "red", color: "white" }}>
                            Cancel
                        </Button>
                        <Button onClick={handleConfirm} color="primary" autoFocus style={{ backgroundColor: "green", color: "white" }}>
                            {editMode ? "Update" : "Add"}
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
            {/* Delete Confirmation dialog */}
            {/* <ConfirmationModal

                open={confirmationOpen}
                handleClose={handleCloseConfirmation}
                handleConfirm={handleConfirmAdd}
                text="Are you sure you want to save the changes?"
            /> */}
        </>

    );
};
export default AddBucketModal