export const Base_url = "https://jsonplaceholder.typicode.com/";

// export const Base_url2 = "http://localhost:8881/";
export const Base_url2 = "http://192.168.30.35:8881/";

// side bar filters Enums with Id's
export const Filters = {
    SearchFilter :1,
    FromDateFilter:2,
    ToDateFilter:3,
    StateFilter :4,
    OptionFilter:5,

}

// Column selection enable disable key
export const Is_Column_Selection_Enable= true ;

export const DATE_FORMAT = "DD-MM-yyyy";
export const DATE_TIME_FORMAT = "DD-MM-yyyy hh:mm";