import { FormControl, IconButton, InputAdornment, TextField } from '@mui/material'
import Search from '@mui/icons-material/Search';
import SearchIcon from '@mui/icons-material/Search';
import React, { useEffect, useState } from 'react'
import ClearIcon from '@mui/icons-material/Clear';


export const SearchComponent = (props) => {

    const { searchName, setSearchName,placeholder } = props
    const [search, setSearch] = useState();



    useEffect(()=>{
    setSearch(searchName);
    },[searchName])

  

    return (
        <FormControl sx={{ float: "right",m:2, mr:1 }} >

            <TextField
                id="serachInputBox"
                type="text"
                placeholder={placeholder}
                // size='small'
                value={search}
                onChange={(e) => {
                    setSearch(e.target.value)
                }}

                // onKeyDown={(e) => {
                //     const { key } = e;
                //     if (key === 'Enter') {
                //         e.preventDefault();
                //         setSearchName(search)
                //     }
                // }}
            
                InputProps={{
                    sx: {
                        fontSize: "13px",
                        height:"30px"
                    },
                    endAdornment: (
                        // <InputAdornment >
                        //     <IconButton sx={{ paddingLeft: '2px', paddingRight: '0px' }}
                        //         onClick={() => {
                        //             setSearch('')
                        //             setSearchName('')
                                 
                        //         }}
                        //     >
                        //         <ClearIcon />
                        //     </IconButton>

                        //     <IconButton sx={{ paddingLeft: '2px', paddingRight: '0px' }}
                        //         onClick={(e) => {
                        //             setSearchName(search)
                                
                        //         }}
                        //     >
                        //         <SearchIcon />
                        //     </IconButton>
                        // </InputAdornment>

                        <InputAdornment position="end">
                            {search && (
                                <IconButton
                                    sx={{ paddingLeft: '2px', paddingRight: '0px' }}
                                    onClick={() => {
                                        setSearch('');
                                        setSearchName('');
                                    }}
                                >
                                    <ClearIcon />
                                </IconButton>
                            )}
                            {search && (
                                <IconButton
                                    sx={{ paddingLeft: '2px', paddingRight: '0px' }}
                                    onClick={() => {
                                        setSearchName(search);
                                    }}
                                >
                                    <SearchIcon />
                                </IconButton>
                            )}
                            {!search && (
                                <IconButton
                                    sx={{ paddingLeft: '2px', paddingRight: '0px' }}
                                    onClick={() => {
                                        setSearchName(search);
                                    }}
                                    disabled
                                >
                                    <SearchIcon />
                                </IconButton>
                            )}
                        </InputAdornment>
                    ),
                }}
            />
        </FormControl>
    )
}


// import {
//     FormControl,
//     IconButton,
//     InputAdornment,
//     TextField,
// } from "@mui/material";
// import Search from "@mui/icons-material/Search";
// import SearchIcon from "@mui/icons-material/Search";
// import React, { useEffect, useState } from "react";
// import ClearIcon from "@mui/icons-material/Clear";
// import { Styles } from "../../constant/GenericStyles";

// export const SearchComponent = (props) => {
//     const { searchName, setSearchName, placeholder } = props;
//     const [search, setSearch] = useState();

//     useEffect(() => {
//         setSearch(searchName);
//     }, [searchName]);

//     return (
//         // <FormControl  >

//         <TextField
//             id="serachInputBox"
//             // type="search"
//             label={placeholder}
//             style={{ width: 'fit-content', }}
//             // fullWidth
//             // placeholder='-'
//             // size="small"
//             // style={{ height: "", width: "10vw", }}
//             // style={{ height: "", width: "11vw", }}
//             sx={{
//                 '& .MuiOutlinedInput-root': {
//                     borderRadius: '25px', // Adjust border radius as needed
//                 },
//             }}
//             value={search}
//             onChange={(e) => {
//                 setSearch(e.target.value);
//             }}
//             onKeyDown={(e) => {
//                 const { key } = e;
//                 if (key === "Enter") {
//                     e.preventDefault();
//                     setSearchName(search);
//                 }
//             }}
//             InputLabelProps={{
//                 shrink: true,
//                 style: {
//                     // fontSize: "16px",
//                     fontWeight: "bold",
//                     color: "black"
//                 }


//             }}
//             inputProps={{
//                 style: {
//                     padding: "5px 10px 5px 10px",
//                     fontSize: Styles.FontSize,
//                     // borderWidth: "7px",
//                     // height:"35px",
//                     height: "30px",
//                     '& MuiOutlinedInput-root': {
//                         borderRadius: '15px',
//                     },

//                 },
//             }}
//             InputProps={{
//                 sx: {
//                     // fontSize: "12px",
//                     height: "30px"
//                 },
//                 endAdornment: (
//                     <InputAdornment>
//                         {search &&
//                             <IconButton
//                                 //   sx={{ paddingLeft: "2px", paddingRight: "0px" }}
//                                 onClick={() => {
//                                     setSearch("");
//                                     setSearchName("");
//                                 }}
//                             >
//                                 <ClearIcon />
//                             </IconButton>
//                         }

//                         <IconButton
//                             //   sx={{ paddingLeft: "2px", paddingRight: "0px" }}
//                             onClick={(e) => {
//                                 setSearchName(search);
//                             }}
//                         >
//                             <SearchIcon />
//                         </IconButton>
//                     </InputAdornment>
//                 ),
//             }}
//         />
//         // </FormControl>
//     );
// };

