// Import necessary modules
import { createTheme } from '@mui/material/styles';
import { Styles } from '../constant/GenericStyles';
import { Hidden } from '@mui/material';

// Create a custom theme
export const customTheme = createTheme({
  palette: {
    primary: {
      main: Styles.BackgroundColor,
    },
    secondary: {
      main: '#ff5722',
    },
  },
  typography: {
    fontFamily: Styles.FontFamily,
    fontSize:Styles.FontSize
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '5px', // Modify the border radius for all buttons
          fontWeight: 'bold', // Modify the font weight for all buttons
          fontSize:Styles.FontSize,
        },
        contained: {
          backgroundColor: Styles.BackgroundColor, // Modify the background color for contained buttons
          cursor:'pointer',
          fontSize:'12px !important'
        },
      },
    },
 MuiTypography :{
    styleOverrides:{
        root:{
          fontSize:Styles.FontSize
            // color:Styles.color
        }
    }
 },
 MuiTableHead :{
  styleOverrides:{
    root:{
      backgroundColor:Styles.BackgroundColor,
      borderLeft: "none", 
      lineHeight:"20px",
      borderBottom: "none",
      boxShadow: 0,
      color:Styles.color,
      padding:'7px 20px',
      position:'sticky',
      top:0
    }
  }
 },
 MuiTableCell:{
  styleOverrides:{
    root:{
      color:'inherit',
      fontWeight:'600',
      padding:'2px 10px',
      height:'40%',
      border: "1px solid #add0f857",
    }
  }
 },
 MuiTableBody:{
  styleOverrides:{
    root:{
      fontSize: "12px !important",
      
      padding: "0px 55px 0px 20px",
      borderLeft: "none",
      borderRight: "1px solid #add0f857",
      borderTop: "none",
      color: "#333",
     fontWeight:'normal !important',
     lineHeight:"20px",
    }
  }
 },
 MuiTableRow:{
  styleOverrides:{
    root:{
      "&:nth-of-type(even)": {
        //  backgroundColor: "#db345b",
        margin: 10,
        maxHeight: 32,
        
      },
      fontSize: 12,
      margin: 10,
      borderCollapse: "separate",
      borderSpacing: "0px 4px",
      // backgroundColor: '#e0e0e0'
      // border: "3px solid #000",
      borderRadius: 10,
      borderBottom: 0,
      // height:1
    },
  }
 },
 MuiDialogTitle:{
   styleOverrides:{
    root:{
      backgroundColor:Styles.BackgroundColor,
      color:Styles.color ,
      height:'40px',
      padding:'5px'
    }
   }
 },
 MuiDrawer:{
  styleOverrides:{
    root:{
      // backgroundColor:Styles.BackgroundColor,
      color:Styles.color,
      width:'300px',
    },
   
  }
 },

 MuiSvgIcon : {
styleOverrides:{
  root:{
    height:'25px',
    width:'25px'
  }
}
 },


 MuiMenuItem:{
  styleOverrides:{
    root:{
      fontSize:Styles.FontSize,
      fontFamily: Styles.FontFamily,
      fontWeight:500
    }
  }
 },
 
 MuiChip:{
styleOverrides:{
  root:{
    backgroundColor:Styles.BackgroundColor,
    color:Styles.color,
    
  },

}
 },

 MuiCheckbox:{
  styleOverrides:{
    root:{
      
    },
  
    
  }
 },

//  MuiAccordionSummary:{
//   styleOverrides:{
//     root:{
//       '&.Mui-expanded':{
//         minHeight:'50px',
//         height:'50px' 
//       }, 
     
//     },
    
//   }
//  }
  
}
  // ... other customizations
});