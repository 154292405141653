import axios from 'axios'
import { Base_url, Base_url2 } from '../constant/Configs'
import { baseUrl, baseUrl2 } from '../pages/Permission/config';



export function getOptions() {
    let options = {};
        options.headers = {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'Access-Control-Allow-Origin': '*',
            // 'Content-Type': 'multipart/form-data',
            // 'userRoleId': null,
            // 'moduleId': null,
            // 'userId':localStorage.getItem("userId"),
            // 'userCompanyId': localStorage.getItem("userCompanyId"),

        };
    
    return options;

}



export function getOptions2() {
    let options = {};
    options.headers = {
        'Authorization': 'Bearer ' + localStorage.getItem("token"),
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'multipart/form-data',
        // 'userRoleId': null,
        // 'moduleId': null,
        // 'userId':localStorage.getItem("userId"),
        // 'userCompanyId': localStorage.getItem("userCompanyId"),

    };

    return options;

}



// simple get Method
const simpleGet = (apiEndpoint,signal)=>()=>{
    return axios.get(Base_url+apiEndpoint)
}

const simpleGet2 = (apiEndpoint, signal) => () => {
    return axios.get(baseUrl + apiEndpoint)
}




// get Method
const get = (apiEndpoint,signal)=>{
    return axios.get(Base_url+apiEndpoint,{...getOptions(),signal})
}



const get2 = (apiEndpoint, signal,path) => {


    return axios.get(`${baseUrl}${apiEndpoint}/${path}`, { ...getOptions(),signal })
}

//post form method
const postFormData = (apiEndpoint, payload, signal) => {

    return axios.post(baseUrl + apiEndpoint, payload, { ...getOptions(), signal })
       
    
};

// post Method
// const post = (apiEndpoint,payload,signal)=>{
//     return axios.get(Base_url+apiEndpoint,payload,{...getOptions(),signal})
// }

const postLogin = (apiEndpoint, payload) => {

    return axios.post(baseUrl + apiEndpoint, payload)


};

const post2 = (apiEndpoint, payload, signal) => {


    const updatedPayload = {
        ...payload,
        "companyId": localStorage.getItem("userCompanyId"),
        "userId": localStorage.getItem("userId"),
        "createdBy": localStorage.getItem("userId")
    };

    return axios.post(baseUrl + apiEndpoint, updatedPayload, { ...getOptions(), signal })
}

// put Method
const put = (apiEndpoint,payload,signal)=>{
    return axios.get(Base_url+apiEndpoint,payload,{...getOptions(),signal})
}

export { simpleGet, get, put, get2, simpleGet2, post2, postFormData, postLogin }