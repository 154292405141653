// thunks/apiThunks.js

import { apiRequest, apiError } from './GenericAction';
import { setLoading } from './LoadingSlice';
import { setSnackBarData } from './SnackBarSlice';

const vvv = localStorage.getItem("userId");




export const createApiThunk = (apiFunction, requestType, successType, errorType,key) => {
  return async (dispatch, getState) => {

   
    
    dispatch(apiRequest(requestType, null));
    dispatch(setLoading(true));


    try {
    
      const response = await apiFunction();
        // if (response.status === 200) {
        //   dispatch(apiRequest(successType, { [key]: response.data }));
        //   dispatch(setSnackBarData({ open: true, msg: response?.data?.message, severity: 'success' }));
        // } 
      
      // Call your API function here
      dispatch(apiRequest(successType,{[key]: response.data}));
      // dispatch(setSnackBarData({ open: true, msg: response?.data?.message, severity: response?.data?.status === 200 ? 'success' : 'error' }));

    } catch (error) {
      dispatch(setLoading(false)); 
      // dispatch(apiError(errorType,{[key]: error.message || 'An error occurred'}));
      // dispatch(setSnackBarData({ open: true, msg: error.message, severity: 'error' }));

    }





    // try {
    //   debugger
    //   const response = await apiFunction(); // Call your API function here

    //   if (response.status === 200) {
    //     dispatch(apiRequest(successType, { [key]: response.data }));
    //     dispatch(setSnackBarData({ open: true, msg: response?.data?.message, severity: 'success' }));
    //   } 
      
    //   else {
    //     if (response.status === 400) {
    //       dispatch(apiError(errorType, { [key]: response.data }));
    //       dispatch(setSnackBarData({ open: true, msg: response?.data?.message || 'Bad Request', severity: 'error' }));
    //     } else {
    //       dispatch(apiError(errorType, { [key]: response.statusText || 'Unknown Error' }));
    //       dispatch(setSnackBarData({ open: true, msg: 'An error occurred', severity: 'error' }));
    //     }
    //   }
    // } catch (error) {
    //   dispatch(setLoading(false));
    //   dispatch(apiError(errorType, { [key]: error.message || 'An error occurred' }));
    //   dispatch(setSnackBarData({ open: true, msg: error.message || 'An error occurred', severity: 'error' }));
    // } finally {
    //   dispatch(setLoading(false));
    // }
  };
};
