import { Typography } from '@mui/material'
import React from 'react'
import { Styles } from '../../constant/GenericStyles'

const TitleHeading = ({title}) => {
  return (
    <Typography variant="h5" sx={{ color: Styles.HeadingColor, fontWeight: "bold", mt: 3,fontSize:'20px' }}>{title}</Typography>
  )
}

export default TitleHeading