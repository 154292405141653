// export const Styles = {
//     BackgroundColor:"#0b3254",
//     FontSize:'15px',
//     color:'white',
//     FontFamily:`"Open Sans", Helvetica, Arial, sans-serif !important`,
//     HeadingColor:'black',
// }

// export const Styles = {
//     BackgroundColor:"#f17ba7",
//     IconsRoundedBackground:"#ea425e",
//     FontSize:'15px',
//     SelectedTabBackground:"#34062c",
//     color:'white',
//     FontFamily:`"Open Sans", Helvetica, Arial, sans-serif !important`,
//     HeadingColor:'black',
// }

export const Styles = {
    // BackgroundColor:"#4f2222",
    BackgroundColor: "#156f83",
    IconsRoundedBackground:"#07233a",
    FontSize:'15px',
    SelectedTabBackground:"#34062c",
    color:'white',
    FontFamily:`"Open Sans", Helvetica, Arial, sans-serif !important`,
    HeadingColor:'black',
}