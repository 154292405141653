import React from 'react';
import {Box,Typography} from '@mui/material'
import { useSelector } from 'react-redux';
import { Styles } from '../../constant/GenericStyles';

const Footer = () => {

  // AUTH DATA AND FROM LOGIN
const authData = useSelector((state) => state?.rootReducer?.data);
    return(
        <Box sx={{backgroundColor:Styles.BackgroundColor,position:'fixed',zIndex:'10000',bottom:'0px',width:'100%'}}>
          <Typography style={{textAlign:'center',padding:'6px',fontSize:'12px',color:Styles.color,fontWeight:'bold'}}>Copyright © NYGGS Automation Suite 2024</Typography>
          {/* <ItemDetailsDrawer itemId={authData?.itemId || null} openFilter={authData?.ItemDrawer || false}  /> */}
        </Box>
    )
}

export default Footer;