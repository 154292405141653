import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Typography, Button, IconButton, Tooltip, CircularProgress } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { setSnackBarData } from '../../redux/SnackBarSlice';
import MainTableDragDrop from '../../common_components/tables/MainTableDragDrop';

const DragAndDropUpload = ({ onDrop, setShowUpload, bucketMaxFileSize }) => {
    const dispatch = useDispatch();
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [totalSize, setTotalSize] = useState(0);
    const [fileErrors, setFileErrors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [uploadInProgress, setUploadInProgress] = useState(false); // Flag to manage upload state

    const bucketMaxFileSizes = bucketMaxFileSize * 1024 * 1024;

    const handleDrop = (files) => {
        const validFiles = [];
        const invalidFiles = [];

        const existingFilesMap = selectedFiles.reduce((acc, file) => {
            acc[file.name] = true;
            return acc;
        }, {});

        files.forEach(file => {
            if (file.size > bucketMaxFileSizes) {
                invalidFiles.push(file.name);
            } else {
                if (existingFilesMap[file.name]) {
                    setSelectedFiles(prevFiles =>
                        prevFiles.filter(f => f.name !== file.name).concat(file)
                    );
                } else {
                    validFiles.push(file);
                }
            }
        });

        if (invalidFiles.length > 0) {
            dispatch(setSnackBarData({
                open: true,
                msg: `Files too large: ${invalidFiles.join(', ')}`,
                severity: 'error'
            }));
        }

        setSelectedFiles(prevFiles => [...prevFiles, ...validFiles]);
    };

    const { getRootProps, getInputProps, open } = useDropzone({
        onDrop: handleDrop,
        noClick: true,
        noKeyboard: true,
    });

    const removeFile = (index) => {
        const newFiles = [...selectedFiles];
        newFiles.splice(index, 1);
        setSelectedFiles(newFiles);
    };

    const handleUpload = async () => {
        if (uploadInProgress) return; // Prevent action if already uploading

        setUploadInProgress(true); // Set flag to true to prevent multiple uploads
        setLoading(true);

        try {
            await onDrop(selectedFiles);  // Call the upload function
        } catch (error) {
            console.error("Upload failed", error);
            dispatch(setSnackBarData({
                open: true,
                msg: `Upload failed: ${error.message}`,
                severity: 'error'
            }));
        } finally {
            // Simulate delay to prevent immediate re-click
            setTimeout(() => {
                setLoading(false);
                setUploadInProgress(false);  // Reset flag after the delay
            }, 2000); // 2 seconds delay (adjust as needed)
        }
    };

    const tableHeader = [
        { title: 'Name', key: 'name' },
        { title: 'Size', key: 'size' },
        { title: 'Actions', key: 'action' },
    ];

    const formatFileSize = (size) => {
        if (size < 1024) {
            return `${size} Bytes`;
        } else if (size < 1024 * 1024) {
            return `${(size / 1024).toFixed(2)} KB`;
        } else if (size < 1024 * 1024 * 1024) {
            return `${(size / (1024 * 1024)).toFixed(2)} MB`;
        } else {
            return `${(size / (1024 * 1024 * 1024)).toFixed(2)} GB`;
        }
    };

    const rows = (Array.isArray(selectedFiles) ? selectedFiles : []).map((selectedFile, index) => ({
        name: <Typography align="left">{`${selectedFile?.name} ` || ''}</Typography>,
        size: <Typography align="right">{`${formatFileSize(selectedFile?.size)}` || ''}</Typography>,
        action: <Tooltip title="Delete">
            <IconButton onClick={() => removeFile(index)}>
                <Delete />
            </IconButton>
        </Tooltip>
    }));

    const removeAllFiles = () => {
        setSelectedFiles([]);
    };

    useEffect(() => {
        let total = 0;
        selectedFiles.forEach(file => {
            total += file.size;
        });
        setTotalSize(total);
    }, [selectedFiles]);

    return (
        <Box>
            <Box
                {...getRootProps()}
                sx={{
                    border: '2px dashed #ccc',
                    padding: '15px',
                    height: "15vh",
                    textAlign: 'center',
                    cursor: 'pointer',
                    marginTop: '20px',
                }}
                onClick={open}
            >
                <input {...getInputProps({ multiple: true })} />
                <Typography style={{ marginTop: 36 }}>Drag & drop some files here, or click to select files</Typography>
            </Box>

            <Box>
                <Typography style={{ marginTop: 25, fontWeight: "bold", fontSize: "18px" }}>
                    No of files: <span style={{ color: selectedFiles?.length > 5 ? "red" : "black" }}>{selectedFiles?.length}</span> / 5
                </Typography>
            </Box>

            {(selectedFiles?.length > 0) && (
                <>
                    <Box sx={{ marginTop: 5 }}>
                        <MainTableDragDrop tableHeader={tableHeader} rows={rows} page={0} rowsPerPage={0} totalRecords={0} />
                    </Box>
                    <Box style={{ display: "flex", justifyContent: "right" }}>
                        <Button
                            variant="contained"
                            disabled={(selectedFiles?.length > 5) || loading}
                            onClick={handleUpload}
                            style={{ marginTop: 20, height: "30px", width: "80px" }}
                        >
                            { 'Upload'}
                        </Button>
                    </Box>
                </>
            )}
        </Box>
    );
};

export default DragAndDropUpload;


