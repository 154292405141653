import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import IconStore from '../../Images/NavIcons/Store.svg'
import { useTranslation } from "react-i18next";
import { Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, Menu, InputLabel, Select, Tooltip, Collapse } from '@mui/material';
// import IconDocumentType from '../main/NavIcons/Store.svg'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LocalDrinkIcon from '@mui/icons-material/LocalDrink';
import SettingsIcon from "@mui/icons-material/Settings";
import logo from '../../Images/NavIcons/NYGGS logo.png';
import { Styles } from '../../constant/GenericStyles';
import Nyggs from "../../Images/NavIcons/powByNyggs.svg";
import Footer from './Footer';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import MasterIcon from '../../Images/NavIcons/Master.svg'
import NavLinkSearchBar from '../input_fields/NavLinkSearchBar';
import { useDispatch, useSelector } from 'react-redux';
import { setAuthData } from '../../redux/AuthSlice';
import { setSnackBarData } from '../../redux/SnackBarSlice';

import FolderIcon from '@mui/icons-material/Folder';

const drawerWidth = 250;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  minHeight: '50px',
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);


export default function MiniDrawer({ children }) {
  const navigate = useNavigate()
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState({ account: null, setting: null });
  const [masterOpen, setMasterOpen] = React.useState(false);
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.authSlice.value);

  const handleMenu = (event, anchor) => {
    setAnchorEl((prev) => {
      return { ...prev, [anchor]: event.currentTarget }
    });
  };

  const handleMenuClose = (anchor) => {
    setAnchorEl((prev) => {
      return { ...prev, [anchor]: null }
    });
  };


  const { t, i18n } = useTranslation();

  const changeLanguageHandler = (e) => {
    const languageValue = e.target.value;
    i18n.changeLanguage(languageValue);
    // setSetting(false)
  };

  const handleDrawerOpen = () => {
    setOpen(true);
    dispatch(setAuthData({ ...authData, open: true }))
  };

  const handleDrawerClose = () => {
    setOpen(false);
    dispatch(setAuthData({ ...authData, open: false }))
  };

  const handleOpen = (path) => {
    navigate(path)
  }

  const handleClick = () => {
    setMasterOpen(!masterOpen);
  };



  // ACTIVE SIDE LINKS FUNCTIONS
  const isActive = (value) => value.includes(window.location.pathname) ? { backgroundColor: "#353C49", color: "white" } : {};



  let MasterList = [
    // {
    //   title: 'Permission', routeLink: '/permission',
    //   relatedLinks: ['/permission'],
    //   navIcon: IconStore
    // },
    {
      title: 'Buckets', routeLink: '/bucketlist',
      relatedLinks: ['/bucketlist'],
      navIcon: IconStore
    },
    {
      title: 'Recycle Bin', routeLink: '/recyclebin',
      relatedLinks: ['/recyclebin'],
      navIcon: IconStore
    },
  ]

  


  const [sideNavList, setSideNavList] = React.useState(MasterList);
  // SIDE LOG USER FUNCTIONS
  const [UserOpen, setUserOpen] = React.useState(false);
  const anchorRef = React.useRef(null);


  // handel side bar listing by search filter
  const handleSearchData = (value) => {
    if (value) {
      const searchFound = MasterList?.filter(item => (item?.title).toLowerCase().includes((value).toLowerCase()));
      setSideNavList(searchFound);
    } else {
      setSideNavList(MasterList)
    }
  }


  const handleToggle = () => {
    setUserOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setUserOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setUserOpen(false);
    } else if (event.key === 'Escape') {
      setUserOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(UserOpen);
  React.useEffect(() => {
    if (prevOpen.current === true && UserOpen === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = UserOpen;
  }, [UserOpen]);

  // LOG OUT USER
  function logOutUser() {
    window.localStorage.clear();

    navigate('/')
    dispatch(setSnackBarData({ open: true, msg: "Signed out succesfully", severity: 'success' }));

  }


  // console.log("language", t('LANGUAGE'))
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={authData?.open} style={{ height: '50px' }} >
        {/* <img style={{ position:'fixed', width:'100%'}} src={header} /> */}
        <Toolbar >

          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(authData?.open && { display: 'none' }),
              width: '25px',
              height: '25px'
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            <img src={logo} alt='' width='96px' style={{marginBottom:"2px"}} />
          </Typography>

          <Box style={{ float: 'center', marginRight: '20px',backgroundColor:"blue" }}>


            <AccountCircleIcon ref={anchorRef}
              onClick={handleToggle}
              sx={{ position: "fixed", right: '20px', top: '14px', cursor: 'pointer', fontSize: '30px' }} />
            <Popper
              open={UserOpen}
              anchorEl={anchorRef.current}
              role={undefined}
              placement="bottom-start"
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom-start' ? 'left top' : 'left bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={open}
                        id="composition-menu"
                        aria-labelledby="composition-button"
                        onKeyDown={handleListKeyDown}
                      >
                        <MenuItem onClick={handleClose}>{t('PROFILE')}</MenuItem>
                        <MenuItem onClick={handleClose}>{t('ACCOUNT')}</MenuItem>
                        <MenuItem onClick={(e) => { logOutUser() }}>{t('LOGOUT')}</MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Box>


        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={authData?.open}>
        <DrawerHeader sx={{ height: '50px !important', backgroundColor: Styles.BackgroundColor }}>
          <img
            src={Nyggs}
            style={{ transform: "translate(-25px, 0px)" }}
            height="100%"
            width="130px"
          />
          <IconButton sx={{ color: Styles.color }} onClick={handleDrawerClose} >
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        {/* <NavLinkSearchBar handleSearchData={handleSearchData} /> */}
        <List sx={{
          backgroundColor: Styles.BackgroundColor, color: Styles.color, height: '100%', overflowY: 'auto', msOverflowStyle: 'none', scrollbarWidth: 'none', '&::-webkit-scrollbar': {
            display: 'none', // Hide scrollbar on Webkit browsers
          }, overflowX: 'wrap', marginBottom: '30px'
        }}>
          <ListItem
            onClick={handleClick}
          >
            <Tooltip title="Master" TransitionComponent="none" >
              <ListItemIcon aria-label="Master">
                {/* <StorageIcon /> */}
                <div style={{ width: "25px", height: "25px" }}>
                  <img
                    src={MasterIcon}
                    className="logoImage"
                    alt="nkc-logo"
                    style={{ width: "100%", maxWidth: "100%" }}
                  />
                </div>
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Master" />
            {masterOpen ? <ExpandLess /> : <ExpandMore />}

          </ListItem>
          <Collapse in={masterOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {sideNavList?.map((val, index) =>
                <Link to={val?.routeLink} style={{ textDecoration: 'none' }}>
                  
                  <ListItem key={val?.title} disablePadding sx={{ display: 'block' }}>
                    <ListItemButton
                      style={isActive([val?.title, val?.routeLink, ...val?.relatedLinks])}
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : 'auto',
                          justifyContent: 'center',
                          padding: '10px',
                          borderRadius: '50%',
                          backgroundColor: Styles.IconsRoundedBackground
                        }}
                      >

                        {val?.title === "Buckets" && <Tooltip title="Buckets">
                          {/* <img src={val?.navIcon} width='20' /> */}
                          <FolderIcon style={{ color: "white", width: 20 }} />

                        </Tooltip> }

                        {val?.title === "Recycle Bin" && <Tooltip title="Recycle Bin">
                          <img src={val?.navIcon} width='20' />
                          {/* <FolderIcon style={{ color: "white", width: 20 }} /> */}

                        </Tooltip>}
                      
                      
                      </ListItemIcon>
                      <ListItemText primary={val?.title} sx={{ opacity: open ? 1 : 0, color: 'white' }} />
                    </ListItemButton>
                  </ListItem>
                </Link>
              )}
            </List>
          </Collapse>
        </List>


      </Drawer>
      <Box sx={{ flexGrow: 1, marginY: '40px', padding: '20px' }} onClick={() => dispatch(setAuthData({ ...authData, open: false }))}>
        {children}
      </Box>
      {/* <div class="tooltip-container">
        <div class="tooltip" id="tooltip">This is a tooltip</div>
      </div> */}
      <Footer />
    </Box >
  );
}