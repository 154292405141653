import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoadingComponent from "../common_components/popups/LoadingComponent";

import MainLayout from "../common_components/layout/MainLayout";
import NoPage from "../common_components/layout/NoPage";
import Login from "../pages/login/Login"

import MainComponent from "../pages/MainComponent";
import { FileList, Listing } from "../pages/files/FileList";
import { RecycleBin } from "../pages/files/RecycleBin";
import { RecyclebinListing } from "../pages/files/RecyclebinListing";
import { SubbucketFileList, SubbucketListing } from "../pages/files/SubbucketFileList";
import { Subbucket, SubbucketList } from "../pages/files/SubbucketList";
import PrivateRoute from "./PrivateRoute";
import { BucketList } from "../pages/files/BucketList";
const MainRouting = () => {
  return (
    <Router>
      <Suspense fallback={<LoadingComponent />}>
        <Routes >
          <Route path="/" element={<Login />}></Route>
          <Route path="/main" element={<MainLayout children={<MainComponent />} />}>
          </Route>
          <Route path="*" element={<Login />} />
          {/* <Route path="/bucketlist" element={<MainLayout children={<BucketList />} />}>
          </Route> */}
          {/* <Route  path="/bucketlist"   element={<PrivateRoute path="/bucketlist" element={<MainLayout children={<BucketList />} />} />} /> */}
          {/* <PrivateRoute path="/bucketlist" element={BucketList} /> */}

<Route path="/" element = {<PrivateRoute/>}>
           <Route path="bucketlist" element={<MainLayout children={<BucketList />} />}>
          </Route> 
            <Route path="filelist" element={<MainLayout children={<FileList />} />}>
            </Route>
            {/* <Route path="/subbucketlist" element={<MainLayout children={<SubbucketList />} />}>
          </Route> */}
            <Route path="subbucketfilelist" element={<MainLayout children={<SubbucketFileList />} />}>
            </Route>
            <Route path="recyclebin" element={<MainLayout children={<RecycleBin />} />}>
            </Route>
            <Route path="recyclebinlisting" element={<MainLayout children={<RecyclebinListing />} />}>
            </Route>











</Route>

         

       
        </Routes>
      </Suspense>
    </Router>
  );
};

export default MainRouting;
