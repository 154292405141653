import React from 'react';
import { Route, Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAuth } from '../redux/AuthSlice'; // Adjust path based on your actual folder structure

const PrivateRoute = ({ path, element }) => {
    const auth = localStorage.getItem('auth');
    


    // if (auth?.isAuthenticated) {
    //     return <Navigate to="/" replace />;
    // }

  return auth ? <Outlet/> : <Navigate to="/"/>


    // return(
    //     <>
    //     <div>hello private</div>
    //     <Outlet/>
    //     </>
    // )

    // return <Route path={path} element={element} />;
};

export default PrivateRoute;






