// actions/apiActions.js

export const apiRequest = (requestType, payload) => ({
    type: requestType,
    payload,
  });
  
  export const apiError = (errorType, error) => ({
    type: errorType,
    payload: error,
  });
  