
import React, { useEffect, useRef } from 'react';

const VideoPlayerComponent = ({ videoUrl }) => {
    const videoRef = useRef(null);
    const progressRef = useRef(null);

    useEffect(() => {
        const video = videoRef.current;
        const progress = progressRef.current;

        const updateTime = () => {
            if (video && progress) {
                const currentTime = video.currentTime;
                const duration = video.duration;
                const progressValue = (currentTime / duration) * 100;

                if (isFinite(progressValue)) {
                    progress.value = progressValue;
                }
            }
        };

        const handleProgressClick = (event) => {
            const clickPosition = event.clientX - progress.getBoundingClientRect().left;
            const progressWidth = progress.offsetWidth;
            const progressPercentage = (clickPosition / progressWidth) * 100;
            const duration = video.duration;
            const seekTime = (progressPercentage / 100) * duration;

            video.currentTime = seekTime;
        };

        video.addEventListener('timeupdate', updateTime);
        progress.addEventListener('click', handleProgressClick);

        return () => {
            video.removeEventListener('timeupdate', updateTime);
            progress.removeEventListener('click', handleProgressClick);
        };
    }, []);

    return (
        <>
            <video id="video" ref={videoRef} controls style={{ maxWidth: '100%', maxHeight: '100%', height: 'auto' }}>
                <source src={videoUrl} type="video/mp4" />
            </video>
                    <progress ref={progressRef} id="progress" value="0" max="100" style={{cursor:"pointer",height:"30px",width:"100%"}} />
          
            <style>
                {`
                #video::-webkit-media-controls-timeline {
                    display: none !important;
                }
                `}
            </style>
        </>
    );
};

export default VideoPlayerComponent;


